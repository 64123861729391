import React, { Component } from 'react';
import { getAppBannerDetail, createAppBanner, updateAppBanner } from '../../services';
import { connect } from 'react-redux';
import './CreateBanner.scss';
import Loading from '../loading/Loading';
import Modal from '../modal/Modal';
import AlertView from '../alertView/AlertView';

class CreateBanner extends Component {

  state = {
    appImage: null,
    webImage: null,
    banner_data: null,
    title: '',
    price: 0,
    index: 0,
    productType: '',
    text: '',
    isProduct: false,
    isLoading: false,
    showError: false,
    errorMessage: false
  }

  componentDidMount() {
    const { id } = this.props.match.params
    if (id) {
      this.getAppBannerDetail(id)
    }
  }

  getAppBannerDetail = async(id) => {
    this.setState({isLoading: true})
    const { userToken } = this.props;
    await getAppBannerDetail(id, userToken.access)
    .then((response) => {
      this.setState({
        banner_data: response.data,
        index: response.data.index,
        title: response.data.title,
        text: response.data.description,
        price: response.data.price,
        isProduct: response.data.type === 'product' ? true : false,
        productType: response.data.product_type
      })
      this.setState({isLoading: false})
      console.log(this.state);
    })
    .catch((error) => {
      window.location.replace('/banners/app/');
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.name] : (e.target.type === "checkbox" ? e.target.checked : e.target.value)
    });
  }

  handleChangeFile = e => {
    this.setState({
        [e.target.name] : e.target.files[0]
    });
  }

  handleSelectorChange = e => {
    this.setState({
        [e.target.name] : e.target.value
    });
  }

  handleSubmit = async(e) => {
    e.preventDefault();
    this.setState({isLoading: true})
    const { userToken } = this.props;
    const { banner_data, appImage, webImage, title, price, index, productType, text, isProduct } = this.state;

    if (banner_data) {
        const data_dict = new FormData();

        if (banner_data.index !== index) {
            data_dict.append('index', index);
        }
        if (banner_data.title !== title) {
            data_dict.append('title', title);
        }
        if (banner_data.description !== text) {
            data_dict.append('description', text);
        }
        if (banner_data.price !== price) {
            data_dict.append('price', price);
        }
        if (banner_data.type !== isProduct ? 'product' : 'promotion') {
            data_dict.append('type', isProduct ? 'product' : 'promotion');
        }
        if (banner_data.productType !== productType) {
            data_dict.append('product_type', productType);
        }
        if (appImage) {
            data_dict.append('image', appImage);
        }
        if (webImage) {
            data_dict.append('image_web', webImage);
        }

        if (data_dict.entries().next().value) {
            await updateAppBanner(banner_data.id, userToken.access, data_dict)
                .then((response) => {
                    window.location.replace('/banners/app/');
                })
                .catch((error) => {
                    this.setState({
                        isLoading: false,
                        showError: true,
                        errorMessage: error.response.data.message
                    });
                });
        } else {
            window.location.replace('/banners/app/');
        }
    } else {
        const data = new FormData();
        data.append('index', index);
        data.append('title', title);
        data.append('description', text);
        data.append('price', price);
        data.append('type', isProduct ? 'product' : 'promotion');
        data.append('product_type', productType);
        data.append('image_web', webImage);
        data.append('image', appImage);

        await createAppBanner(userToken.access, data)
            .then((response) => {
                window.location.replace('/banners/app/');
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    showError: true,
                    errorMessage: error.response.data.message
                });
            });
    }
  }

  handleOkError = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  render() {
    const { banner_data, appImage, webImage, title, price, index, productType, text, isProduct, isLoading, showError, errorMessage } = this.state;
    let header = "Crear banner";
    let button_title = "Crear banner";

    if (banner_data) {
      header = "Detalle del banner";
      button_title = "Guardar banner";
    }

    return (
      isLoading ? <Loading/> :
      <div className="create-banner_main mt-4 ml-5 mr-5 mb-5">
        {showError && 
          <Modal>
            <AlertView
              handleOkButton={this.handleOkError}
              message={errorMessage}
            />
          </Modal>
        }
        <div className="create-banner_inside">
          <div className="d-flex justify-content-center mb-3">
            <h1>{ header }</h1>
          </div>
          <form className="create-banner-form" onSubmit={this.handleSubmit}>
            <div className="create-banner-input-container">
              <label className='create-banner-input-label mr-3'>Título del banner: </label>
              <input
                type="text"
                placeholder=""
                name="title"
                value={title}
                onChange={this.handleChange}
                required
                disabled={false}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-banner-input-container">
              <label className='create-banner-input-label mr-3'>Precio: </label>
              <input
                type="number"
                placeholder=""
                max="9999"
                name="price"
                value={price} 
                onChange={this.handleChange}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-banner-input-container">
              <label className='create-banner-input-label mr-3'>Indice: </label>
              <input
                type="number"
                placeholder=""
                max="9999"
                name="index"
                value={index} 
                onChange={this.handleChange}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-banner-input-container">
              <label className='create-banner-input-label mr-3'>Tipo de producto: </label>
              <input
                type="text"
                placeholder=""
                name="productType"
                value={productType}
                onChange={this.handleChange}
                required={isProduct}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-banner-input-container">
              <label className='create-banner-input-label mr-3'>Descripción del banner: </label>
              <textarea name="text" cols="40" rows="10" id="id_text" onChange={this.handleChange} value={text} required></textarea>
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-banner-input-container">
              <label className='create-banner-input-label mr-3'>Selecciona una imagen para las aplicaciones: </label>
              <input 
                type="file"
                name="appImage"
                required={banner_data ? false : true}
                onChange={this.handleChangeFile}
                accept="image/jpeg, image/jpeg"
              />
            </div>
            { appImage &&
                <img src={URL.createObjectURL(appImage)} width="320px" height="160px" alt="Cover Imge"/>
            }
            <hr className="cart_info-inline"></hr>
            <div className="create-banner-input-container">
              <label className='create-banner-input-label mr-3'>Selecciona una imagen para web: </label>
              <input 
                type="file"
                name="webImage"
                required={banner_data ? false : true}
                onChange={this.handleChangeFile}
                accept="image/jpeg, image/jpeg"
              />
            </div>
            { webImage &&
                <img src={URL.createObjectURL(webImage)} width="320px" height="160px" alt="Cover Imge"/>
            }
            <hr className="cart_info-inline"></hr>
            <div className="create-banner-input-container">
              <label className='create-banner-input-label mr-3'>¿Es un producto? </label>
              <input 
                type="checkbox"
                name="isProduct"
                checked={isProduct}
                onChange={this.handleChange}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="d-flex justify-content-center align-items-center">
                <button id="create_cover" type="submit" className="create_banner_button my-3">{ button_title }</button>
            </div>
          </form>
        </div>
      </div>
    );
  }

}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

export default connect(mapStateToProps)(CreateBanner);