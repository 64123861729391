import React, { Component } from 'react';
import { connect } from 'react-redux'
import { saveUserDataAction } from '../../redux/actions';
import { loginUser } from '../../services';
import Loading from '../loading/Loading';
import Modal from '../modal/Modal';
import AlertView from '../alertView/AlertView';
import logo from '../../images/pixyalbum-logo.png';
import './Login.scss';


class Login extends Component {

  state = {
    username: '',
    password: '',
    isLoading: true,
    showError: false,
    errorMessage: ''
  }

  componentDidMount() {
    const { userToken } = this.props;
    if (userToken) {
      this.props.history.replace('/search/')
    } else {
      this.setState({isLoading: false});
    }
  }

  handleTextInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = async(e) => {
    e.preventDefault();
    const { username, password } = this.state;
    this.setState({isLoading: true})
    try {
      const response = await loginUser({username, password});
      this.props.saveUserDataAction(response.data, username)
      this.props.history.push('/search')
    } catch(error) {
      // if (error.response.status === 401) {
        this.setState({
          username: '',
          password: '',
          isLoading: false,
          showError: true,
          errorMessage: 'Las credenciales de acceso son incorrectas.'
        });
      // }
    }
  }

  handleOkError = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  render() {
    const { username, password, isLoading, showError, errorMessage } = this.state;
    return (
      isLoading ? <Loading/> :
      <div className="login_main d-flex">
        {showError && 
          <Modal>
            <AlertView
              handleOkButton={this.handleOkError}
              message={errorMessage}
            />
          </Modal>
        }
        <div className="login_left-container w-50"></div>
        <form className="login_right-container w-50 d-flex flex-column justify-content-center align-items-center" onSubmit={this.handleSubmit}>
          <img src={logo} height="70" alt="" />
          <div className="login_input-container d-flex flex-column align-items-start">
            <p className="gotham-bold-darkgray-25">Inicia sesión</p>
            <input className="login_input my-2" type="text" placeholder="Nombre de usuario" name="username" value={username} onChange={this.handleTextInput} />
            <input className="login_input mt-2 mb-4" type="password" placeholder="Contraseña" name="password" value={password} onChange={this.handleTextInput} />
            <button className="generic-button mt-5" type="submit">
              Iniciar sesión
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

const mapDispatchToProps = {
  saveUserDataAction: saveUserDataAction,
}


export default connect(mapStateToProps, mapDispatchToProps)(Login);