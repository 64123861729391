import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFilteredShippingGuides, getNextPageShippingGuide } from '../../services';
import ShippingGuideSearchResultRow from './ShippingGuideSearchResultRow';
import Loading from '../loading/Loading';

import search_img from '../../images/search-img.png';
import warning_img from '../../images/warning-img.png';
import './SearchShippingGuide.scss';


class SearchShippingGuide extends Component {

  state = {
    searchText: '',
    shippingGuides: [],
    data: null,
    showError: false,
    errorMessage: '',
    isLoading: false,
    showLocalLoading: false
  }

  handleTextInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = async(e) => {
    e.preventDefault();
    console.log('test-submit')
    this.setState({
      showError: false,
      errorMessage: '',
      isLoading: true
    });
    const { searchText } = this.state;
    const { access, refresh } = this.props.userToken;
    if (searchText === '') {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ingresar número de guía',
      });
    } else {
      let searchType = 'tracking_number'
      try {
        const response = await getFilteredShippingGuides(searchText, access, searchType);
        if (response.status === 200) {
          if (response.data.count === 0) {
            this.setState({
              showError: true,
              errorMessage: 'No existen guías con los criterios utilizados',
              isLoading: false
            });
          } else {
            this.setState({
              isLoading: false,
              shippingGuides: response.data.results,
              data: response.data,
              searchText: ''
            });
          }
        }
      } catch(err) {
        this.setState({
          showError: true,
          isLoading: false,
          errorMessage: 'Ha ocurrido un error, intenta más tarde.',
          searchText: ''
        })
      }
    }
  }

  handleLoadNextShippingGuides = async() => {
    const { data, shippingGuides } = this.state;
    const { access } = this.props.userToken;
    this.setState({showLocalLoading: true});
    const nextPageUrl = data.next;
    try {
      const response = await getNextPageShippingGuide(nextPageUrl, access);
      if (response.status === 200) {
        this.setState({
          shippingGuides: shippingGuides.concat(response.data.results),
          data: response.data,
          showLocalLoading: false
        });
      }
    } catch(e) {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde',
        isLoading: false
      });
    }
  }

  handleSelectOrder = (id) => {
    window.open(`/order/detail/${id}`, '_blank')
  }

  handleOkError = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  render() {
    const { searchText, shippingGuides, data, showError, errorMessage, isLoading, showLocalLoading } = this.state;
    return (
      isLoading ? <Loading/> :
      <div className="search-shipping-guide_main">
        <form className="search-shipping-guide_form d-flex flex-column justify-content-center align-items-center" onSubmit={this.handleSubmit}>
          <div className="search-shipping-guide_box d-flex justify-content-between align-items-center">
            <div className={showError ? "search-shipping-guide_input-container-error" : "search-shipping-guide_input-container"}>
              <input className="search-shipping-guide_input" type="text" placeholder="Buscar número de guía" name="searchText" value={searchText} onChange={this.handleTextInput} />
              {showError &&
                <div className="search-shipping-guide_error mt-1 d-flex align-items-center justify-content-center">
                  <img src={warning_img} height="18" alt=""/>
                  <p className="gotham-md-red-12 ml-3">{errorMessage}</p>
                </div>              
              }
            </div>
            <button className="search-shipping-guide_button" type="submit">
              {isLoading ? 
                <div className="spinner-border text-light"></div>
                : <img src={search_img} height="30" alt="" />
              }
            </button>
          </div>
        </form>
        {shippingGuides.length > 0 &&
          <div className="pb-5 d-flex flex-column align-items-center">
            <div className="search-shipping-guide_count-results pl-2">
              <p className="gotham-md-darkfont-15">Mostrando {shippingGuides.length} de {data.count} resultados</p>
            </div>
            <table className="search-shipping-guide_table">
              <thead className="search-shipping-guide_table-head">
                <tr>
                  <th className="search-shipping-guide_table-head-item">ID orden</th>
                  <th className="search-shipping-guide_table-head-item">Número de Guía</th>
                  <th className="search-shipping-guide_table-head-item">Compañía</th>
                  <th className="search-shipping-guide_table-head-item">Fecha de Creación</th>
                </tr>
              </thead>
              <tbody>
                {shippingGuides.map((item, i) => (
                  <ShippingGuideSearchResultRow
                    key={i}
                    item={item}
                    handleSelectOrder={this.handleSelectOrder}
                  />
                ))}
              </tbody>
            </table>
            {(data && data.count > shippingGuides.length) && (
              showLocalLoading ? <Loading isLocalLoading /> :
              <button className="search-shipping-guide_see-more my-5" onClick={this.handleLoadNextShippingGuides}>
                Ver más +
              </button>)
            }
          </div>
        }
        
      </div>
    );
  }

}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

export default connect(mapStateToProps)(SearchShippingGuide);