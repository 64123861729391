import React from 'react';
import './SearchPushNotifications';

export default function PushNotificationsSearchResultRow(props) {
  const {
    id,
    title,
    text,
    push_type,
    device,
    send_date,
    image_link,
    is_scheduled,
    is_sent
  } = props.item;

  let push_type_str = '';
  switch (push_type) {
    case 'all':
      push_type_str = 'Todos';
      break;
    case 'oxxo':
      push_type_str = 'Pagar Oxxo';
      break;
    case 'cart':
      push_type_str = 'Productos en Carrito';
      break;
    case 'cart_3_months':
      push_type_str = 'Carrito (+3 Meses sin compra)'
      break;
    case 'buy':
      push_type_str = 'Sin comprar';
      break;
    case 'customer':
      push_type_str = 'Usuarios con compra';
      break;
  }

  let device_str = '';
  switch (device) {
    case 'all':
      device_str = 'Todos';
      break;
    case 'ios':
      device_str = 'iOS';
      break;
    case 'android':
      device_str = 'Android';
      break;
  }

  return (
    <tr>
      {
        !is_sent ? 
        <td className="search-orders_table-item cursor-pointer" onClick={() => props.handleSelectedPushNotification(props.item)}>{title}</td> 
        : 
        <td className="search-orders_table-item">{title}</td>
      }
      <td className="search-orders_table-item">{text}</td>
      <td className="search-orders_table-item">{push_type_str}</td>
      <td className="search-orders_table-item">{send_date}</td>
      <td className="search-orders_table-item"><img alt="notification_image" width="120px" height="120px" src={image_link}></img></td>
      <td className="search-orders_table-item">{device_str}</td>
      <td className="search-orders_table-item">{is_scheduled ? 'Si' : 'No'}</td>
      <td className="search-orders_table-item">
        {
          !is_scheduled && <button className="push-notifications-action-button" onClick={() => props.handleSendPushNotification(id)}>Enviar</button>
        }
        &nbsp;
        {
          is_sent && <button className="push-notifications-action-button" onClick={() => props.handleGetPushNotificationRecord(id)}>Ver registro</button>
        }
        &nbsp;
        {
          !is_sent && <button className="push-notifications-action-button" onClick={() => props.handleSelectedPushNotification(props.item)}>Editar</button>
        }
      </td>
    </tr>
  )
}