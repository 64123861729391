import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';


function PrivateRoute({ component: Component, userToken, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (!userToken) {
          return <Redirect to="/"/>
        } else {
          return <Component {...props}/>
        }
      }}
    />
  );
}

const mapStateToProps = state => ({
  userToken: state.userToken
});

export default connect(mapStateToProps)(PrivateRoute);