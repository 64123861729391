import React from 'react';
import './ProductCard.scss';

const ProductCard = (props) => {
  const { cover_url } = props.product;
  const { product, productType } = props;
  switch (productType) {
    case 'magnet':
      return (
        <div className="card_container my-4 px-3 d-flex flex-column align-items-center">
          <div className="card_top pt-3 d-flex flex-column align-items-between">
            <p className="gotham_bold-blue-14">Especificaciones:</p>
            <div className="w-100 d-flex justify-content-between align-items-start">
              <p className="gotham-book-12">No de copias: {product.quantity}</p>
              <div className="card_data-right d-flex flex-column align-items-start">
                <p className="gotham-book-12">ID: {product.order}</p>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-start align-items-center">
              <p className="gotham-book-12">Tamaño: {product.size}</p>
            </div>
          </div>
          <div className="card_border" />
          <div className="card_center d-flex justify-content-center align-items-center">
            <div className="card_magnet" >
              <img src={product.cover} height="100%" width="100%" alt="" />
            </div>
          </div>
          <div className="card_bottom d-flex justify-content-between align-items-start">
            <button className="card_button-v3" onClick={() => props.handleReprintSingleProduct(product.id, productType)}>Reencolar</button>
            <button className="card_button-v3" onClick={() => props.goToTemplates('magnet', product.id, null)}>Ver proyecto</button>
          </div>
        </div>
      );
    case 'picture':
      const frameBackground = {
        backgroundImage: 'url(' + product.frame_url + ')',
        backgroundSize: 'cover'
      };
      const configuration = product.size.split(' ')[3];
      return (
        <div className="card_container my-4 px-3 d-flex flex-column align-items-center">
          <div className="card_top pt-3 d-flex flex-column align-items-between">
            <p className="gotham_bold-blue-14">Especificaciones:</p>
            <div className="w-100 d-flex justify-content-between align-items-start">
              <p className="gotham-book-12">Tipo de marco: {product.size.slice(4)}</p>
              <div className="card_data-right d-flex flex-column align-items-start">
                <p className="gotham-book-12">No de copias: {product.quantity}</p>
                <p className="gotham-book-12">ID: {product.order}</p>
              </div>
            </div>
          </div>
          <div className="card_border" />
          <div className="card_center d-flex justify-content-center align-items-center">
            <div className="card_picture-frame d-flex justify-content-center align-items-center" style={frameBackground}>
              <img className="card_picture-img" src={product.photo} alt="" />
            </div>
          </div>
          <div className="card_bottom d-flex justify-content-between align-items-start">
            <button className="card_button-v3" onClick={() => props.handleReprintSingleProduct(product.id, productType)}>Reencolar</button>
            <button className="card_button-v3" onClick={() => props.goToTemplates('picture', product.id, configuration)}>Ver proyecto</button>
          </div>
        </div>
      );
    case 'ornament':
      return (
        <div className="card_container my-4 px-3 d-flex flex-column align-items-center">
          <div className="card_top pt-3 d-flex flex-column align-items-between">
            <p className="gotham_bold-blue-14">Especificaciones:</p>
            <div className="w-100 d-flex justify-content-between align-items-start">
              <p className="gotham-book-12">No de copias: {product.quantity}</p>
              <div className="card_data-right d-flex flex-column align-items-start">
                <p className="gotham-book-12">ID: {product.order}</p>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-start align-items-center">
              <p className="gotham-book-12">Tamaño: {product.size}</p>
            </div>
          </div>
          <div className="card_border" />
          <div className="card_center d-flex justify-content-center align-items-center">
            <div className="card_ornament" >
              <img src={product.cover} height="100%" width="100%" alt="" />
            </div>
          </div>
          <div className="card_bottom d-flex justify-content-between align-items-start">
            <button className="card_button-v3" onClick={() => props.handleReprintSingleProduct(product.id, productType)}>Reencolar</button>
            <button className="card_button-v3" onClick={() => props.goToTemplates('ornament', product.id, null)}>Ver proyecto</button>
          </div>
        </div>
      );
    case 'giftcard':
      return (
        <div className="card_container my-4 px-3 d-flex flex-column align-items-center">
          <div className="card_top pt-3 d-flex flex-column align-items-between">
            <p className="gotham_bold-blue-14">Especificaciones:</p>
            <div className="w-100 d-flex justify-content-between align-items-start">
              <p className="gotham-book-12">Destinatario: {product.recipient_email}</p>
              <p className="gotham-book-12">ID: {product.id}</p>
            </div>
          </div>
          <div className="card_border" />
          <div className="card_center d-flex justify-content-center align-items-center">
            <div className="card_giftcard-background d-flex justify-content-center align-items-end">
              <img className="card_giftcard-img" src={product.design_url} alt="" />
            </div>
          </div>
          <div className="card_bottom d-flex justify-content-center align-items-start">
            <button className="card_button-v3" onClick={() => props.giftcardResend(product.id)}>Reenviar correo</button>
          </div>
        </div>
      );
    case 'item':
      return (
        <div className="card_container my-4 px-3 d-flex flex-column align-items-center">
          <div className="card_top pt-3 d-flex flex-column align-items-between">
            <p className="gotham_bold-blue-14">Especificaciones:</p>
            <div className="w-100 d-flex justify-content-between align-items-start">
              <p className="gotham-book-12">No de copias: {product.quantity}</p>
              <p className="gotham-book-12">ID: {product.id}</p>
            </div>
            <div className="w-100 d-flex">
              <p className="gotham-book-12">Tipo: {product.name}</p>
            </div>
          </div>
          <div className="card_border" />
          <div className="card_center d-flex justify-content-center align-items-center">
            <img className="card_item-img shadow" src={product.cover_url} alt="" />
          </div>
        </div>
      );
    default:
      const coverStyle = {
        backgroundImage: 'url(' + (product.mask_cover ? cover_url : product.predesign_cover) + ')',
        backgroundSize: 'cover'
      };
      return (
        <div className="card_container my-4 px-3 d-flex flex-column align-items-center">
          <div className="card_top pt-3 d-flex flex-column justify-content-between">
            <p className="gotham_bold-blue-14">Especificaciones:</p>
            <div className="w-100 d-flex justify-content-between align-items-start">
              <div className="d-flex flex-column align-items-start">
                <p className="gotham-book-12">Tamaño: {product.size}</p>
                <p className="gotham-book-12">Número de páginas: {product.pages_number}</p>
                <p className="gotham-book-12">Tipo de pasta: {product.hard_cover ? 'Dura' : 'Suave'}</p>
              </div>
              <div className="card_data-right d-flex flex-column align-items-start">
                <p className="gotham-book-12">No de copias: {product.quantity}</p>
                <p className="gotham-book-12">ID: {product.order}</p>
              </div>
            </div>
            <p className="gotham-book-12">Lomo: {product.name}</p>
          </div>
          <div className="card_border" />
          <div className="card_center d-flex align-items-center justify-content-center" >
            {(() => {
              if (product.predesign_cover) {
                return (
                  <div className="card_album shadow d-flex" style={coverStyle}>
                    <div className="card_album-left"></div>
                    <div className="card_album-right d-flex align-items-center justify-content-center"></div>
                  </div>
                )
              } else if (product.mask_cover) {
                return (
                  <div className="card_album shadow d-flex" style={coverStyle}>
                    <div className="card_album-left"></div>
                    <div className="card_album-right d-flex align-items-center justify-content-center">
                      <img className="card_img" src={product.mask_cover.thumbnail_square_url} height="100%" width="100%" alt=""/>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div className="card_album shadow d-flex">
                    <div className="card_album-left"></div>
                    <div className="card_album-right d-flex align-items-center justify-content-center">
                      <img className="card_img" src={cover_url} height="90%" width="90%" alt=""/>
                    </div>
                  </div>
                )
              }
            })()}
          </div>
          <div className="card_bottom">
            <div className="d-flex justify-content-between align-items-center">
              <button className="card_button-v1" onClick={() => props.handleReprintSingleProduct(product.id, productType)}>Reencolar</button>
              <button className="card_button-v2" onClick={() => props.goToTemplates('cover', product.id, null)}>Cover</button>
            </div>
            <div className="pt-3 d-flex justify-content-center align-items-center">
              <button className="card_button-v3" onClick={() => props.goToTemplates('album', product.id, null)}>Álbum</button>
            </div>
          </div>
        </div>
      );
  }
}

export default ProductCard;