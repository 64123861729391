import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSurveyDetail } from '../../services';
import SurveyStatistics from '../surveyStadistics/SurveyStadistics';
import Modal from '../modal/Modal';
import Loading from '../loading/Loading';
import AlertView from '../alertView/AlertView';
import './SurveyDetail.scss';

import surveys from '../../images/surveys.png';
import favorite from '../../images/favorite.png';


class SurveyDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      questions: [],
      token_stadistics: {},
      customersAnswers: [],
      aswers: [],
      isLoading: true,
      showLoacalLoading: false,
      showError: false,
      errorMessage: ''
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getSurveyDetail(id);
  }

  getSurveyDetail = async(id) => {
    const { userToken } = this.props;
    try {
      const response = await getSurveyDetail(id, userToken.access);
      if (response.status === 200) {
        var questions = response.data.questions
        var answers = []
        var aux
        for (var i = 0; i < questions.length; i++) {
          answers = answers.concat(questions[i].answers)
          for (var j = 0; j < questions.length - 1; j++) {
              if (questions[j].answers.length < questions[j + 1].answers.length)
              {
                  aux = questions[j];
                  questions[j] = questions[j + 1];
                  questions[j + 1] = aux;
              }
          }
        }
        
        var questionMoreAnswers = questions[0]
        let uniqueAnswers = questionMoreAnswers.answers.filter((value, index) => {
          const _value = JSON.stringify(value);
          return index === questionMoreAnswers.answers.findIndex(obj => {
            return JSON.stringify(obj) === _value;
          });
        });

        var customersAnswersArray = []
        for (var i = 0; i < uniqueAnswers.length; i++) {
          let answer = uniqueAnswers[i]
          let customerAnswers = answers.filter(({ customer_id }) => customer_id == answer.customer_id)
          let customerUniqueAnswers = customerAnswers.filter((value, index) => {
            const _value = JSON.stringify(value);
            return index === customerAnswers.findIndex(obj => {
              return JSON.stringify(obj) === _value;
            });
          });
          let dict = {
            customer_id: answer.customer_id,
            customer_name: answer.customer_name,
            answers: customerUniqueAnswers
          }
          customersAnswersArray.push(dict)
        }

        this.setState({
          title: response.data.title,
          questions: questions,
          token_stadistics: response.data.token_stadistics,
          customersAnswers: customersAnswersArray,
          isLoading: false
        });
      }
    } catch(e) {
      this.setState({
        showError: true,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.'
      })
    }
  }

  handleSelectUser = (customerId) => {
    this.props.history.push({
      pathname: '/user/detail',
      id: customerId
    })
  }

  render() {
    const { title, questions, token_stadistics, customersAnswers, isLoading, showError, errorMessage, } = this.state;
    return (
      isLoading ? <Loading/> :
      <React.Fragment>
        {showError && 
          <Modal>
            <AlertView
              handleOkButton={this.handleOkError}
              message={errorMessage}
            />
          </Modal>
        }
        <div className="survey-detail_main">
          <div className="survey-detail_header pb-3 pt-3 pl-5 d-flex align-items-center">
            <div className="d-flex flex-column justify-content-center align-items-start">
              <div className="d-flex align-items-center">
                <img className="ml-5 mr-2 mb-2" src={surveys} alt="" height="25" />
                <p className="gotham_bold-white-24">{title}</p>
              </div>
              <div className="gotham_book-white-24">Preguntas:</div>
              {questions.length > 0 && questions.map((question, i) => (
                  <div className="gotham_book-white-24">
                    {question.text} 
                    {question.type == "scale" && 
                      <div className="gotham_book-white-24 ml-3">Promedio: {question.average} <img src={favorite} alt="" height="20" /></div> 
                    }
                    {question.type == "note" && 
                      <div className="gotham_book-white-24 ml-3">Respuestas: {question.answers.length}</div>
                    }
                  </div>
                ))}
            </div>
          </div>

          <div className="survey-detail_content d-flex">
            <div className="survey-detail_status">
              <SurveyStatistics
                token_stadistics={token_stadistics}
              />
            </div>
            <div className="survey-detail_center py-5 d-flex flex-column align-items-center">
              <div className="survey-detail_section-title mb-3 d-flex justify-content-start align-items-center" >
                <p className="gotham_bold-24 ml-4" height="32">Respuestas</p>
              </div>
                {customersAnswers.length > 0 && customersAnswers.map((customerAnswers, i) => (
                  <div className="survey-detail_section-detail py-4 px-5 mb-2 d-flex flex-column">
                    <div className="d-flex align-items-center">
                      <p className="gotham_bold-16 cursor-pointer" onClick={() => this.handleSelectUser(customerAnswers.customer_id)}>{customerAnswers.customer_name}</p>
                    </div>
                    {customerAnswers.answers.length > 0 && customerAnswers.answers.map((answer, i) => (
                      
                      <div className="d-flex align-items-center">
                        <p className="gotham_book-16 ml-2">{answer.text}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

export default connect(mapStateToProps)(SurveyDetail);

