import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import './App.scss';

//Components

import Navigation from './components/navigation/Navigation';
import PrivateRoute from './components/privateRoute/PrivateRoute';
import Login from './components/login/Login';
import SearchOrders from './components/searchOrders/SearchOrders';
import OrderDetail from './components/orderDetail/OrderDetail';
import Album from './components/templates/album/Album';
import Cover from './components/templates/cover/Cover';
import UserDetail from "./components/userDetail/UserDetail";
import Magnet from "./components/templates/magnet/Magnet";
import Picture from "./components/templates/picture/Picture";
import CreateCoupon from './components/createCoupon/CreateCoupon';
import SearchCopouns from './components/searchCoupons/SearchCopouns';
import CreateCover from './components/createCover/CreateCover';
import CoverCategories from './components/coverCategories/CoverCategories';
import Covers from './components/covers/Covers';
import CreatePushNotification from './components/createPushNotification/CreatePushNotification';
import SearchPushNotifications from './components/searchPushNotifications/SearchPushNotifications';
import CreateBanner from './components/createBanner/CreateBanner';
import SearchShippingGuide from './components/searchShippingGuide/SearchShippingGuide';
import SearchSurveys from './components/searchSurveys/SearchSurveys';
import SurveyDetail from './components/surveyDetail/SurveyDetail';


function App() {
  return (
    <div>
      <Provider store={store}>
        <Router>
          <Navigation />
          <Switch>
            <Route exact path="/" component={Login} />
            <PrivateRoute exact path="/search/" component={SearchOrders} />
            <PrivateRoute exact path="/order/detail/:id/" component={OrderDetail} />  
            <PrivateRoute exact path="/user/detail/" component={UserDetail} />  
            <PrivateRoute exact path="/magnetdetail/:id/" component={Magnet} />
            <PrivateRoute exact path="/picturedetail/:config/:id/" component={Picture} /> 
            <PrivateRoute exact path="/albumdetail/:id/" component={Album} />
            <PrivateRoute exact path="/coverdetail/:id/" component={Cover} />
            <PrivateRoute exact path="/coupons/" component={SearchCopouns}/>
            <PrivateRoute exact path="/createcoupon/" component={CreateCoupon}/>
            <PrivateRoute exact path="/createcoupon/:id/" component={CreateCoupon}/>
            <PrivateRoute exact path="/createcover/" component={CreateCover}/>
            <PrivateRoute exact path="/:type/cover/categories" component={CoverCategories}/>
            <PrivateRoute exact path="/:type/cover/categories/:id" component={Covers}/>
            <PrivateRoute exact path="/:type/cover/categories/:id/createcover" component={CreateCover}/>
            <PrivateRoute exact path="/:type/categories" component={CoverCategories}/>
            <PrivateRoute exact path="/:type/categories/:id" component={Covers}/>
            <PrivateRoute exact path="/:type/categories/:id/createquote" component={CreateCover}/>
            <PrivateRoute exact path="/createpushnotification/" component={CreatePushNotification}/>
            <PrivateRoute exact path="/createpushnotification/:id/" component={CreatePushNotification}/>
            <PrivateRoute exact path="/pushnotifications/" component={SearchPushNotifications}/>
            <PrivateRoute exact path="/banners/:type" component={CoverCategories}/>
            <PrivateRoute exact path="/banners/:type/create" component={CreateBanner}/>
            <PrivateRoute exact path="/banners/:type/:id" component={CreateBanner}/>
            <PrivateRoute exact path="/searchshippingguides" component={SearchShippingGuide}/>
            <PrivateRoute exact path="/surveys/" component={SearchSurveys}/>
            <PrivateRoute exact path="/surveys/detail/:id" component={SurveyDetail}/>
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        </Router>      
      </Provider>
    </div>
  );
}

export default App;
