import React from 'react';
import './SearchSurveys';

export default function SurveysSearchResultRow(props) {
  const {
    id,
    title,
    voucher_code,
    key,
    is_active
  } = props.item;

  let survey_type_str = '';
  switch (key) {
    case 'place_order':
      survey_type_str = 'Compras';
      break;
  }

  return (
    <tr>
      <td className="search-orders_table-item cursor-pointer" onClick={() => props.handleSelectedSurvey(props.item)}>{title}</td>
      <td className="search-orders_table-item">{voucher_code}</td>
      <td className="search-orders_table-item">{survey_type_str}</td>
      <td className="search-orders_table-item">{is_active ? "Activa" : "No Activa"}</td>
    </tr>
  )
}