import React from 'react';
import './HardCoverAlbumTemplate16.scss';

export default function HardCoverAlbumTemplate16({photos, albumData, order_number}) {
  const renderIndicators = (i) => {
    if (i+1 === 33) {
      return (
        <div className="album-template_indicators-container d-flex justify-content-start">
          <div className="album-template_indicator" style={{backgroundColor: 'yellow'}}></div>
        </div>
      )
    } else if (i+1 === 49) {
      return (
        <div className="album-template_indicators-container d-flex justify-content-start">
          <div className="album-template_indicator" style={{backgroundColor: 'red'}}></div>
        </div>
      )
    } else if (i+1 === 17) {
      return (
        <div className="album-template_indicators-container d-flex justify-content-end">
          <div className="album-template_indicator" style={{backgroundColor: 'green'}}></div>
        </div>
      )
    } else {
      return (<div className="album-template_indicators-container"></div>);
    }
  } 
  return (
    <div className="album_main-container">
      <style dangerouslySetInnerHTML={{
        __html: `
              @media print {
                @page {
                  size: 15.7cm 16cm;
                  margin: 0;
                  padding: 0;
                  page-break-after: always;
                }
              }
            `
      }}/>
      <div className="album-template_main">
        {photos.map((photo, i) => (
          <div key={i} 
            className="album-template_page"
            style={{paddingLeft: (i+1) % 2 === 0 ? '2.5395cm' : '2.9915cm'}}
          >
            {renderIndicators(i)}
            {i === 0 && <span className="album-template_ordernum">{order_number}</span>}
            <div className="album-template_header">
              {(photo.editable && albumData.dates) &&
                <p 
                  className="album-template_date" 
                  style={{textAlign: (i+1) % 2 === 0 ? 'start' : 'end'}}
                >
                  {photo.date}
                </p>
              }
            </div>
            <img style={{width: '17.949cm', height:'17.949cm', objectFit: 'contain'}} src={photo.image_url} alt="" />
            <div className="album-template_footer">
              <p className="album-template_comment">{photo.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}