import React, { Component } from 'react';
import OrderStatusItem from './OrderStatusItem';

import cancel_blue from '../../images/cancelorder-blue.png';
import cancel_white from '../../images/cancelorder-white.png';
import print_blue from '../../images/print-blue.png';
import print_white from '../../images/print-white.png';
import sent_blue from '../../images/sent-blue.png';
import sent_white from '../../images/sent-white.png';
import push_error_blue from '../../images/order-detail/push-error-blue.png';
import push_error_white from '../../images/order-detail/push-error-white.png';
import error_blue from '../../images/error-blue.png';
import error_white from '../../images/error-white.png';
import requeue_blue from '../../images/requeue-blue.png';
import './OrderStatus.scss';

 class OrderStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cancel: {
        id: 0,
        title: 'Cancelar',
        activeIcon: cancel_white,
        inactiveIcon: cancel_blue,
        isActive: this.props.status === 'cancelled'
      },
      printed: {
        id: 1,
        title: 'Impresa',
        activeIcon: print_white,
        inactiveIcon: print_blue,
        isActive: this.props.status === 'printed'
      },
      sent: {
        id: 2,
        title: 'Enviada',
        activeIcon: sent_white,
        inactiveIcon: sent_blue,
        isActive: this.props.status === 'shipped'
      },
      reprinted: {
        id: 3,
        title: 'Reimprimir orden',
        activeIcon: print_white,
        inactiveIcon: print_blue,
        isActive: this.props.status === 'reprinted'
      },
      isPriority: {
        id: 4,
        title: 'Error',
        activeIcon: error_white,
        inactiveIcon: error_blue,
        isActive: this.props.is_priority
      },
      error: {
        id: 5,
        title: 'Push Error',
        activeIcon: push_error_white,
        inactiveIcon: push_error_blue,
        isActive: false
      },
      requeued: {
        id: 6,
        title: 'Reencolar orden',
        activeIcon: print_white,
        inactiveIcon: requeue_blue,
        isActive: false
      },
    }
  }



  render() {
    const { cancel, printed, sent, reprinted, isPriority, error, requeued} = this.state;
    const statusItems = [cancel, printed, sent];
    const actionsItems = [isPriority, error, requeued, reprinted];
    return (
      <div className="order-status_main d-flex flex-column">
        <div className="order-status_top d-flex justify-content-center align-items-center">
          <p className="gotham-md-darkfont-15">Estatus:</p>
        </div>
        <div 
          className="d-flex flex-column justify-content-around align-items-center"
          style={{height: '40%'}}
        >
          {statusItems.map((item, i) => (
            <OrderStatusItem
              key={i}
              item={item}
              handleUpdateStatus={this.props.handleUpdateStatus}
            />
          ))}
        </div>
        <div className="order-status_tile d-flex justify-content-center align-items-center">
          <p className="gotham-md-darkfont-15">Acciones:</p>
        </div>
        <div className="order-status_action-items d-flex flex-column justify-content-around align-items-center">
          {actionsItems.map((item, i) => (
            <OrderStatusItem
              key={i}
              item={item}
              handleUpdateStatus={this.props.handleUpdateStatus}
            />
          ))}
        </div>
        
      </div>
    );
  }
}

export default OrderStatus;