import React from 'react';
import './CategoryProductCard.scss';

import edit from '../../images/edit.png';

const CategoryProductCard = (props) => {
  const { category, coverType, cover, product } = props;
  switch (coverType) {
    case 'category':
      const isActiveCategory = category.is_active ? 'Deshabilitar' : 'Habilitar';
      return (
        <div className="card_main-container d-flex flex-column align-items-center">
          <div className="card-category_container">
            <div className="mt-3 mb-3 d-flex flex-row align-items-center justify-content-between">
              <p className="ml-4 gotham_bold-12">{category.count} {product}</p>
              <div className="cursor-pointer mr-4 d-flex justify-content-center align-items-center" onClick={() => props.handleShowEditName(category)}>
                <img src={edit} alt="" height="14" />
                <p className="gotham_bold-14 ml-2">{category.name}</p>
              </div>
            </div>
            <div className="card-cover_border" />
            <div className="card-cover_center mt-4 d-flex justify-content-center align-items-center">
              <div className="card-cover_album" >
                <img src={category.image_url} height="100%" width="100%" alt="" />
              </div>
            </div>
            <div className="card-cover_bottom pt-4">
              <div className="ml-4 mr-4 d-flex justify-content-between align-items-center">
                <button className="card-cover_button" onClick={() => props.handleSelectCategory(category)}>Ver {product}</button>
                <button className="card-cover_button" onClick={() => props.handleChangeStatusCategory(category.id, {"is_active": !category.is_active})}>{isActiveCategory}</button>
              </div>
              <div className="pt-3 d-flex justify-content-center align-items-center">
                <button className="card-cover_button" onClick={() => props.handlerFileInput(category)}>Cambiar vista previa</button>
              </div>
            </div>
          </div>
        </div>
      );
    case 'quote':
      const isActiveQuote = cover.is_active ? 'Deshabilitar' : 'Habilitar';
      return (
        !cover.is_active ? <div></div> :
        <div className="card-quote_main-container d-flex flex-column align-items-center">
          <div className="card-cover_container">
            <div className="card-cover_center mt-4 d-flex justify-content-center">
              <div className="card-cover_album" >
                <img src={cover.image_url} height="90%" width="100%" alt="" />
              </div>
            </div>
            <div className="card-cover_bottom pt-5">
              <div className="ml-4 mr-4 mt-4 d-flex justify-content-center align-items-center">
                <button className="card-cover_button" onClick={() => props.handleChangeStatusCategory(cover.id, {"is_active": !cover.is_active})}>{isActiveQuote}</button>
              </div>
            </div>
          </div>
        </div>
      );
    case 'landing_banner':
        return (
          <div className="card-cover_main-container">
            <div className="card-cover_container d-flex flex-column align-items-between justify-content-between">
              <div className="card-banner_center d-flex justify-content-center">
                <div className="card-cover" >
                  <p className="mt-3 gotham_bold-14 d-flex flex-row align-items-center justify-content-center">{category.name === 'landing_web' ? 'Escritorio' : 'Móviles'}</p>
                  <img src={category.description} width="100%" className="card-banner mt-2" alt="" />
                </div>
              </div>
              <div className="card-cover_bottom mt-2">
                <div className="ml-4 mr-4 d-flex justify-content-center align-items-center">
                  <button className="card-cover_button" onClick={() => props.handlerEditBanner(category.id)}>Editar</button>
                </div>
              </div>
            </div>
          </div>
        );
    case 'app_banner':
      return (
        <div className="card-banner_main-container">
          <div className="card-banner_container d-flex flex-column align-items-between justify-content-between">
            <div className="card-banner_center mt-4 mb-3 d-flex justify-content-center">
              <div className="card-cover" >
                <img src={category.image_url} width="100%" className="card-banner" alt="" />
              </div>
            </div>
            <div className="mb-2 d-flex flex-column align-items-start justify-content-between">
              <p className="ml-4 gotham_bold-14">Titulo:</p>
              <p className="ml-4 mr-4 gotham_book-12">{category.title}</p>
            </div>
            <div className="mb-2 d-flex flex-column align-items-start justify-content-between">
              <p className="ml-4 gotham_bold-14">Descripción:</p>
              <p className="ml-4 mr-4 gotham_book-12">{category.description}</p>
            </div>
            <div className="card-cover_bottom mt-2">
              <div className="ml-4 mr-4 d-flex justify-content-center align-items-center">
                <button className="card-cover_button" onClick={() => props.handlerEditBanner(category.id)}>Editar</button>
              </div>
            </div>
          </div>
        </div>
      );
    default:
      const coverStyle = {
        backgroundImage: 'url(' + cover.thumbnail_url + ')',
        backgroundSize: 'cover'
      };
      const isActiveCover = cover.is_active ? 'Deshabilitar' : 'Habilitar';
      return (
        !cover.is_active ? <div></div> :
        <div className="card-cover_main-container d-flex flex-column align-items-center">
          <div className="card-cover_container">
            <div className="card-cover_center mt-4 d-flex justify-content-center">
              <div className="card-cover" >
                <img src={cover.thumbnail_url} width="100%" alt="" />
              </div>
            </div>
            <div className="card-cover_bottom pt-5">
              <div className="ml-4 mr-4 mt-2 d-flex justify-content-center align-items-center">
                <button className="card-cover_button" onClick={() => props.handleChangeStatusCategory(cover.id, {"is_active": !cover.is_active})}>{isActiveCover}</button>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

export default CategoryProductCard;