
export const saveUserDataAction = (token, userName) => (dispatch) => {
    localStorage.setItem('USER_TOKEN', JSON.stringify(token));
    localStorage.setItem('USER_NAME', JSON.stringify(userName));
    dispatch({
        type: 'UPLOAD_USER_TOKEN',
        payload: token
    });
    dispatch({
        type: 'SET_USER_NAME',
        payload: userName
    });
}

export const updateUserTokenAction = (token) => (dispatch) => {
    localStorage.setItem('USER_TOKEN', JSON.stringify(token));
    dispatch({
        type: 'UPLOAD_USER_TOKEN',
        payload: token
    })
}

export const removeUserDataAction = () => (dispatch) => {
    localStorage.removeItem('USER_TOKEN');
    localStorage.removeItem('USER_NAME');
    dispatch({
        type: 'REMOVE_USER_TOKEN',
        payload: null
    });
    dispatch({
        type: 'REMOVE_USER_NAME',
        payload: null
    });
}