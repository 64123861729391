import React, { Component } from 'react';
import { getCoverDetail } from '../../../services';
import { connect } from 'react-redux';
import Loading from '../../loading/Loading';
import Modal from '../../modal/Modal';
import AlertView from '../../alertView/AlertView';
import SoftCover16 from './160/SoftCover16';
import SoftCover21 from './210/SoftCover21';
import HardCover16 from './160/HardCover16';
import HardCover21 from './210/HardCover21';
import PredesignSoftCover16 from './160/PredesignSoftCover16';
import PredesignSoftCover21 from './210/PredesignSoftCover21';
import PredesignHardCover16 from './160/PredesignHardCover16';
import PredesignHardCover21 from './210/PredesignHardCover21';
import './Cover.scss';

class Cover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      coverData: null,
      configuration: null
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getCoverData(id);
  }

  getCoverData = async(id) => {
    const { userToken } = this.props;
    try {
      const configuration = JSON.parse(localStorage.getItem('cover_config'))
      const response = await getCoverDetail(id, userToken.access);
      if (response.status === 200) {
        this.setState({
          isLoading: false,
          coverData: response.data,
          configuration: configuration
        })
      }
    } catch(e) {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ha ocurrido un error, por favor intenta más tarde.'
      })
    }
  }

  handleOkError = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  render() {
    const { isLoading, coverData, configuration, showError, errorMessage } = this.state;
    const renderCover = (configuration) => {
      if (!configuration.hard_cover && configuration.size === '16x16cm' && !configuration.predesign_cover) {
        return <SoftCover16 coverData={coverData}/>
      } else if (!configuration.hard_cover && configuration.size !== '16x16cm' && !configuration.predesign_cover) {
        return <SoftCover21 coverData={coverData} />
      } else if (configuration.hard_cover && configuration.size === '16x16cm' && !configuration.predesign_cover) {
        return <HardCover16 coverData={coverData} />
      } else if (configuration.hard_cover && configuration.size !== '16x16cm' && !configuration.predesign_cover) {
        return <HardCover21 coverData={coverData} />
      } else if (!configuration.hard_cover && configuration.size === '16x16cm' && configuration.predesign_cover) {
        return <PredesignSoftCover16 coverData={coverData} />
      } else if (!configuration.hard_cover && configuration.size !== '16x16cm' && configuration.predesign_cover) {
        return <PredesignSoftCover21 coverData={coverData} />
      } else if (configuration.hard_cover && configuration.size === '16x16cm' && configuration.predesign_cover) {
        return <PredesignHardCover16 coverData={coverData} />
      } else if (configuration.hard_cover && configuration.size !== '16x16cm' && configuration.predesign_cover) {
        return <PredesignHardCover21 coverData={coverData} />
      } else {
        return;
      }
    }
    return (
      isLoading ? <Loading/> :
      <div className="cover_main">
        {showError && 
          <Modal>
            <AlertView
              handleOkButton={this.handleOkError}
              message={errorMessage}
            />
          </Modal>
        }
        {renderCover(configuration)}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

export default connect(mapStateToProps)(Cover);