import { createStore, applyMiddleware, compose } from 'redux';
import mainReducer from './reducers';
import thunk from 'redux-thunk';
import promise from 'redux-promise';


const middleware = [thunk, promise];


const store = createStore(mainReducer, compose(
    applyMiddleware(...middleware)
));




export default store;