import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFilteredOrders, getFilteredOrdersByStatus, getNextPageOrders } from '../../services';
import SearcResultRow from './SearchResultRow';
import Loading from '../loading/Loading';


import unselected from '../../images/unselected-img.png';
import selected from '../../images/selected-img.png';
import search_img from '../../images/search-img.png';
import warning_img from '../../images/warning-img.png';
import './SearchOrders.scss';


class SearchOrders extends Component {

  state = {
    searchText: '',
    orders: [],
    selectedPaid: false,
    selectedPriorities: false,
    selectedPrinted: false,
    data: null,
    showError: false,
    errorMessage: '',
    isLoading: false,
    showLocalLoading: false
  }

  handleTextInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = async(e) => {
    e.preventDefault();
    console.log('test-submit')
    this.setState({
      showError: false,
      errorMessage: '',
      isLoading: true
    });
    const { searchText, orders } = this.state;
    const { access, refresh } = this.props.userToken;
    if (searchText === '') {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ingresar #Orden, #OXXO, Email o Nombre de usuario.',
      });
    } else {
      let searchType = 'customer__name'
      if (searchText.includes('@')){
        searchType = 'customer__email'
      } else if (/^[0-9]+$/.test(searchText)){
        if (searchText.length >= 14) {
          searchType = 'cash_payment_reference'
        } else {
          searchType = 'id'
        }
      }
      try {
        const response = await getFilteredOrders(searchText, access, searchType);
        if (response.status === 200) {
          if (response.data.count === 0) {
            this.setState({
              showError: true,
              errorMessage: 'No existen órdenes con los criterios utilizados',
              isLoading: false
            });
          } else {
            this.setState({
              isLoading: false,
              orders: response.data.results,
              data: response.data,
              searchText: '',
              selectedPaid: false,
              selectedPrinted: false,
              selectedPriorities: false
            });
          }
        }
      } catch(err) {
        this.setState({
          showError: true,
          isLoading: false,
          errorMessage: 'Ha ocurrido un error, intenta más tarde.',
          searchText: ''
        })
      }
    }
  }

  handleSelectItem = async(slectedCheckbox) => {
    const { selectedPrinted, selectedPriorities, selectedPaid, selectedInternational, orders } = this.state;
    const { access } = this.props.userToken;
    this.setState({isLoading: true});
    const searchPriorityOrders = slectedCheckbox === 'priority' ? true : false;
    const searchInternationalOrders = slectedCheckbox === 'international' ? true : false;
    try {
      const response = await getFilteredOrdersByStatus(slectedCheckbox, access, searchPriorityOrders, searchInternationalOrders);
      if (response.status === 200) {
        if (slectedCheckbox === 'paid') {
          this.setState({
            isLoading: false,
            selectedPaid: true,
            selectedPrinted: false,
            selectedPriorities: false,
            selectedInternational: false,
            orders: response.data.results,
            data: response.data,
            searchText: '',
            showError: false,
          });
        } else if (slectedCheckbox === 'printed') {
          this.setState({
            isLoading: false,
            selectedPaid: false,
            selectedPrinted: true,
            selectedPriorities: false,
            selectedInternational: false,
            orders: response.data.results,
            data: response.data,
            searchText: '',
            showError: false,
          });
        } else if (slectedCheckbox === 'priority') {
          this.setState({
            isLoading: false,
            selectedPaid: false,
            selectedPrinted: false,
            selectedPriorities: true,
            selectedInternational: false,
            orders: response.data.results,
            data: response.data,
            searchText: '',
            showError: false,
          });
        } else if (slectedCheckbox === 'international') {
          this.setState({
            isLoading: false,
            selectedPaid: false,
            selectedPrinted: false,
            selectedPriorities: false,
            selectedInternational: true,
            orders: response.data.results,
            data: response.data,
            searchText: '',
            showError: false,
          });
        }
      }
    } catch(e) {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde'
      });
    }
  }

  handleLoadNextOrders = async() => {
    const { data, orders } = this.state;
    const { access } = this.props.userToken;
    this.setState({showLocalLoading: true});
    const nextPageUrl = data.next;
    try {
      const response = await getNextPageOrders(nextPageUrl, access);
      if (response.status === 200) {
        this.setState({
          orders: orders.concat(response.data.results),
          data: response.data,
          showLocalLoading: false
        });
      }
    } catch(e) {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde',
        isLoading: false
      });
    }
  }

  handleSelectOrder = (id) => {
    window.open(`/order/detail/${id}`, '_blank')
  }

  handleSelectUser = (customerId) => {
    this.props.history.push({
      pathname: '/user/detail',
      id: customerId
    })
  }

  handleOkError = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  render() {
    const { searchText, orders, selectedPaid, selectedPriorities, selectedPrinted, selectedInternational, data, showError, errorMessage, isLoading, showLocalLoading } = this.state;
    return (
      isLoading ? <Loading/> :
      <div className="search-orders_main">
        <form className="search-orders_form d-flex flex-column justify-content-center align-items-center" onSubmit={this.handleSubmit}>
          <div className="search-orders_box d-flex justify-content-between align-items-center">
            <div className={showError ? "search-orders_input-container-error" : "search-orders_input-container"}>
              <input className="search-orders_input" type="text" placeholder="Buscar #Orden, #OXXO, Email o Nombre de usuario" name="searchText" value={searchText} onChange={this.handleTextInput} />
              {showError &&
                <div className="search-orders_error mt-1 d-flex align-items-center justify-content-center">
                  <img src={warning_img} height="18" alt=""/>
                  <p className="gotham-md-red-12 ml-3">{errorMessage}</p>
                </div>              
              }
            </div>
            <button className="search-orders_button" type="submit">
              {isLoading ? 
                <div className="spinner-border text-light"></div>
                : <img src={search_img} height="30" alt="" />
              }
            </button>
          </div>
          <div className="search-orders_filters-box mt-5 d-flex justify-content-center align-items-center">
            <div className="d-flex align-items-center">
              <img className="mr-2 cursor-pointer" src={selectedPaid ? selected : unselected} height="25" alt="" onClick={() => this.handleSelectItem('paid')}/>
              <p className="gotham-bold-darkfont-15">Pagadas</p>
            </div>
            <div className="d-flex align-items-center">
              <img className="ml-4 mr-2 cursor-pointer" src={selectedPriorities ? selected : unselected} height="25" alt="" onClick={() => this.handleSelectItem('priority')}/>
              <p className="gotham-bold-darkfont-15">Errores</p>          
            </div>
            <div className="d-flex align-items-center">
              <img className="ml-4 mr-2 cursor-pointer" src={selectedPrinted ? selected : unselected} height="25" alt="" onClick={() => this.handleSelectItem('printed')}/>
              <p className="gotham-bold-darkfont-15">Impresas</p>
            </div>
            <div className="d-flex align-items-center">
              <img className="ml-4 mr-2 cursor-pointer" src={selectedInternational ? selected : unselected} height="25" alt="" onClick={() => this.handleSelectItem('international')}/>
              <p className="gotham-bold-darkfont-15">Internacionales</p>
            </div>
          </div>
        </form>
        {orders.length > 0 &&
          <div className="pb-5 d-flex flex-column align-items-center">
            <div className="search-orders_count-results pl-2">
              <p className="gotham-md-darkfont-15">Mostrando {orders.length} de {data.count} resultados</p>
            </div>
            <table className="search-orders_table">
              <thead className="search-orders_table-head">
                <tr>
                  <th className="search-orders_table-head-item">ID orden</th>
                  <th className="search-orders_table-head-item">Nombre del cliente</th>
                  <th className="search-orders_table-head-item">Fecha de pago</th>
                  <th className="search-orders_table-head-item">Estatus</th>
                  <th className="search-orders_table-head-item">Productos</th>
                  <th className="search-orders_table-head-item">Con error</th>
                  <th className="search-orders_table-head-item">País</th>
                  <th className="search-orders_table-head-item">ID cobro</th>
                  <th className="search-orders_table-head-item">Total</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((item, i) => (
                  <SearcResultRow
                    key={i}
                    item={item}
                    handleSelectOrder={this.handleSelectOrder}
                    handleSelectUser={this.handleSelectUser}
                  />
                ))}
              </tbody>
            </table>
            {(data && data.count > orders.length) && (
              showLocalLoading ? <Loading isLocalLoading /> :
              <button className="search-orders_see-more my-5" onClick={this.handleLoadNextOrders}>
                Ver más +
              </button>)
            }
          </div>
        }
        
      </div>
    );
  }

}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

export default connect(mapStateToProps)(SearchOrders);