import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../loading/Loading';
import { getCoupons, getNextPageCoupons, getFilteredCoupons } from '../../services';
import CouponSearchResultRow from './CouponSearchResultRow';

import search_img from '../../images/search-img.png';
import warning_img from '../../images/warning-img.png';
import './SearchCoupons.scss';

class SearchCoupons extends Component {

  state = {
    searchText: '',
    coupons: [],
    data: null,
    showError: false,
    errorMessage: '',
    isLoading: false,
    showLocalLoading: false
  }

  componentDidMount() {
    this.getCoupons();
  }

  handleTextInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = async(e) => {
    e.preventDefault();
    this.setState({
      showError: false,
      errorMessage: '',
      isLoading: true
    });
    const { searchText } = this.state;
    const { access } = this.props.userToken;
    if (searchText === '') {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ingresa un cupón',
      });
    } else {
      await getFilteredCoupons(searchText, access)
      .then((response) => {
        if (response.data.count === 0) {
          this.setState({
            showError: true,
            errorMessage: 'No hay resultados para el cupón buscado.',
            isLoading: false
          });
        } else {
          this.setState({
            coupons: response.data.results,
            data: response.data,
            isLoading: false
          });
        }
      })
      .catch((error) => {
        this.setState({
          showError: true,
          errorMessage: 'Ocurrió un error, intenta más tarde',
          isLoading: false
        });
      });
    }
  }

  getCoupons = async() => {
    const { access } = this.props.userToken;
    this.setState({isLoading: true});

    await getCoupons(access)
    .then((response) => {
      this.setState({
        coupons: response.data.results,
        data: response.data,
        isLoading: false
      });
    })
    .catch((error) => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde',
        isLoading: false
      });
    });
  }

  handleLoadNextCoupons = async() => {
    const { data, coupons } = this.state;
    const { access } = this.props.userToken;
    this.setState({showLocalLoading: true});
    const nextPageUrl = data.next;

    await getNextPageCoupons(nextPageUrl, access)
    .then((response) => {
      this.setState({
        coupons: coupons.concat(response.data.results),
        data: response.data,
        showLocalLoading: false
      });
    })
    .catch((error) => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde',
        showLocalLoading: false
      });
    });
  }

  handleSelectedCoupon= (item) => {
    window.open(`/createcoupon/${item.id}`, '_blank')
  }

  render() {
    const { searchText, coupons, data, showError, errorMessage, isLoading, showLocalLoading } = this.state;

    return (
      isLoading ? <Loading/> :
      <div className="search-coupons_main">
        <form className="search-coupons_form d-flex flex-column justify-content-center align-items-center" onSubmit={this.handleSubmit}>
          <div className="search-coupons_box d-flex justify-content-between align-items-center">
            <div className={ showError ? "search-coupons_input-container-error" : "search-coupons_input-container" }>
              <input className="search-coupons_input" type="text" placeholder="Ingresa cupon de descuento" name="searchText" value={searchText} onChange={this.handleTextInput} />
              {showError &&
                <div className="search-coupons_error mt-1 d-flex align-items-center justify-content-center">
                  <img src={warning_img} height="18" alt=""/>
                  <p className="gotham-md-red-12 ml-3">{errorMessage}</p>
                </div>              
              }
            </div>
            <button className="search-coupons_button" type="submit">
              {isLoading ? 
                <div className="spinner-border text-light"></div>
                : <img src={search_img} height="30" alt="" />
              }
            </button>
          </div>
        </form>
        {coupons.length > 0 &&
          <div className="pb-5 d-flex flex-column align-items-center">
            <div className="search-coupons_count-results pl-2">
              <p className="gotham-md-darkfont-15">Mostrando {coupons.length} de {data.count} resultados</p>
            </div>
            <table className="search-coupons_table">
              <thead className="search-orders_table-head">
                <tr>
                  <th className="search-orders_table-head-item">Cupón</th>
                  <th className="search-orders_table-head-item">Descuento</th>
                  <th className="search-orders_table-head-item">Mínimo de compra</th>
                  <th className="search-orders_table-head-item">Primera compra</th>
                  <th className="search-orders_table-head-item">Limite de uso</th>
                  <th className="search-orders_table-head-item">Envío gratis</th>
                  <th className="search-orders_table-head-item">Vigencia</th>
                </tr>
              </thead>
              <tbody>
                {coupons.map((item, i) => (
                  <CouponSearchResultRow
                    key={i}
                    item={item}
                    handleSelectedCoupon={this.handleSelectedCoupon}
                  />
                ))}
              </tbody>
            </table>
            {(data && data.count > coupons.length) && (
              showLocalLoading ? <Loading isLocalLoading /> :
              <button className="search-coupons_see-more my-5" onClick={this.handleLoadNextCoupons}>
                Ver más +
              </button>)
            }
          </div>
        }
        
      </div>
    );
  }

}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

export default connect(mapStateToProps)(SearchCoupons);      