import React from 'react';
import logo_gray from '../../images/logo-gray.png';
import './AlertView.scss';


function AlertView(props) {
  return (
    <div className="alertview_main d-flex justify-content-center align-items-center">
      <div className="alertview_inside d-flex flex-column justify-content-between align-items-center">
        <img className="mt-5" src={logo_gray} alt="" />
        <p className="gotham-md-16">{props.message}</p>
        <button className="generic-button mb-5" onClick={props.handleOkButton}>Ok</button>
      </div>
    </div>
  );  
}


export default AlertView;