import React, { Component } from 'react';
import { getCouponDetail, createCoupon, updateCoupon, getCountries } from '../../services';
import { connect } from 'react-redux';
import './CreateCoupon.scss';
import Loading from '../loading/Loading';
import Modal from '../modal/Modal';
import AlertView from '../alertView/AlertView';

class CreateCoupon extends Component {

  state = {
    title: '',
    code: '',
    terms_and_conditions: '',
    cash: 0,
    percentage: 0,
    minimum_purchase: 0,
    free_shipping: false,
    pay_two_get_three: false,
    get_n: 0,
    pay_m: "0.00",
    once_per_order: true,
    validate_use: false,
    first_purchase_only: false,
    is_private: false,
    group: 'all',
    valid_for: '',
    times: 0,
    valid_until: '',
    products: [],
    voucher_data: null,
    countries: [],
    voucher_countries: [],
    isLoading: false,
    showError: false,
    errorMessage: ''
  }

  componentDidMount() {
    this.getCountries()
  }

  getCountries = async() => {
    this.setState({isLoading: true})
    const { userToken } = this.props;
    await getCountries(userToken.access)
    .then((response) => {
      this.setState({
        countries: response.data.results
      })
      const { id } = this.props.match.params
      if (id) {
        this.getCouponDetail(id)
      } else {
        this.setState({isLoading: false})
      }
    })
    .catch((error) => {
      window.location.replace('/coupons');
    });
  }

  getCouponDetail = async(id) => {
    const { userToken } = this.props;
    await getCouponDetail(id, userToken.access)
    .then((response) => {
      const { title, code, terms_and_conditions, cash, percentage, minimum_purchase, free_shipping, pay_two_get_three, get_n, pay_m, once_per_order, validate_use, first_purchase_only, is_private, groups, valid_for, times, valid_until, products, countries } = response.data
      let group = 'all'
      if (groups.length > 0) {
        group = groups[0].key
      }
      this.setState({
        title: title,
        code: code,
        terms_and_conditions: terms_and_conditions,
        cash: cash,
        percentage: percentage,
        minimum_purchase: minimum_purchase,
        free_shipping: free_shipping,
        pay_two_get_three: pay_two_get_three,
        get_n: get_n,
        pay_m: pay_m,
        once_per_order: once_per_order,
        validate_use: validate_use,
        first_purchase_only: first_purchase_only,
        is_private: is_private,
        group: group,
        valid_for: valid_for.toString(),
        times: times,
        valid_until: valid_until,
        products: products,
        voucher_countries: countries,
        voucher_data: response.data
      })
      this.setState({isLoading: false})
    })
    .catch((error) => {
      window.location.replace('/coupons');
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.name] : (e.target.type === "checkbox" ? e.target.checked : e.target.value)
    });
  }

  handleGetNChange = e => {
    let get_n = 0
    let pay_m = "0.00"
    let pay_two_get_three = false
    if (e.target.checked){
      if (e.target.name === 'pay_two_get_three') {
        get_n = 0
        pay_m = "0.00"
        pay_two_get_three = true
      } else if (e.target.name === 'get_two_pay_one') {
        get_n = 2
        pay_m = "1.00"
        pay_two_get_three = false
      } else if (e.target.name === 'get_two_pay_one_half') {
        get_n = 2
        pay_m = "1.50";
        pay_two_get_three = false
      } else if (e.target.name === 'get_one_pay_half') {
        get_n = 1
        pay_m = "0.50";
        pay_two_get_three = false
      }
    } else {
      get_n = 0
      pay_m = "0.00"
      pay_two_get_three = false
    }

    this.setState({
      get_n : get_n,
      pay_m : pay_m,
      pay_two_get_three : pay_two_get_three
    });
  }

  handleProductsChange = e => {
    let product_array = []
    product_array.push(...this.state.products)

    if (e.target.checked) {
      product_array.push(e.target.value)
    } else {
      for( var i = 0; i < product_array.length; i++){ 
        if ( product_array[i] === e.target.value) {
          product_array.splice(i, 1);
          break;
        }
      }
    }
    
    this.setState({
      products : product_array
    });
  }

  handleCountriesChange = e => {
    let countries_array = []
    countries_array.push(...this.state.voucher_countries)

    if (e.target.checked) {
      for(const country of this.state.countries){
        if ( country.id === e.target.value) {
          countries_array.push(country)
          break;
        }
      }
    } else {
      for( var i = 0; i < countries_array.length; i++){ 
        if ( countries_array[i].id === e.target.value) {
          countries_array.splice(i, 1);
          break;
        }
      }
    }
    
    this.setState({
      voucher_countries : countries_array
    });
  }

  handleSubmit = async(e) => {
    e.preventDefault();
    this.setState({isLoading: true})
    const { userToken } = this.props;
    const { title, code, terms_and_conditions, cash, percentage, minimum_purchase, free_shipping, pay_two_get_three, get_n, pay_m, once_per_order, validate_use, first_purchase_only, is_private, group, valid_for, times, valid_until, products, voucher_data, voucher_countries } = this.state;
    const voucher_data_dict = {};

    if (voucher_data) {
      if (voucher_data.title !== title) {
        voucher_data_dict.title = title
      }
      if (voucher_data.terms_and_conditions !== terms_and_conditions) {
        voucher_data_dict.terms_and_conditions = terms_and_conditions
      }
      if (voucher_data.cash !== cash) {
        voucher_data_dict.cash = parseInt(cash, 10)
      }
      if (voucher_data.percentage !== percentage) {
        voucher_data_dict.percentage = parseInt(percentage, 10)
      }
      if (voucher_data.minimum_purchase !== minimum_purchase) {
        voucher_data_dict.minimum_purchase = parseInt(minimum_purchase, 10)
      }
      if (voucher_data.free_shipping !== free_shipping) {
        voucher_data_dict.free_shipping = free_shipping
      }
      if (voucher_data.pay_two_get_three !== pay_two_get_three) {
        voucher_data_dict.pay_two_get_three = pay_two_get_three
      }
      if (voucher_data.get_n !== get_n) {
        voucher_data_dict.get_n = get_n
      }
      if (voucher_data.pay_m !== pay_m) {
        voucher_data_dict.pay_m = pay_m
      }
      if (voucher_data.once_per_order !== once_per_order) {
        voucher_data_dict.once_per_order = once_per_order
      }
      if ( voucher_data.validate_use !== validate_use) {
        voucher_data_dict.validate_use = validate_use
      }
      if ( voucher_data.first_purchase_only !== first_purchase_only) {
        voucher_data_dict.first_purchase_only = first_purchase_only
      }
      if ( voucher_data.is_private !== is_private) {
        voucher_data_dict.is_private = is_private
      }
      if ( voucher_data.times !== times) {
        voucher_data_dict.times = parseInt(times, 10)
      }
      if (voucher_data.valid_until !== valid_until) {
        if (valid_until.length === 0) {
          voucher_data_dict.valid_until = null;
        } else {
          voucher_data_dict.valid_until = valid_until;
        }
      }

      const data = {}

      if (Object.keys(voucher_data_dict).length !== 0) {
        data.voucher_data = voucher_data_dict
      }

      if (!this.equalsIgnoreOrder(voucher_data.products, products)) {
        const products_key = {
            products: products
        }
        data.products_data = products_key
      }

      if (!this.equalsIgnoreOrder(voucher_data.countries, voucher_countries)) {
        let countries_array = []
        for (let item of voucher_countries) {
          countries_array.push(item.id)
        }
        data.countries_data = countries_array
      }

      let voucher_data_groups = voucher_data.groups[0]
      if (voucher_data.groups.length > 0) {
        if (voucher_data_groups.key !== group && group !== 'all') {
          let group_data = [group]
          data.groups_data = group_data
        } else if (voucher_data_groups.key !== group && group === 'all') {
          data.groups_data = []
        }
      } else {
        if (group !== 'all') {
          let group_data = [group]
          data.groups_data = group_data
        }
      }
      
      if (valid_for === '' && voucher_data.valid_for.toString() !== valid_for) {
        data.valid_for_data = []
      } else if (voucher_data.valid_for.toString() !== valid_for) {
        data.valid_for_data = valid_for.split(",")
      }

      if (Object.keys(data).length !== 0) {
        await updateCoupon(voucher_data.id, userToken.access, data)
        .then((response) => {
          window.location.replace('/coupons');
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            showError: true,
            errorMessage: error.response.data.message
          });
        });
      } else {
        window.location.replace('/coupons');
      }      

    } else {
      voucher_data_dict.title = title;
      voucher_data_dict.terms_and_conditions = terms_and_conditions;
      voucher_data_dict.code = code;
      voucher_data_dict.cash = parseInt(cash, 10);
      voucher_data_dict.percentage = parseInt(percentage, 10);
      voucher_data_dict.minimum_purchase = parseInt(minimum_purchase, 10);
      voucher_data_dict.free_shipping = free_shipping;
      voucher_data_dict.is_private = is_private;
      voucher_data_dict.pay_two_get_three = pay_two_get_three;
      voucher_data_dict.get_n = get_n;
      voucher_data_dict.pay_m = pay_m;
      voucher_data_dict.once_per_order = once_per_order;
      voucher_data_dict.validate_use = validate_use;
      voucher_data_dict.first_purchase_only = first_purchase_only;
      voucher_data_dict.times = parseInt(times, 10);

      if (valid_until.length === 0) {
        voucher_data_dict.valid_until = null;
      } else {
        voucher_data_dict.valid_until = valid_until;
      }
      
      let group_data = []
      if (group !== 'all') {
        group_data = [group]
      }

      let valid_for_data = []
      if (valid_for !== '') {
        valid_for_data = valid_for.split(",")
      }

      let countries_data = []
      for (let item of voucher_countries) {
        countries_data.push(item.id)
      }

      const data = {
        voucher_data: voucher_data_dict,
        products_data: {
          products: products
        },
        countries_data: countries_data,
        groups_data: group_data,
        valid_for_data: valid_for_data
      }

      await createCoupon(userToken.access, data)
      .then((response) => {
        window.location.replace('/coupons');
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          showError: true,
          errorMessage: error.response.data.message
        });
      });
    }
  }

  equalsIgnoreOrder = (a, b) => {
    if (a.length !== b.length) return false;
    const uniqueValues = new Set([...a, ...b]);
    for (const v of uniqueValues) {
      const aCount = a.filter(e => e === v).length;
      const bCount = b.filter(e => e === v).length;
      if (aCount !== bCount) return false;
    }
    return true;
  }

  handleOkError = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  render() {
    const { title, code, terms_and_conditions, cash, percentage, minimum_purchase, free_shipping, pay_two_get_three, get_n, pay_m, once_per_order, validate_use, first_purchase_only, is_private, group, valid_for, times, valid_until, products, voucher_data, isLoading, showError, errorMessage, countries, voucher_countries } = this.state;
    let title_componente = "Crear cupón";
    let button_title = "Crear cupón";
    let is_coupon_disable = false;
    let selectedAlbum = false;
    let selectedMagnet = false;
    let selectedPicture = false;
    let selectedOrnament = false;

    if (voucher_data) {
      title_componente = "Detalle del cupón";
      button_title = "Guardar cupón";
      is_coupon_disable = true;
    }

    for( var i = 0; i < products.length; i++){ 
      switch (products[i]) {
        case "album":
          selectedAlbum = true
          break;
        case "magnet":
          selectedMagnet = true
          break;
        case "picture":
          selectedPicture = true
          break;
        case "ornament":
          selectedOrnament = true
          break;
        default:
          break;
      }
    }

    return (
      isLoading ? <Loading/> :
      <div className="create-coupon_main mt-4 ml-5 mr-5 mb-5">
        {showError && 
          <Modal>
            <AlertView
              handleOkButton={this.handleOkError}
              message={errorMessage}
            />
          </Modal>
        }
        <div className="create-coupon_inside">
          {voucher_data &&
          <div class="create-coupon-count-container">
            Usado
            <div class="create-coupon-count">
              {voucher_data.used_count}
            </div>
            veces
          </div>
          }
          <div class="d-flex justify-content-center mb-3">
            <h1>{title_componente}</h1>
          </div>
          <form className="create-coupon-form" onSubmit={this.handleSubmit}>
            <hr className="cart_info-inline"></hr>
            <div className="create-coupon-input-container">
              <label className='create-coupon-input-label mr-3'>Ingresa un título: </label>
              <input 
              type="text"
              placeholder=""
              name="title"
              value={title}
              onChange={this.handleChange}
              />
              <label className='create-coupon-input-label-description ml-3'>Título que aparecera en "Mis promosiones"</label>
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-coupon-input-container">
              <label className='create-coupon-input-label mr-3'>Ingresa un cupón valido: </label>
              <input 
              type="text"
              placeholder="" 
              name="code"
              value={code}
              onChange={this.handleChange}
              disabled={is_coupon_disable}
              />
              <label className='create-coupon-input-label-description ml-3'>Si no se especifica se creará un cupón aleatorio</label>
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-coupon-input-container">
              <label className='create-coupon-input-label mr-3'>Términos y condiciones: </label>
              <textarea 
              name="terms_and_conditions" 
              cols="40" 
              rows="10" 
              id="terms_and_conditions" 
              onChange={this.handleChange} 
              value={terms_and_conditions} >
              </textarea>
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-coupon-input-container">
              <label className='create-coupon-input-label mr-3'>Descuento en dinero: </label>
              <input
                type="number"
                placeholder=""
                max="9999"
                name="cash"
                value={cash} 
                onChange={this.handleChange}
                disabled={percentage >= 1 ? true : false}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-coupon-input-container">
              <label className='create-coupon-input-label mr-3'>Descuento en porcentaje a orden: </label>
              <input 
                type="number" 
                placeholder="" 
                max="9999"
                name="percentage" 
                value={percentage} 
                onChange={this.handleChange}
                disabled={cash >= 1 ? true : false}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-coupon-input-container">
              <label className='create-coupon-input-label mr-3'>3x2:</label>
              <input 
              type="checkbox"
              name="pay_two_get_three"
              checked={pay_two_get_three}
              onChange={this.handleGetNChange}
              disabled={cash > 0 || percentage > 0}
              />
              <label className='create-coupon-input-label mr-3'>&nbsp;&nbsp;&nbsp;2x1:</label>
              <input 
              type="checkbox"
              name="get_two_pay_one"
              checked={get_n === 2 && pay_m === "1.00"}
              onChange={this.handleGetNChange}
              disabled={cash > 0 || percentage > 0}
              />
              <label className='create-coupon-input-label mr-3'>&nbsp;&nbsp;&nbsp;2x1 -50% en Producto:</label>
              <input 
              type="checkbox"
              name="get_two_pay_one_half"
              checked={get_n === 2 && pay_m === "1.50"}
              onChange={this.handleGetNChange}
              disabled={cash > 0 || percentage > 0}
              />
              <label className='create-coupon-input-label mr-3'>&nbsp;&nbsp;&nbsp;-50% en Producto:</label>
              <input 
              type="checkbox"
              name="get_one_pay_half"
              checked={get_n === 1 && pay_m === "0.50"}
              onChange={this.handleGetNChange}
              disabled={cash > 0 || percentage > 0}
              />
            </div>
            <div className="create-coupon-input-container">
              <label className='create-coupon-input-label mr-3'>Una vez por orden: </label>
              <input 
                type="checkbox"
                name="once_per_order"
                checked={once_per_order}
                onChange={this.handleChange}
                disabled={cash > 0 || percentage > 0}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-coupon-input-container">
              <label className='create-coupon-input-label mr-3'>Mínimo de compra: </label>
              <input 
                type="number"
                placeholder=""
                max="9999"
                name="minimum_purchase" 
                value={minimum_purchase} 
                onChange={this.handleChange}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-coupon-input-container">
              <label className='create-coupon-input-label mr-3'>Envío gratis: </label>
              <input 
                type="checkbox"
                name="free_shipping"
                checked={free_shipping}
                onChange={this.handleChange}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-coupon-input-container">
              <label className='create-coupon-input-label mr-3'>Validar uso por usuario: </label>
              <input 
                type="checkbox"
                name="validate_use"
                checked={validate_use}
                onChange={this.handleChange}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-coupon-input-container">
              <label className='create-coupon-input-label mr-3'>Válido solo para la primera compra: </label>
              <input 
                type="checkbox"
                name="first_purchase_only"
                checked={first_purchase_only}
                onChange={this.handleChange}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-coupon-input-container">
              <label className='create-coupon-input-label mr-3'>El cupón es privado: </label>
              <input 
                type="checkbox"
                name="is_private"
                checked={is_private}
                onChange={this.handleChange}
              />
              <label className='create-coupon-input-label-description ml-3'>Al poner privado el cupón no aparecera en las promociones.</label>
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-coupon-input-container">
              <label className='create-coupon-input-label mr-3'>Cuantas veces es valido el cupón: </label>
              <input 
                type="number" 
                placeholder="" 
                name="times" 
                value={times} 
                onChange={this.handleChange}
              />
              <label className='create-coupon-input-label-description ml-3'>El número cero hará que el cupón no tenga limite de usos.</label>
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-coupon-input-container">
              <label className='create-coupon-input-label mr-3'>Fecha de vencimiento: </label>
              <input 
                type="date"
                placeholder="" 
                name="valid_until" 
                value={valid_until} 
                onChange={this.handleChange}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-coupon-input-container">
              <label className='create-coupon-input-label mr-3'>Grupos de usuarios al que aplíca: </label>
                <select style={{width: '30%'}} onChange={this.handleChange} name="group">
                  <option value="all" selected={group === 'all' && true}>Todos los usuarios</option>
                  <option value="user" selected={group === 'user' && true}>Usuarios sin compra</option>
                  <option value="customer" selected={group === 'customer' && true}>Usuarios con compra</option>
                  <option value="pending_cart" selected={group === 'pending_cart' && true}>Usuarios con carrito pendiente 30 días</option>
                  <option value="purchase_range_6_11" selected={group === 'purchase_range_6_11' && true}>Usuarios con compra antigua 6M - 11M</option>
                  <option value="purchase_range_12_inf" selected={group === 'purchase_range_12_inf' && true}>Usuarios con compra antigua 12M - Eternidad</option>
                </select>
              <label className='create-coupon-input-label-description ml-3'>Al poner privado el cupón no aparecera en las promociones.</label>
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-coupon-input-container">
              <label className='create-coupon-input-label mr-3'>Agregar el correo del ó los usuarios que aplicará el cupón: </label>
              <textarea 
                name="valid_for" 
                cols="40" 
                rows="10" 
                id="valid_for" 
                onChange={this.handleChange} 
                value={valid_for.toString()}>
              </textarea>
              <label className='create-coupon-input-label-description ml-3'>Seperados por ",". Ejemplo "miguel@pixyalbum.com,enrique@pixyalbum.com,jeber@pixyalbum.com"</label>
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-coupon-input-container"></div>
            <div className="create-coupon-input-container">
              <label className='create-coupon-input-label mr-3'>Productos a los que aplica: </label>
              <br></br>
              <label className='create-coupon-input-label-description ml-3 mr-2'>álbum: </label>
              <input 
                type="checkbox"
                name="album"
                value="album"
                onChange={this.handleProductsChange}
                checked={selectedAlbum}
              />
              <br></br>
              <label className='create-coupon-input-label-description ml-3 mr-2'>imanes: </label>
              <input 
                type="checkbox"
                name="magnet"
                value="magnet"
                onChange={this.handleProductsChange}
                checked={selectedMagnet}
              />
              <br></br>
              <label className='create-coupon-input-label-description ml-3 mr-2'>cuadros: </label>
              <input 
                type="checkbox"
                name="picture"
                value="picture"
                onChange={this.handleProductsChange}
                checked={selectedPicture}
              />
              <br></br>
              <label className='create-coupon-input-label-description ml-3 mr-2'>esferas: </label>
              <input 
                type="checkbox"
                name="ornament"
                value="ornament"
                onChange={this.handleProductsChange}
                checked={selectedOrnament}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <label className='create-coupon-input-label mr-3'>Países a los que aplica: </label>
            <br></br>
            {countries.map((item, i) => (
              <div>
              <label className='create-coupon-input-label-description ml-3 mr-2'>{item.name}: </label>
              <input 
                type="checkbox"
                name="countries"
                value={item.id}
                onChange={this.handleCountriesChange}
                checked={voucher_countries.some(item_voucher_country => item_voucher_country.id === item.id)}
              />
              </div>
            ))}



            <div className="d-flex justify-content-center align-items-center">
                <button id="create_coupon" type="submit" className="create_coupon_button my-3">{button_title}</button>
              </div>
          </form>
        </div>
      </div>
    );
  }

}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

export default connect(mapStateToProps)(CreateCoupon);