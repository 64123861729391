import React from 'react';
import { parseDate } from '../../utils/parsers';
import './SearchCopouns';

export default function CouponSearchResultRow(props) {
  const { 
    code,
    cash,
    percentage,
    minimum_purchase,
    free_shipping,
    first_purchase_only,
    times,
    valid_until
  } = props.item;

  let discount = '';
  if (cash > 0) {
    discount = '$' + cash;
  } else if (percentage > 0) {
    discount = percentage + '%';
  } else {
    discount = 'No aplica'
  }

  let expirationDate = '';

  if (valid_until) {
    expirationDate = parseDate(valid_until)
  } else {
    expirationDate = 'Sin vigencia'
  }

  return (
    <tr>
      <td className="search-orders_table-item cursor-pointer" onClick={() => props.handleSelectedCoupon(props.item)}>{code}</td>
      <td className="search-orders_table-item">{discount}</td>
      <td className="search-orders_table-item">${minimum_purchase}</td>
      <td className="search-orders_table-item">{first_purchase_only ? 'Si' : 'No'}</td>
      <td className="search-orders_table-item">{times === 0 ? 'Sin limite de uso' : times + ' veces'}</td>
      <td className="search-orders_table-item">{free_shipping ? 'Si' : 'No'}</td>
      <td className="search-orders_table-item">{expirationDate}</td>
    </tr>
  )
}