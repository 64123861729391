import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../loading/Loading';
import { getPushNotifications, getNextPagePushNotifications, updatePushNotification, getPushNotificationRecord } from '../../services';
import PushNotificationsSearchResultRow from './PushNotificationsSearchResultRow';
import './SearchPushNotifications.scss';
import Modal from '../modal/Modal';
import PushNotificationRecordView from '../pushNotificationRecordView/PushNotificationRecordView';

import warning_img from '../../images/warning-img.png';

class SearchPushNotifications extends Component {

  state = {
    pushNotifications: [],
    data: null,
    showError: false,
    errorMessage: '',
    isLoading: false,
    showLocalLoading: false,
    pushNotificationRecordItem: null,
    showPushRecord: false,
  }

  componentDidMount() {
    this.getPushNotifications();
  }

  getPushNotifications = async() => {
    const { access } = this.props.userToken;
    this.setState({isLoading: true});

    await getPushNotifications(access)
    .then((response) => {
      this.setState({
        pushNotifications: response.data.results,
        data: response.data,
        isLoading: false
      });
    })
    .catch((error) => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde',
        isLoading: false
      });
    });
  }

  handleLoadNextPushNotifications = async() => {
    const { data, pushNotifications } = this.state;
    const { access } = this.props.userToken;
    this.setState({showLocalLoading: true});
    const nextPageUrl = data.next;

    await getNextPagePushNotifications(nextPageUrl, access)
    .then((response) => {
      this.setState({
        pushNotifications: pushNotifications.concat(response.data.results),
        data: response.data,
        showLocalLoading: false
      });
    })
    .catch((error) => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde',
        showLocalLoading: false
      });
    });
  }

  handleSendPushNotification = async(id) => {
    const { access } = this.props.userToken;
    let data = { "is_scheduled": true }
    this.setState({isLoading: true});

    await updatePushNotification(id, access, data)
    .then((response) => {
      this.getPushNotifications()
    })
    .catch((error) => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde',
        isLoading: false
      });
    });
  }

  handleSelectedPushNotification= (item) => {
    window.open(`/createpushnotification/${item.id}`, '_blank')
  }

  handleGetPushNotificationRecord = async(id) => {
    const { access } = this.props.userToken;
    this.setState({isLoading: true});

    await getPushNotificationRecord(id, access)
    .then((response) => {
      this.setState({
        isLoading: false,
        showPushRecord: true,
        pushNotificationRecordItem: response.data.results[0]
      });
    })
    .catch((error) => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde',
        isLoading: false
      });
    });
  }

  handleOkPushNotificationRecord = () => {
    this.setState({
      showPushRecord: false,
      pushNotificationRecordItem: null
    });
  }

  render() {
    const { pushNotifications, data, isLoading, showLocalLoading, showError, pushNotificationRecordItem, showPushRecord, errorMessage } = this.state;

    return (
      isLoading ? <Loading/> :
      <div className="search-push-notifications_main">
        {
          showPushRecord &&
          <Modal>
            <PushNotificationRecordView
              handleOkButton={this.handleOkPushNotificationRecord}
              item={pushNotificationRecordItem}
            />
          </Modal>
        }
        <div className="search-push-notifications_title d-flex flex-column justify-content-center align-items-center">
          <div className="search-push-notifications_box d-flex justify-content-between align-items-center">
            {showError &&
              <div className="search-push-notifications_input-container-error">
                <div className="search-push-notifications_error mt-1 d-flex align-items-center justify-content-center">
                  <img src={warning_img} height="18" alt=""/>
                  <p className="gotham-md-red-12 ml-3">{errorMessage}</p>
                </div>
              </div>            
            }   
          </div>
        </div>
        {pushNotifications.length > 0 &&
          <div className="pb-5 d-flex flex-column align-items-center">
            <div className="search-push-notifications_count-results pl-2">
              <p className="gotham-md-darkfont-15">Mostrando {pushNotifications.length} de {data.count} resultados</p>
            </div>
            <table className="search-push-notifications_table">
              <thead className="search-orders_table-head">
                <tr>
                  <th className="search-orders_table-head-item">Titulo</th>
                  <th className="search-orders_table-head-item">Texto</th>
                  <th className="search-orders_table-head-item">Tipo</th>
                  <th className="search-orders_table-head-item">Fecha de envio</th>
                  <th className="search-orders_table-head-item">Imágen</th>
                  <th className="search-orders_table-head-item">Dispositivo</th>
                  <th className="search-orders_table-head-item">Programada</th>
                  <th className="search-orders_table-head-item">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {pushNotifications.map((item, i) => (
                  <PushNotificationsSearchResultRow
                    key={i}
                    item={item}
                    handleSelectedPushNotification={this.handleSelectedPushNotification}
                    handleSendPushNotification={this.handleSendPushNotification}
                    handleGetPushNotificationRecord={this.handleGetPushNotificationRecord}
                  />
                ))}
              </tbody>
            </table>
            {(data && data.count > pushNotifications.length) && (
              showLocalLoading ? <Loading isLocalLoading /> :
              <button className="search-push-notifications_see-more my-5" onClick={this.handleLoadNextPushNotifications}>
                Ver más +
              </button>)
            }
          </div>
        }
        
      </div>
    );
  }

}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

export default connect(mapStateToProps)(SearchPushNotifications);      