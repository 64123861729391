import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { removeUserDataAction } from '../../redux/actions';
import './Navigation.scss';

import pixyalbum_logo from '../../images/pixyalbum-logo.png';
import arrow_up from '../../images/navigation/arrow_up.png';
import arrow_down from '../../images/navigation/arrow_down.png';
import covers from '../../images/navigation/covers.png';
import giftcards from '../../images/navigation/giftcards.png';
import phrases from '../../images/navigation/phrases.png';
import profile from '../../images/navigation/profile.png';
import logout from '../../images/logout-img.png';

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routeName: '',
      showDropdown: false,
      mouseOverItem: null
    }
  }
  
  handleMouseOver = (isMouseOver, mouseOverItem) => {
    if (isMouseOver) {
      this.setState({ 
        showDropdown: true,
        mouseOverItem: mouseOverItem
      })
    } else {
      this.setState({ 
        showDropdown: false,
        mouseOverItem: ''
      })
    }
  }

  handleMouseOverItem = (item) => {
    this.setState({ mouseOverItem: item });
  }

  handleLogout = () => {
    this.props.removeUserDataAction();
    window.location.replace('/');
  }

  handleSearchOrders = () => {
    window.location.replace('/search');
  }

  handleSearchShippingGuides = () => {
    window.location.replace('/searchshippingguides');
  }

  handleSearchCoupons = () => {
    window.location.replace('/coupons');
  }

  handleCreateCoupon = () => {
    window.location.replace('/createcoupon')
  }

  handleCoverCategories = () => {
    window.location.replace('/predesign/cover/categories');
  }

  handleMaskCoverCategories = () => {
    window.location.replace('/mask/cover/categories');
  }

  handleQuoteCategories = () => {
    window.location.replace('/quote/categories');
  }

  handleSearchPushNotifications = () => {
    window.location.replace('/pushnotifications');
  }

  handleCreatePushNotifiaction = () => {
    window.location.replace('/createpushnotification')
  }

  handleAppBanners = () => {
    window.location.replace('/banners/app');
  }

  handleLandingBanners = () => {
    window.location.replace('/banners/landing')
  }

  handleSurveys = () => {
    window.location.replace('/surveys')
  }

  render() {
    const { mouseOverItem } = this.state;
    const { userToken, userName, customerName} = this.props;
    return (
      userToken &&
      <React.Fragment>
        <div className="navbar_container">
            <div className="navbar_top d-flex justify-content-between align-items-center">
              <Link to="/search" className="navbar_logo-container me-2 cursor-pointer">
                <img src={pixyalbum_logo} height="49" alt="logo" />
              </Link>
              <div className="icons_container d-flex justify-content-end align-items-center">
              <div
                className="mt-2 pr-4 px-2 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                onMouseEnter={() => this.handleMouseOver(true, 'surveys')}
                onMouseLeave={() => this.handleMouseOver(false)}
              >
                <img className="mr-1" height="8" src={(mouseOverItem === 'manage' || mouseOverItem === 'phrases' || mouseOverItem === 'covers' || mouseOverItem === 'giftcards') ? arrow_up : arrow_down} alt="manage"/>
                {<p className="navbar_icon-subtext">Encuestas</p>}
                {this.state.showDropdown && this.state.mouseOverItem === 'surveys' &&
                  <div className="navbar_dropdown" onMouseEnter={() => this.handleMouseOver(true, 'surveys')} onMouseLeave={() => this.handleMouseOver(false)}>
                    <div className="px-2 d-flex align-items-center navbar_dorpdown-icon cursor-pointer">
                      <img className="mr-1" height="8" src={(mouseOverItem === 'manage' || mouseOverItem === 'phrases' || mouseOverItem === 'covers' || mouseOverItem === 'giftcards') ? arrow_up : arrow_down} alt="manage"/>
                      <p className="mr-3 navbar_icon-subtext">Encuestas </p>
                    </div>
                    <div className="navbar_dropdown-box d-flex flex-column align-items-center">
                      <div 
                        className="py-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                        onMouseEnter={() => this.handleMouseOverItem('surveys')}
                        onMouseLeave={() => this.handleMouseOverItem(null)}
                        onClick={this.handleSurveys}
                      >
                        <img className="px-2" src={''} height="16" alt="" />
                        {mouseOverItem === 'surveys' ? <p className="navbar_icon-subtext-selected">Encuestas</p> : <p className="navbar_icon-subtext">App</p>}
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div
                  className="mt-2 pr-4 px-2 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                  onMouseEnter={() => this.handleMouseOver(true, 'banners')}
                  onMouseLeave={() => this.handleMouseOver(false)}
                >
                  <img className="mr-1" height="8" src={(mouseOverItem === 'manage' || mouseOverItem === 'phrases' || mouseOverItem === 'covers' || mouseOverItem === 'giftcards') ? arrow_up : arrow_down} alt="manage"/>
                  {<p className="navbar_icon-subtext">Banners</p>}
                  {this.state.showDropdown && this.state.mouseOverItem === 'banners' &&
                    <div className="navbar_dropdown" onMouseEnter={() => this.handleMouseOver(true, 'banners')} onMouseLeave={() => this.handleMouseOver(false)}>
                      <div className="px-2 d-flex align-items-center navbar_dorpdown-icon cursor-pointer">
                        <img className="mr-1" height="8" src={(mouseOverItem === 'manage' || mouseOverItem === 'phrases' || mouseOverItem === 'covers' || mouseOverItem === 'giftcards') ? arrow_up : arrow_down} alt="manage"/>
                        <p className="mr-3 navbar_icon-subtext">Banners </p>
                      </div>
                      <div className="navbar_dropdown-box d-flex flex-column align-items-center">
                        <div 
                          className="py-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          onMouseEnter={() => this.handleMouseOverItem('banners')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                          onClick={this.handleAppBanners}
                        >
                          <img className="px-2" src={''} height="16" alt="" />
                          {mouseOverItem === 'banners' ? <p className="navbar_icon-subtext-selected">Apps</p> : <p className="navbar_icon-subtext">App</p>}
                        </div>
                        <div 
                          className="py-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          onMouseEnter={() => this.handleMouseOverItem('banners')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                          onClick={this.handleLandingBanners}
                        >
                          <img className="px-2" src={''} height="16" alt="" />
                          {mouseOverItem === 'banners' ? <p className="navbar_icon-subtext-selected">Landing</p> : <p className="navbar_icon-subtext">Landing</p>}
                        </div>
                      </div>   
                    </div>
                  }
                </div>
                <div
                  className="mt-2 pr-4 px-2 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                  onMouseEnter={() => this.handleMouseOver(true, 'pushnotifications')}
                  onMouseLeave={() => this.handleMouseOver(false)}
                >
                  <img className="mr-1" height="8" src={(mouseOverItem === 'manage' || mouseOverItem === 'phrases' || mouseOverItem === 'covers' || mouseOverItem === 'giftcards') ? arrow_up : arrow_down} alt="manage"/>
                  {<p className="navbar_icon-subtext">Push Notifications</p>}
                  {this.state.showDropdown && this.state.mouseOverItem === 'pushnotifications' &&
                    <div className="navbar_dropdown" onMouseEnter={() => this.handleMouseOver(true, 'pushnotifications')} onMouseLeave={() => this.handleMouseOver(false)}>
                      <div className="px-2 d-flex align-items-center navbar_dorpdown-icon cursor-pointer">
                        <img className="mr-1" height="8" src={(mouseOverItem === 'manage' || mouseOverItem === 'phrases' || mouseOverItem === 'covers' || mouseOverItem === 'giftcards') ? arrow_up : arrow_down} alt="manage"/>
                        <p className="navbar_icon-subtext">Push Notifications</p>
                      </div>
                      <div className="navbar_dropdown-box d-flex flex-column align-items-center">
                        <div 
                          className="py-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          onMouseEnter={() => this.handleMouseOverItem('pushnotifications')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                          onClick={this.handleSearchPushNotifications}
                        >
                          <img className="px-2" src={''} height="16" alt="" />
                          {mouseOverItem === 'pushnotifications' ? <p className="navbar_icon-subtext-selected">Buscar Push Notifiaction</p> : <p className="navbar_icon-subtext">Buscar Push Notification</p>}
                        </div>
                        <div 
                          className="py-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          onMouseEnter={() => this.handleMouseOverItem('pushnotifications')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                          onClick={this.handleCreatePushNotifiaction}
                        >
                          <img className="px-2" src={''} height="16" alt="" />
                          {mouseOverItem === 'pushnotifications' ? <p className="navbar_icon-subtext-selected">Crear Push Notifiaction</p> : <p className="navbar_icon-subtext">Crear Push Notification</p>}
                        </div>
                      </div>   
                    </div>
                  }
                </div>
              <div
                  className="mt-2 pr-4 px-2 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                  onMouseEnter={() => this.handleMouseOver(true, 'quotes')}
                  onMouseLeave={() => this.handleMouseOver(false)}
                >
                  <img className="mr-1" height="8" src={(mouseOverItem === 'manage' || mouseOverItem === 'phrases' || mouseOverItem === 'giftcards' || mouseOverItem === 'covers' ) ? arrow_up : arrow_down} alt="manage"/>
                  {<p className="navbar_icon-subtext">Frases</p>}
                  {this.state.showDropdown && this.state.mouseOverItem === 'quotes' &&
                    <div className="navbar_dropdown" onMouseEnter={() => this.handleMouseOver(true, 'quotes')} onMouseLeave={() => this.handleMouseOver(false)}>
                      <div className="px-2 mr-1 d-flex align-items-center navbar_dorpdown-icon cursor-pointer">
                        <img className="mr-1" height="8" src={(mouseOverItem === 'manage' || mouseOverItem === 'phrases' || mouseOverItem === 'giftcards' || mouseOverItem === 'giftcards') ? arrow_up : arrow_down} alt="manage"/>
                        <p className="navbar_icon-subtext">Frases</p>
                      </div>
                      <div className="navbar_dropdown-box d-flex flex-column align-items-center">
                        <div 
                          className="py-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          onMouseEnter={() => this.handleMouseOverItem('quotes')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                          onClick={this.handleQuoteCategories}
                        >
                          <img className="px-2" src={''} height="16" alt="" />
                          {mouseOverItem === 'quotes' ? <p className="navbar_icon-subtext-selected">Frases</p> : <p className="navbar_icon-subtext">Frases</p>}
                        </div>
                      </div>   
                    </div>
                  }
                </div>
              <div
                  className="mt-2 pr-4 px-2 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                  onMouseEnter={() => this.handleMouseOver(true, 'covers')}
                  onMouseLeave={() => this.handleMouseOver(false)}
                >
                  <img className="mr-1" height="8" src={(mouseOverItem === 'manage' || mouseOverItem === 'phrases' || mouseOverItem === 'giftcards') ? arrow_up : arrow_down} alt="manage"/>
                  {<p className="navbar_icon-subtext">Portadas</p>}
                  {this.state.showDropdown && this.state.mouseOverItem === 'covers' &&
                    <div className="navbar_dropdown" onMouseEnter={() => this.handleMouseOver(true, 'covers')} onMouseLeave={() => this.handleMouseOver(false)}>
                      <div className="px-2 d-flex align-items-center navbar_dorpdown-icon cursor-pointer">
                        <img className="mr-1" height="8" src={(mouseOverItem === 'manage' || mouseOverItem === 'phrases' || mouseOverItem === 'giftcards' || mouseOverItem === 'giftcards') ? arrow_up : arrow_down} alt="manage"/>
                        <p className="navbar_icon-subtext">Portadas</p>
                      </div>
                      <div className="navbar_dropdown-box d-flex flex-column align-items-center">
                        <div 
                          className="py-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          onMouseEnter={() => this.handleMouseOverItem('covers')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                          onClick={this.handleCoverCategories}
                        >
                          <img className="px-2" src={''} height="16" alt="" />
                          {mouseOverItem === 'covers' ? <p className="navbar_icon-subtext-selected">Prediseñados</p> : <p className="navbar_icon-subtext">Prediseñados</p>}
                        </div>
                        <div 
                          className="py-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          onMouseEnter={() => this.handleMouseOverItem('covers')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                          onClick={this.handleMaskCoverCategories}
                        >
                          <img className="px-2" src={''} height="16" alt="" />
                          {mouseOverItem === 'covers' ? <p className="navbar_icon-subtext-selected">Foto con Mascara</p> : <p className="navbar_icon-subtext">Foto con Mascara</p>}
                        </div>
                      </div>   
                    </div>
                  }
                </div>
              <div
                  className="mt-2 pr-4 px-2 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                  onMouseEnter={() => this.handleMouseOver(true, 'coupons')}
                  onMouseLeave={() => this.handleMouseOver(false)}
                >
                  <img className="mr-1" height="8" src={(mouseOverItem === 'manage' || mouseOverItem === 'phrases' || mouseOverItem === 'covers' || mouseOverItem === 'giftcards') ? arrow_up : arrow_down} alt="manage"/>
                  {<p className="navbar_icon-subtext">Cupones</p>}
                  {this.state.showDropdown && this.state.mouseOverItem === 'coupons' &&
                    <div className="navbar_dropdown" onMouseEnter={() => this.handleMouseOver(true, 'coupons')} onMouseLeave={() => this.handleMouseOver(false)}>
                      <div className="px-2 d-flex align-items-center navbar_dorpdown-icon cursor-pointer">
                        <img className="mr-1" height="8" src={(mouseOverItem === 'manage' || mouseOverItem === 'phrases' || mouseOverItem === 'covers' || mouseOverItem === 'giftcards') ? arrow_up : arrow_down} alt="manage"/>
                        <p className="navbar_icon-subtext">Cupones</p>
                      </div>
                      <div className="navbar_dropdown-box d-flex flex-column align-items-center">
                        <div 
                          className="py-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          onMouseEnter={() => this.handleMouseOverItem('coupons')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                          onClick={this.handleSearchCoupons}
                        >
                          <img className="px-2" src={''} height="16" alt="" />
                          {mouseOverItem === 'coupons' ? <p className="navbar_icon-subtext-selected">Buscar Cupon</p> : <p className="navbar_icon-subtext">Buscar Cupon</p>}
                        </div>
                        <div 
                          className="py-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          onMouseEnter={() => this.handleMouseOverItem('coupons')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                          onClick={this.handleCreateCoupon}
                        >
                          <img className="px-2" src={''} height="16" alt="" />
                          {mouseOverItem === 'coupons' ? <p className="navbar_icon-subtext-selected">Crear Cupon</p> : <p className="navbar_icon-subtext">Crear Cupon</p>}
                        </div>
                      </div>   
                    </div>
                  }
                </div>
                <div
                  className="mt-2 pr-4 px-2 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                  onMouseEnter={() => this.handleMouseOver(true, 'orders')}
                  onMouseLeave={() => this.handleMouseOver(false)}
                >
                  <img className="mr-1" height="8" src={(mouseOverItem === 'manage' || mouseOverItem === 'phrases' || mouseOverItem === 'covers' || mouseOverItem === 'giftcards') ? arrow_up : arrow_down} alt="manage"/>
                  {<p className="navbar_icon-subtext">Ordenes</p>}
                  {this.state.showDropdown && this.state.mouseOverItem === 'orders' &&
                    <div className="navbar_dropdown" onMouseEnter={() => this.handleMouseOver(true, 'orders')} onMouseLeave={() => this.handleMouseOver(false)}>
                      <div className="px-2 d-flex align-items-center navbar_dorpdown-icon cursor-pointer">
                        <img className="mr-1" height="8" src={(mouseOverItem === 'manage' || mouseOverItem === 'phrases' || mouseOverItem === 'covers' || mouseOverItem === 'giftcards') ? arrow_up : arrow_down} alt="manage"/>
                        <p className="navbar_icon-subtext">Ordenes</p>
                      </div>
                      <div className="navbar_dropdown-box d-flex flex-column align-items-center">
                        <div 
                          className="py-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          onMouseEnter={() => this.handleMouseOverItem('orders')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                          onClick={this.handleSearchOrders}
                        >
                          <img className="px-2" src={''} height="16" alt="" />
                          {mouseOverItem === 'orders' ? <p className="navbar_icon-subtext-selected">Buscar Orden</p> : <p className="navbar_icon-subtext">Buscar Orden</p>}
                        </div>
                        <div 
                          className="py-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          onMouseEnter={() => this.handleMouseOverItem('orders')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                          onClick={this.handleSearchShippingGuides}
                        >
                          <img className="px-2" src={''} height="16" alt="" />
                          {mouseOverItem === 'orders' ? <p className="navbar_icon-subtext-selected">Buscar Guía de envío</p> : <p className="navbar_icon-subtext">Buscar Guía de envío</p>}
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div
                  className="mt-2 pr-4 px-2 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                  onMouseEnter={() => this.handleMouseOver(true, 'admin')}
                  onMouseLeave={() => this.handleMouseOver(false)}
                >
                  <img className="mr-1" height="8" src={(mouseOverItem === 'manage' || mouseOverItem === 'phrases' || mouseOverItem === 'covers' || mouseOverItem === 'giftcards') ? arrow_up : arrow_down} alt="manage"/>
                  {<p className="navbar_icon-subtext">Administrar</p>}
                  {this.state.showDropdown && this.state.mouseOverItem === 'admin' &&
                    <div className="navbar_dropdown" onMouseEnter={() => this.handleMouseOver(true, 'admin')} onMouseLeave={() => this.handleMouseOver(false)}>
                      <div className="px-2 mr-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer">
                        <img className="mr-1" height="8" src={(mouseOverItem === 'manage' || mouseOverItem === 'phrases' || mouseOverItem === 'covers' || mouseOverItem === 'giftcards') ? arrow_up : arrow_down} alt="manage"/>
                        <p className="navbar_icon-subtext">Administrar</p>
                      </div>
                      <div className="navbar_dropdown-box d-flex flex-column align-items-center">
                        {/* <Link
                          to="/search"
                          className="my-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          style={{ color: routeName === 'phrases' ? '#01baf2' : null, textDecoration: 'none' }}
                          onMouseEnter={() => this.handleMouseOverItem('phrases')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                        >
                          <img className="mx-2" height="11" src={phrases} alt="manage" />
                          {(routeName === 'phrases' || mouseOverItem === 'phrases') ? <p className="navbar_icon-subtext-selected">Frases</p> : <p className="navbar_icon-subtext">Frases</p>}
                        </Link>
                        <div className="border" />
                        <Link
                          to="/search"
                          className="py-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          style={{ color: routeName === 'covers' ? '#01baf2' : null, textDecoration: 'none' }}
                          onMouseEnter={() => this.handleMouseOverItem('covers')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                        >
                          <img className="mx-2" height="16" src={covers} alt="" />
                          {(routeName === 'covers' || mouseOverItem === 'covers') ? <p className="navbar_icon-subtext-selected">Portadas</p> : <p className="navbar_icon-subtext">Portadas</p>}
                        </Link>
                        <div className="border" />
                        <Link
                          to="/search"
                          className="py-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          style={{ color: routeName === 'giftcards' ? '#01baf2' : null, textDecoration: 'none' }}
                          onMouseEnter={() => this.handleMouseOverItem('giftcards')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                        >
                          <img className="px-2" height="16" src={giftcards} alt="" />
                          {(routeName === 'giftcards' || mouseOverItem === 'giftcards') ? <p className="navbar_icon-subtext-selected">Tarjetas de regalo</p> : <p className="navbar_icon-subtext">Tarjetas de regalo</p>}
                        </Link>
                        <div className="border" /> */}
                        <div 
                          className="py-3 d-flex align-items-center navbar_dorpdown-icon cursor-pointer"
                          onMouseEnter={() => this.handleMouseOverItem('admin')}
                          onMouseLeave={() => this.handleMouseOverItem(null)}
                          onClick={this.handleLogout}
                        >
                          <img className="px-2" src={logout} height="16" alt="" />
                          {mouseOverItem === 'logout' ? <p className="navbar_icon-subtext-selected">Cerrar sesión</p> : <p className="navbar_icon-subtext">Cerrar sesión</p>}
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <img className="me-2" src={profile} height="29" alt="profile" />
                {<p className="mt-2 px-2 navbar_icon-subtext">{(customerName === '') ? customerName : userName}</p>}
              </div>
            </div>
          </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userToken: state.userToken,
  userName: state.userName
});

const mapDispatchToProps = {
  removeUserDataAction: removeUserDataAction
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);

// export default Navigation;