import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCommentsByOrderId, postComment } from '../../services';
import CommentItem from './CommentItem';
import './Comments.scss';

class Comments extends Component {

  state = {
    comments: [],
    newComment: ''
  }

  componentDidMount() {
    this.getOrderComments();
  }

  getOrderComments = async() => {
    const { userToken, orderId } = this.props;
    const response = await getCommentsByOrderId(userToken.access, orderId);
    if (response.status === 200) {
      this.setState({
        comments: response.data.results
      });
    }
    
  }

  handleTextInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  submitComment = async(e) => {
    e.preventDefault();
    const { userToken, orderId } = this.props;
    const { newComment, comments } = this.state;
    try {
      const data = {
        "order": orderId,
        "text": newComment
      }
      const response = await postComment(userToken.access, data);
      if (response.status === 201) {
        const updatedComments = [response.data].concat(comments);
        this.setState({
          newComment: '',
          comments: updatedComments
        });
      }
    } catch(e) {
      // console.log('error', e);
    }
  }

  render() {
    const { comments, newComment } = this.state;
    return (
      <div className="comments_main d-flex flex-column justify-content-start">
        {comments.length > 0 &&
          <div className="comments_top">
            {comments.map((comment, i) => (
              <CommentItem
                key={i}
                comment={comment}
              />
            ))}
          </div>
        }
        <form onSubmit={this.submitComment} className="comments_bottom d-flex flex-column justify-content-start align-items-center">
          <div className="comments_input mt-2">
            <textarea onChange={this.handleTextInput} maxLength="140" type="text" placeholder="Agregar comentario" name="newComment" value={newComment}/>
          </div>
          <div className="comments_submit py-2 d-flex justify-content-end">
            <button type="submit" className="generic-button">Enviar</button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

export default connect(mapStateToProps)(Comments);
