import React from 'react';
import Loading from '../loading/Loading'
import shipping from '../../images/shipping-img.png';
import './ShippingGuide.scss';

export default function ShippingGuide(props) {
  const { orderDetail, selectGuideCompany, guideCompany, showLoacalLoading } = props;
  return (
    showLoacalLoading ? <Loading isLocalLoading /> :
    <div className="shipping-guide_main d-flex flex-column align-items-center">
      <div className="order-detail_section-title mb-3 mt-5 d-flex justify-content-start align-items-center" >
        <img src={shipping} alt="" height="28" />
        <p className="gotham_bold-24 ml-4" height="32">Información de guía</p>
      </div>
      <div className="order-detail_section-detail py-4 px-5 d-flex">
      {Object.entries(orderDetail.shipping_guide).length > 0 && 
        <div className="w-50 d-flex flex-column">
          <p className="gotham_bold-16">Fecha de creación:</p>
          <p className="gotham_book-16">{orderDetail.shipping_guide.created_at.slice(0,10)}</p>
          <p className="gotham_bold-16">Número de guía:</p>
          <p className="gotham_book-16 cursor-pointer" onClick={() => props.handleTrackShipping(orderDetail.shipping_guide.company ,orderDetail.shipping_guide.tracking_number)}>{orderDetail.shipping_guide.tracking_number}</p>
          <p className="gotham_bold-16">Paquetería:</p>
          <p className="gotham_book-16">{orderDetail.shipping_guide.company}</p>
        </div>
      }
        <div className="w-50 d-flex flex-column">
          <p className="gotham_bold-16">Nombre de quien recibe:</p>
          <p className="gotham_book-16">{orderDetail.shipping_address.receiver_name}</p>
          <p className="gotham_bold-16">Teléfono:</p>
          <p className="gotham_book-16">{orderDetail.shipping_address.receiver_phone}</p>
          <p className="gotham_bold-16">Calle:</p>
          <p className="gotham_book-16">{orderDetail.shipping_address.street}</p>
          <p className="gotham_bold-16">No. exterior/No. interior:</p>
          <p className="gotham_book-16 cursor-pointer">{orderDetail.shipping_address.number}/{orderDetail.shipping_address.number_int}</p>
          <p className="gotham_bold-16">Colonia:</p>
          <p className="gotham_book-16">{orderDetail.shipping_address.neighborhood}</p>
          <p className="gotham_bold-16">Ciudad/Delegación:</p>
          <p className="gotham_book-16">{orderDetail.shipping_address.city}</p>
          <p className="gotham_bold-16">Estado:</p>
          <p className="gotham_book-16">{orderDetail.shipping_address.state}, {orderDetail.shipping_address.country.code}</p>
          <p className="gotham_bold-16">Código postal:</p>
          <p className="gotham_book-16">{orderDetail.shipping_address.zip_code}</p>
          <p className="gotham_bold-16">Referencias:</p>
          <p className="gotham_book-16">{orderDetail.shipping_address.references}</p>
        </div>
      </div>
      {Object.entries(orderDetail.shipping_guide).length === 0 && orderDetail.shipping_method !== "international" &&
        <div className="order-detail_buttons">
          <div>
            <p>Seleccionar generación de guía de manera automática o manual</p>
            <select style={{width: '100%'}} onChange={props.handleSelectGuideCompanyOrNot}>
              <option value="automatic" defaultValue>Automática</option>
              <option value="manual">Manual</option>
            </select>
          </div>
          
          {selectGuideCompany === 'manual' &&
            <div>
              <p>Seleccione la compañía de envío</p>
              <select style={{width: '100%'}} onChange={props.handleSelectGuideCompany}>
                <option value="estafeta" defaultValue>Estafeta</option>
                <option value="jtexpress">J&amp;T Express</option>
                <option value="metropolitan">Metropólitan</option>
              </select>
            </div>
          }
          {(selectGuideCompany === 'manual' && guideCompany === 'estafeta') && 
            <div>
              <p>Seleccione el tipo de guía de estafeta</p>
              <select style={{width: '100%'}} onChange={props.handleSelectGuideType}>
                <option value="70" defaultValue>Terrestre</option>
                <option value="D0">Dos días</option>
                <option value="60">Día siguiente</option>
              </select>
            </div>
          }
        </div>
      }
      <div className="order-detail_buttons py-5 d-flex flex-wrap justify-content-between">
        <button className="order-detail_btn" onClick={props.openAlertViewAddAddress}>Modificar dirección</button>
        {orderDetail.shipping_method !== "international" && (Object.entries(orderDetail.shipping_guide).length > 0 ? 
          <button className="order-detail_btn" onClick={() => props.handleTrackShipping(orderDetail.shipping_guide.company ,orderDetail.shipping_guide.tracking_number)}>
            Rastrear envío
          </button> :
          <button className="order-detail_btn" onClick={props.handleCreateShippingGuide}>
            Generar guía
          </button>)
        }
        {Object.entries(orderDetail.shipping_guide).length > 0 && 
          <button className="order-detail_btn" onClick={props.handleDeleteShippingGuide}>Liberar guía</button>
        }
      </div>
      {Object.entries(orderDetail.shipping_guide).length > 0 &&
        <div style={{width: '100%'}} className="d-flex flex-column align-items-center">
          {orderDetail.shipping_guide.company === 'metropolitan' && 
            <button className="mb-4 order-detail_btn" onClick={() => props.handleDownloadGuide(orderDetail.shipping_guide.image_url, orderDetail.id)}>Descargar guía</button>
          }
          <div className="order-detail_buttons d-flex justify-content-center">
            <iframe src={orderDetail.shipping_guide.image_url} width="100%" height="800" alt=""></iframe>
          </div>
        </div>  
      }
    </div>
  );
}