import React, { Component } from 'react';
import { getPushNotificationDetail, createPushNotification, updatePushNotification } from '../../services';
import { connect } from 'react-redux';
import './CreatePushNotification.scss';
import Loading from '../loading/Loading';
import Modal from '../modal/Modal';
import AlertView from '../alertView/AlertView';

class CreatePushNotification extends Component {

  state = {
    title: '',
    text: '',
    push_type: 'all',
    push_action: 'home',
    image_link: '',
    device: 'all',
    is_gif: false,
    is_scheduled: false,
    send_date: '',
    selected_file: null,
    push_data: null,
    isLoading: false,
    showError: false,
    errorMessage: ''
  }

  componentDidMount() {
    const { id } = this.props.match.params
    if (id) {
      this.getPushNotificationDetail(id)
    }
  }

  getPushNotificationDetail = async(id) => {
    this.setState({isLoading: true})
    const { userToken } = this.props;
    await getPushNotificationDetail(id, userToken.access)
    .then((response) => {
      if (response.data.is_sent) {
        window.location.replace('/pushnotifications');
      }
      const { title, text, push_type, push_action, image_link, device, is_gif, is_scheduled, send_date } = response.data
      this.setState({
        title: title,
        text: text,
        push_type: push_type,
        push_action: push_action,
        image_link: image_link,
        device: device,
        is_gif: is_gif,
        is_scheduled: is_scheduled,
        send_date: send_date,
        push_data: response.data
      })
      this.setState({isLoading: false})
    })
    .catch((error) => {
      window.location.replace('/pushnotifications');
    });
  }

  handleChange = e => {
    this.setState({
      [e.target.name] : (e.target.type === "checkbox" ? e.target.checked : e.target.value)
    });
  }

  handleSelectorChange = e => {
    this.setState({
        [e.target.name] : e.target.value
    });
  }

  handleChangeFile = e => {
    this.setState({
        [e.target.name] : e.target.files[0]
    });
  }

  handleSubmit = async(e) => {
    e.preventDefault();
    this.setState({isLoading: true})
    const { userToken } = this.props;
    const { title, text, push_type, push_action, selected_file, device, is_gif, send_date, push_data } = this.state;

    if (push_data) {
      const push_data_dict = new FormData();

      if (push_data.title !== title) {
        push_data_dict.append('title', title);
      }
      if (push_data.text !== text) {
        push_data_dict.append('text', text);
      }
      if (push_data.push_type !== push_type) {
        push_data_dict.append('push_type', push_type);
      }
      if (push_data.push_action !== push_action) {
        push_data_dict.append('push_action', push_action);
      }
      if (push_data.device !== device) {
        push_data_dict.append('image', device);
      }
      if ( push_data.is_gif !== is_gif) {
        push_data_dict.append('is_gif', is_gif);
      }
      if (push_data.send_date !== send_date) {
        if (send_date.length === 0) {
          push_data_dict.append('send_date', null);
        } else {
          push_data_dict.append('send_date', send_date);
        }
      }
      if (selected_file) {
        push_data_dict.append('image', selected_file);
      }

      if (push_data_dict.entries().next().value) {
        await updatePushNotification(push_data.id, userToken.access, push_data_dict)
        .then((response) => {
          window.location.replace('/pushnotifications');
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            showError: true,
            errorMessage: error.response.data.message
          });
        });
      } else {
        window.location.replace('/pushnotifications');
      }

    } else {
      const push_form_data = new FormData();
      push_form_data.append('title', title);
      push_form_data.append('text', text);
      push_form_data.append('push_type', push_type);
      push_form_data.append('push_action', push_action);
      push_form_data.append('device', device);
      push_form_data.append('is_gif', is_gif);

      if (selected_file) {
        push_form_data.append('image', selected_file);
      }

      if (send_date.length === 0) {
        push_form_data.append('send_date', null);
      } else {
        push_form_data.append('send_date', send_date);
      }

      await createPushNotification(userToken.access, push_form_data)
      .then((response) => {
        window.location.replace('/pushnotifications');
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          showError: true,
          errorMessage: error.response.data.message
        });
      });
    }
  }

  handleOkError = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  parseDatetime(send_date){
    if(!send_date){
      return "";
    }
    const date_time = send_date.split(' ')
    const m_d_y = date_time[0].split('-')
    return m_d_y[2]+"-"+m_d_y[0]+"-"+m_d_y[1]+" "+date_time[1]
  }

  render() {
    const { title, text, push_type, push_action, selected_file, device, is_gif, is_scheduled, send_date, push_data, image_link, isLoading, showError, errorMessage } = this.state;
    let page_title = "Crear push notification";
    let button_title = "Crear push notification";

    if (push_data) {
      page_title = "Detalle de la push notification";
      button_title = "Guardar push notification";
    }

    return (
      isLoading ? <Loading/> :
      <div className="create-push-notifications_main mt-4 ml-5 mr-5 mb-5">
        {showError && 
          <Modal>
            <AlertView
              handleOkButton={this.handleOkError}
              message={errorMessage}
            />
          </Modal>
        }
        <div className="create-push-notifications_inside">
          <div class="d-flex justify-content-center mb-3">
            <h1>{page_title}</h1>
          </div>
          <form className="create-push-notifications-form" onSubmit={this.handleSubmit}>
            <div className="create-push-notifications-input-container">
              <label className='create-push-notifications-input-label mr-3'>Título de la notificación: </label>
              <input
                type="text"
                placeholder=""
                name="title"
                value={title}
                onChange={this.handleChange}
                required
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-push-notifications-input-container">
              <label className='create-push-notifications-input-label mr-3'>Texto de la notificación: </label>
              <textarea name="text" cols="40" rows="10" id="id_text" onChange={this.handleChange} value={text} required></textarea>
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-push-notifications-input-container">
              <label className='create-push-notifications-input-label mr-3'>Tipo de notificación: </label>
              <select name='push_type' onChange={this.handleSelectorChange}>
                <option value="all" selected={ push_type === "all" && "selected"}>Todos</option>
                <option value="oxxo" selected={ push_type === "oxxo" && "selected"}>Pagar Oxxo</option>
                <option value="cart" selected={ push_type === "cart" && "selected"}>Productos en Carrito</option>
                <option value="buy" selected={ push_type === "buy" && "selected"}>Sin comprar</option>
                <option value="customer" selected={ push_type === "customer" && "selected"}>Usuarios con compra</option>
              </select>
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-push-notifications-input-container">
              <label className='create-push-notifications-input-label mr-3'>Acción de la notificación: </label>
              <select name='push_action' onChange={this.handleSelectorChange}>
                <option value="home" selected={ push_action === "home" && "selected"}>Inicio</option>
                <option value="create_project" selected={ push_action === "create_project" && "selected"}>Creación de proyecto</option>
                <option value="coupons" selected={ push_action === "coupons" && "selected"}>Mis cupónes</option>
                <option value="cart" selected={ push_action === "cart" && "selected"}>Carrito de compra</option>
                <option value="pixypesos" selected={ push_action === "pixypesos" && "selected"}>Pixypesos</option>
                <option value="orders" selected={ push_action === "orders" && "selected"}>Mis ordenes</option>
                <option value="photobook" selected={ push_action === "photobook" && "selected"}>Creación de fotolibro</option>
                <option value="magnets" selected={ push_action === "magnets" && "selected"}>Creación de imanes</option>
                <option value="pictures" selected={ push_action === "pictures" && "selected"}>Creación de cuadros</option>
                <option value="ornaments" selected={ push_action === "ornaments" && "selected"}>Creación de esferas</option>
              </select>
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-push-notifications-input-container">
              <label className='create-push-notifications-input-label mr-3'>Dispositivo a enviar: </label>
              <select name='device' onChange={this.handleSelectorChange}>
                <option value="all" selected={ device === "all" && "selected"}>Todos</option>
                <option value="ios" selected={ device === "ios" && "selected"}>iOS</option>
                <option value="android" selected={ device === "android" && "selected"}>Android</option>
              </select>
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="create-push-notifications-input-container">
              <label className='create-push-notifications-input-label mr-3'>Fecha de envio: </label>
              {
                is_scheduled ? 
                <label className='create-push-notifications-input-label mr-3'>{send_date}</label>
                :
                <input 
                  type="datetime-local"
                  name="send_date"
                  defaultValue={this.parseDatetime(send_date)}
                  onChange={this.handleChange}
                  required
                />
              }
            </div>
            <hr className="cart_info-inline"></hr>
            
            <div className="create-cover-input-container">
              <label className='create-cover-input-label mr-3'>Seleccionar la imagen para la notificación: </label>
              <input 
                type="file"
                name="selected_file"
                onChange={this.handleChangeFile}
                accept="image/png, image/jpeg, image/jpeg"
              />
            </div>
              { selected_file &&
                <img src={URL.createObjectURL(selected_file)} width="400px" height="200px" alt="Image"/>
              }
              { !selected_file && push_data &&
                <img src={image_link} width="400px" height="200px" alt="Image"/>
              }
            <hr className="cart_info-inline"></hr>
            <div className="create-push-notifications-input-container">
              <label className='create-push-notifications-input-label mr-3'>¿La imagen es un GIF?: </label>
              <input 
                type="checkbox"
                name="is_gif"
                checked={is_gif}
                onChange={this.handleChange}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="d-flex justify-content-center align-items-center">
                <button id="create_push-notifications" type="submit" className="create_push-notifications_button my-3">{button_title}</button>
              </div>
          </form>
        </div>
      </div>
    );
  }

}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

export default connect(mapStateToProps)(CreatePushNotification);