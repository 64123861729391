import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  getOrderDetail, 
  patchOrderDetailStatus, 
  postOrderError,  
  reprintSingleProduct,
  createShippingGuide, 
  deleteShippingGuide,
  giftcardResend,
  getMagnetPreview,
  getOrnamentPreview,
  getAlbumDetail,
  getCoverDetail
} from '../../services';
import { parseStatus, parseDate, parseOrderDate } from '../../utils/parsers';
import { updateUserTokenAction } from '../../redux/actions';
import { saveAs } from 'file-saver'; 
import ProductCard from '../productCard';
import OrderStatus from '../orderStatus/OrderStatus';
import Comments from '../comments/Comments';
import EditAddress from '../editAddress/EditAddress';
import Modal from '../modal/Modal';
import Loading from '../loading/Loading';
import AlertView from '../alertView/AlertView';
import ShippingGuide from '../shippingGuide/ShippingGuide';
import './OrderDetail.scss';

import back from '../../images/back.png';
import order from '../../images/order.png';
import profile from '../../images/profile.png';
import info from '../../images/info.png';
import card from '../../images/card.png';
import cart from '../../images/cart.png';
import details from '../../images/details.png';


import logo from '../../images/pixyalbum-logo.png';

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetail: null,
      products: null,
      isLoading: true,
      showLoacalLoading: false,
      showAlertAddAddress: false,
      guideCompany: 'estafeta',
      guideType: '70',
      selectGuideCompany: 'automatic',
      showError: false,
      errorMessage: ''
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getOrderData(id);
  }

  openAlertViewAddAddress = () => {
    this.setState({
      showAlertAddAddress: true
    });
  }

  hideAlertViewAddAddress = (reload) => {
    if (reload) {
      const { id } = this.props.match.params;
      this.getOrderData(id);
    }
    this.setState({
      showAlertAddAddress: false
    });
  }

  getOrderData = async(id) => {
    const { userToken } = this.props;
    try {
      const response = await getOrderDetail(id, userToken.access);
      if (response.status === 200) {
        this.setState({
          orderDetail: response.data,
          products: response.data.products,
          isLoading: false
        });
      }
    } catch(e) {
      this.setState({
        showError: true,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.'
      })
    }

  }

  goToTemplates = (productType, id, configuration) => {
    const { products } = this.state;
    switch(productType) {
      case 'magnet':
        this.setState({isLoading: true});
        this.showMagnetPreview(id);
        return;
      case 'picture':
        this.props.history.push(`/picturedetail/${configuration}/${id}/`)
        return;
      case 'ornament':
        this.setState({isLoading: true});
        this.showOrnamentPreview(id);
        return;
      case 'cover':
        this.setState({isLoading: true});
        this.showAlbumDetailCover(id);
        return;
      default:
        this.setState({isLoading: true});
        this.showAlbumDetail(id);
        return;
    }
  }

  showMagnetPreview = async(id) => {
    const { userToken } = this.props;
    try {
      const response = await getMagnetPreview(id, userToken.access);
      if (response.status === 200) {
        let responseHtml = response.data;
        var newWindow = window.open('', '_blank');
        newWindow.document.write(responseHtml);
      }
      this.setState({isLoading: false});
    } catch(err) {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ha ocurrido un error, por favor intenta más tarde.'
      })
    }
  }

  showOrnamentPreview = async(id) => {
    const { userToken } = this.props;
    try {
      const response = await getOrnamentPreview(id, userToken.access);
      if (response.status === 200) {
        let responseHtml = response.data;
        var newWindow = window.open('', '_blank');
        newWindow.document.write(responseHtml);
      }
      this.setState({isLoading: false});
    } catch(err) {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ha ocurrido un error, por favor intenta más tarde.'
      })
    }
  }

  showAlbumDetail = async(id) => {
    const { userToken } = this.props;
    try {
      const response = await getAlbumDetail(id, userToken.access);
      if (response.status === 200) {
        let responseHtml = response.data;
        var newWindow = window.open('', '_blank');
        newWindow.document.write(responseHtml);
      }
      this.setState({isLoading: false});
    } catch(err) {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ha ocurrido un error, por favor intenta más tarde.'
      })
    }
  }

  showAlbumDetailCover = async(id) => {
    const { userToken } = this.props;
    try {
      const response = await getCoverDetail(id, userToken.access);
      if (response.status === 200) {
        let responseHtml = response.data;
        var newWindow = window.open('', '_blank');
        newWindow.document.write(responseHtml);
      }
      this.setState({isLoading: false});
    } catch(err) {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ha ocurrido un error, por favor intenta más tarde.'
      })
    }
  }

  handleUpdateStatus = async(id) => {
    const { userToken } = this.props;
    const { orderDetail } = this.state;
    this.setState({isLoading: true});
    let data = {};
    if (id === 0) {
      data = {"status": "cancelled"};
    } else if (id === 1) {
      data = {"status": "printed"}
    } else if (id === 2) {
      data = {"status": "shipped"}
    } else if (id === 3) {
      data = {"status": "reprinted"}
    } else if (id === 4) {
      data = {"is_priority": !orderDetail.is_priority}
    } else if (id === 5) {
      this.handleOrderError(orderDetail.id, userToken.access);
      return;
    } else if (id === 6) {
      data = {"status": "requeued"}
    }
    try {
      const response = await patchOrderDetailStatus(orderDetail.id, userToken.access, data);
      if (response.status === 200) {
        this.setState({
          orderDetail: response.data,
          isLoading: false
        });
      }
      console.log('res-update', response)
    } catch(e) {
      this.setState({
        showError: true,
        isLoading: false,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.'
      })
    }
  }

  handleOrderError = async(orderId, token) => {
    try {
      const response = await postOrderError(orderId, token, {});
      if (response.status === 200) {
        this.setState({isLoading: false})
      }
    } catch(e) {
      this.setState({
        showError: true,
        isLoading: false,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.'
      })
    }
  }

  handleTrackShipping = (shippingCompany, tracking_number) => {
    const lowerCaseShipping = shippingCompany.toLowerCase();
    if (lowerCaseShipping === 'fedex') {
      window.open(`https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${tracking_number}&cntry_code=mx`);
    } else if (lowerCaseShipping === 'estafeta') {
      window.open(`https://rastreositecorecms.azurewebsites.net/Tracking/searchByGet/?wayBillType=1&wayBill=${tracking_number}`)
    } else if (lowerCaseShipping === 'jtexpress') {
      window.open(`https://www.jtexpress.mx/trajectoryQuery?waybillNo=${tracking_number}`)
    } else if (lowerCaseShipping === 'metropolitan') {
      window.open(`http://ape.mcssolution.com.mx/ecommerce/consulta/guia`)
    } else {
      return;
    }
  }

  duplicatePictureProjectForEachImage = (pictures) => {
    // TODO: Reestructurar para no usar for loops anidados
    let finalPictures = [];
    for (let pictureProject of pictures) {
      for (let image of pictureProject.photos.urls) {
        let picture = {
          ...pictureProject,
          photo: image
        }
        finalPictures.push(picture);
      }
    }
    return finalPictures;
  }

  totalAlbumsInOrder = (albums) => {
    let totalAlbumsInOrder = 0;
    for (let albumProject of albums) {
      totalAlbumsInOrder += albumProject.quantity;
    }
    return totalAlbumsInOrder;
  }

  totalMagnetsInOrder = (magnets) => {
    let totalMagnetsInOrder = 0;
    for (let magnetProject of magnets) {
      totalMagnetsInOrder += magnetProject.quantity;
    }
    return totalMagnetsInOrder;
  }

  totalPicturesInOrder = (pictures) => {
    let totalPicturesInOrder = 0;
    for (let pictureProject of pictures) {
      totalPicturesInOrder += (pictureProject.photos.urls.length * pictureProject.quantity);
    }
    return totalPicturesInOrder;
  }

  totalOrnamentsInOrder = (ornaments) => {
    let totalOrnamentsInOrder = 0;
    for (let ornamentProject of ornaments) {
      totalOrnamentsInOrder += ornamentProject.quantity;
    }
    return totalOrnamentsInOrder;
  }

  totalItemsInOrder = (items) => {
    let totalItemsInOrder = 0;
    for (let itemProject of items) {
      totalItemsInOrder += itemProject.quantity;
    }
    return totalItemsInOrder;
  }

  handleSelectUser = (id) => {
    this.props.history.push({
      pathname: '/user/detail',
      id: id
    });
  }

  handleReprintSingleProduct = async(id, productType) => {
    const { userToken } = this.props;
    this.setState({isLoading: true});
    try {
      const response = await reprintSingleProduct(id, productType, userToken.access)
      if (response.status === 200) {
        this.setState({isLoading: false});
      }
    } catch(e) {
      this.setState({
        showError: true,
        isLoading: false,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.'
      })
    }
  }

  handleSelectGuideCompany = (e) => {
    this.setState({guideCompany: e.target.value});
  }

  handleSelectGuideType = (e) => {
    this.setState({guideType: e.target.value});
  }

  handleSelectGuideCompanyOrNot = (e) => {
    this.setState({selectGuideCompany: e.target.value});
  }

  handleCreateShippingGuide = async() => {
    const { orderDetail, guideType, guideCompany, selectGuideCompany } = this.state;
    const { userToken } = this.props;
    this.setState({showLoacalLoading: true});

    let data = {};
    if (selectGuideCompany === 'manual' && guideCompany === 'metropolitan') {
      data = {"delivery_company": "metropolitan"}
    } else if (selectGuideCompany === 'manual' && guideCompany === 'jtexpress') {
      data = {"delivery_company": "jtexpress"}
    } else if (selectGuideCompany === 'manual' && guideCompany === 'estafeta' && guideType === '70') {
      data = {
        "delivery_company": "estafeta",
        "service_type": "70"
      }
    } else if (selectGuideCompany === 'manual' && guideCompany === 'estafeta' && guideType === 'D0') {
      data = {
        "delivery_company": "estafeta",
        "service_type": "D0"
      }
    } else if (selectGuideCompany === 'manual' && guideCompany === 'estafeta' && guideType === '60') {
      data = {
        "delivery_company": "estafeta",
        "service_type": "60"
      }
    }

    await createShippingGuide(orderDetail.id, userToken.access, data)
    .then((response) => {
      const updatedOrderDetail = {
        ...orderDetail, 
        shipping_guide: response.data
      };
      this.setState({
        orderDetail: updatedOrderDetail,
        showLoacalLoading: false,
        guideCompany: 'estafeta',
        guideType: '70',
        selectGuideCompany: 'automatic'
      });
    })
    .catch((error) => {
      this.setState({
        showError: true,
        showLoacalLoading: false,
        errorMessage: error.response.data.toString()
      })
    });
  }

  handleDeleteShippingGuide = async() => {
    const { orderDetail } = this.state;
    const { userToken } = this.props;
    this.setState({isLoading: true});
    try {
      const response = await deleteShippingGuide(orderDetail.id, userToken.access)
      if (response.status === 204) {
        const updatedOrderDetail = {
          ...orderDetail, 
          shipping_guide: {}
        };
        this.setState({
          orderDetail: updatedOrderDetail,
          isLoading: false
        });
      }
    } catch(e) {
      this.setState({
        showError: true,
        isLoading: false,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.'
      })
    }
  }

  giftcardResend = async(id) => {
    const { userToken } = this.props;
    try {
      const response = await giftcardResend(id, userToken.access);
      if (response.status === 200) {
        this.setState({
          showError: true,
          errorMessage: response.data.detail
        })
      }
    } catch(err) {
      this.setState({
        showError: true,
        isLoading: false,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.'
      })
    }
  }

  handleOkError = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  handleDownloadGuide = (image_url, id) => {
    saveAs(image_url, `guía_metropolitan_${id}`)
  }

  render() {
    const { orderDetail, products, isLoading, showAlertAddAddress, guideType, guideCompany, selectGuideCompany, showError, errorMessage, showLoacalLoading } = this.state;
    let totalAlbums =  products && this.totalAlbumsInOrder(products.albums);
    let totalMagnets =  products && this.totalMagnetsInOrder(products.magnets);
    let totalPictures =  products && this.totalPicturesInOrder(products.pictures);
    let totalOrnaments = products && this.totalOrnamentsInOrder(products.ornaments);
    let totalItems =  products && this.totalItemsInOrder(products.items);
    const totalProducts = products && totalAlbums + products.gift_cards.length + totalMagnets + totalPictures + totalOrnaments + totalItems;
    return (
      isLoading ? <Loading/> :
      <React.Fragment>
        {
          showAlertAddAddress &&
          <Modal>
            <EditAddress 
              handleShowCreateAddress={this.hideAlertViewAddAddress} 
              shippingAddress={orderDetail.shipping_address}
              userToken={this.props.userToken}
            />
          </Modal>  
        }
        {showError && 
          <Modal>
            <AlertView
              handleOkButton={this.handleOkError}
              message={errorMessage}
            />
          </Modal>
        }
        <div className="order-detail_main">
          <div className="order-detail_header d-flex align-items-center">
            <div className="order-detail_back-btn d-flex justify-content-start">
              <img className="cursor-pointer" src={back} alt="" height="35" onClick={() => this.props.history.goBack()} />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-start">
              <div className="d-flex align-items-center">
                <img className="mr-2 mb-2" src={order} alt="" height="25" />
                <p className="gotham_bold-white-24">Orden no. {orderDetail.id}</p>
              </div>
              <div className="order-detail_header-bottom d-flex align-items-center justify-content-start">
                <p className="gotham_book-white-24">{parseStatus(orderDetail.status).toUpperCase()}</p>
                {orderDetail.is_priority && <p className="gotham_bold-white-24 ml-2">| CON ERROR</p>} 
              </div>
            </div>
          </div>

          <div className="order-detail_content d-flex">
            <div className="order-detail_status">
              <OrderStatus
                status={orderDetail.status}
                is_priority={orderDetail.is_priority}
                handleUpdateStatus={this.handleUpdateStatus}
              />
            </div>

            <div className="order-detail_center py-5 d-flex flex-column align-items-center">

              <div className="order-detail_section-title mb-3 d-flex justify-content-start align-items-center">
                <img src={profile} alt="" height="28" />
                <p className="gotham_bold-24 ml-4">Cliente</p>
              </div>

              <div className="order-detail_section-detail py-4 px-5 d-flex flex-column cursor-pointer" onClick={() => this.handleSelectUser(orderDetail.customer.id)}>
                <div className="d-flex align-items-center">
                  <p className="gotham_bold-16">Nombre:</p>
                  <p className="gotham_book-16 ml-2">{orderDetail.customer.name}</p>
                </div>
                <div className="d-flex align-items-center">
                  <p className="gotham_bold-16">Email:</p>
                  <p className="gotham_book-16 ml-2">{orderDetail.customer.email}</p>
                </div>
                <div className="d-flex align-items-center">
                  <p className="gotham_bold-16">Teléfono:</p>
                  <p className="gotham_book-16 ml-2">{orderDetail.customer.phone}</p>
                </div>
              </div>

              <div className="order-detail_section-title mb-3 mt-5 d-flex justify-content-start align-items-center" >
                <img src={info} alt="" height="29" />
                <p className="gotham_bold-24 ml-4" height="32">Información de la orden</p>
              </div>

              <div className="order-detail_section-detail py-4 px-5 d-flex flex-column">
                <div className="d-flex align-items-center">
                  <p className="gotham_bold-16">Estado:</p>
                  <p className="gotham_book-16 ml-2">{parseStatus(orderDetail.status)}</p>
                </div>
                <div className="d-flex align-items-center">
                  <p className="gotham_bold-16">Con error:</p>
                  <p className="gotham_book-16 ml-2">{orderDetail.is_priority ? 'Sí' : 'No'}</p>
                </div>
                <div className="d-flex align-items-center">
                  <p className="gotham_bold-16">Fecha de creación:</p>
                  <p className="gotham_book-16 ml-2">{parseOrderDate(orderDetail.created_at)}</p>
                </div>
                <div className="d-flex align-items-center">
                  <p className="gotham_bold-16">Última modificación:</p>
                  <p className="gotham_book-16 ml-2">{parseDate(orderDetail.updated_at)}</p>
                </div>
                <div className="d-flex align-items-center">
                  <p className="gotham_bold-16">Método de envío:</p>
                  <p className="gotham_book-16 ml-2">{orderDetail.shipping_method}</p>
                </div>
              </div>

              <div className="order-detail_section-title mb-3 mt-5 d-flex justify-content-start align-items-center" >
                <img src={card} alt="" height="23" />
                <p className="gotham_bold-24 ml-4" height="32">Pago de la orden</p>
              </div>

              <div className="order-detail_section-detail py-4 px-5 d-flex flex-column">
                <div className="d-flex align-items-center">
                  <p className="gotham_bold-16">Total pagado:</p>
                  <p className="gotham_book-16 ml-2">{Number(orderDetail.total_paid) + Number(orderDetail.total_shipping)}</p>
                </div>
                <div className="d-flex align-items-center">
                  <p className="gotham_bold-16">ID de pasarela de cobro:</p>
                  <p className="gotham_book-16 ml-2">{orderDetail.payment_reference}</p>
                </div>
                <div className="d-flex align-items-center">
                  <p className="gotham_bold-16">Costo de envío:</p>
                  <p className="gotham_book-16 ml-2">{orderDetail.total_shipping}</p>
                </div>
                <div className="d-flex align-items-center">
                  <p className="gotham_bold-16">Cupón:</p>
                  <p className="gotham_book-16 ml-2">{orderDetail.voucher_code}</p>
                </div>
                <div className="d-flex align-items-center">
                  <p className="gotham_bold-16">Referencia de pago (OXXO):</p>
                  <p className="gotham_book-16 ml-2">{orderDetail.cash_payment_reference}</p>
                </div>
              </div>

              <div className="order-detail_section-title mb-3 mt-5 d-flex justify-content-start align-items-center">
                <img src={cart} alt="" height="29" />
                <p className="gotham_bold-24 ml-4" height="32">Productos</p>
              </div>

              <div className="order-detail_section-detail mb-5 py-4 px-5 d-flex flex-column">
                <div className="d-flex flex-column align-items-start ms-3">
                  <p className="gotham_bold-16">Total: {totalProducts}</p>
                  <ul>
                    {totalAlbums > 0 && 
                      <li className="gotham_book-16">
                        {totalAlbums} {totalAlbums > 1 ? 'fotolibros' : 'fotolibro'} 
                      </li>
                    }
                    {products.gift_cards.length > 0 && 
                      <li className="gotham_book-16">
                        {products.gift_cards.length} {products.gift_cards.length > 1 ? 'tarjetas de regalo' : 'tarjeta de regalo'} 
                      </li>
                    }
                    {totalMagnets > 0 && 
                      <li className="gotham_book-16">
                        {totalMagnets} {totalMagnets > 1 ? 'imanes' : 'imán'} 
                      </li>
                    }
                    {totalPictures > 0 && 
                      <li className="gotham_book-16">
                        {totalPictures} {totalPictures > 1 ? 'pixycuadros' : 'pixycuadro'} 
                      </li>
                    }
                    {totalOrnaments > 0 && 
                      <li className="gotham_book-16">
                        {totalOrnaments} {totalOrnaments > 1 ? 'esferas' : 'esfera'} 
                      </li>
                    }
                    {totalItems > 0 && 
                      <li className="gotham_book-16">
                        {totalItems} {totalItems > 1 ? 'accesorios' : 'accesorio'} 
                      </li>
                    }
                  </ul>
                </div>
              </div>


              <div className="border" />

              <div className="order-detail_section-title mb-3 mt-5 d-flex justify-content-start align-items-center">
                <img src={details} alt="" height="25" />
                <p className="gotham_bold-24 ml-4" height="32">Detalle de productos</p>
              </div>

              <div className="order-detail_products-detail mb-3 d-flex flex-wrap justify-content-between">
                {products.albums.length > 0 && products.albums.map((product, i) => (
                    <ProductCard
                      key={i}
                      product={product}
                      productType={'album'}
                      selectCollectionCallBack={this.selectCollectionCallBack}
                      goToTemplates={this.goToTemplates}
                      handleReprintSingleProduct={this.handleReprintSingleProduct}
                    />
                  ))
                }
                {products.gift_cards.length > 0 && products.gift_cards.map((product, i) => (
                    <ProductCard
                      key={i}
                      product={product}
                      productType={'giftcard'}
                      selectCollectionCallBack={this.selectCollectionCallBack}
                      handleReprintSingleProduct={this.handleReprintSingleProduct}
                      giftcardResend={this.giftcardResend}
                    />
                  ))
                }
                {products.magnets.length > 0 && products.magnets.map((product, i) => (
                    <ProductCard
                      key={i}
                      product={product}
                      productType={'magnet'}
                      selectCollectionCallBack={this.selectCollectionCallBack}
                      goToTemplates={this.goToTemplates}
                      handleReprintSingleProduct={this.handleReprintSingleProduct}
                    />
                  ))
                }
                {products.pictures.length > 0 && this.duplicatePictureProjectForEachImage(products.pictures).map((product, i) => (
                    <ProductCard
                      key={i}
                      product={product}
                      productType={'picture'}
                      selectCollectionCallBack={this.selectCollectionCallBack}
                      goToTemplates={this.goToTemplates}
                      handleReprintSingleProduct={this.handleReprintSingleProduct}
                    />
                  ))
                }
                {products.ornaments.length > 0 && products.ornaments.map((product, i) => (
                    <ProductCard
                      key={i}
                      product={product}
                      productType={'ornament'}
                      selectCollectionCallBack={this.selectCollectionCallBack}
                      goToTemplates={this.goToTemplates}
                      handleReprintSingleProduct={this.handleReprintSingleProduct}
                    />
                  ))
                }
                {products.items.length > 0 && products.items.map((product, i) => (
                  <ProductCard
                    key={i}
                    product={product}
                    productType={'item'}

                  />
                ))}
              </div>
              <div className="mb-3 border" />
              <ShippingGuide 
                orderDetail={orderDetail}
                selectGuideCompany={selectGuideCompany}
                guideCompany={guideCompany}
                showLoacalLoading={showLoacalLoading}
                handleDownloadGuide={this.handleDownloadGuide}
                handleSelectGuideCompanyOrNot={this.handleSelectGuideCompanyOrNot}
                handleTrackShipping={this.handleTrackShipping}
                handleSelectGuideCompany={this.handleSelectGuideCompany}
                handleSelectGuideType={this.handleSelectGuideType}
                openAlertViewAddAddress={this.openAlertViewAddAddress}
                handleCreateShippingGuide={this.handleCreateShippingGuide}
                handleDeleteShippingGuide={this.handleDeleteShippingGuide}
              />
            </div>
            <div className="order-detail_comments">
              <Comments orderId={orderDetail.id}/>
            </div>

          </div>

        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

const mapDispatchToProps = {
  updateUserTokenAction: updateUserTokenAction
}


export default connect(mapStateToProps, mapDispatchToProps)(OrderDetail);

