import React, { Component } from 'react';

import cancel_blue from '../../images/cancelorder-blue.png';
import cancel_white from '../../images/cancelorder-white.png';
import print_blue from '../../images/print-blue.png';
import print_white from '../../images/print-white.png';
import sent_blue from '../../images/sent-blue.png';
import sent_white from '../../images/sent-white.png';
import favorite from '../../images/favorite.png';
import favorite_blue from '../../images/favorite-blue.png';
import error_blue from '../../images/error-blue.png';
import error_white from '../../images/error-white.png';
import './SurveyStadistics.scss';

class SurveyStadistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token_stadistics: this.props.token_stadistics
    }
  }

  render() {
    const { token_stadistics } = this.state;
    console.log(token_stadistics)
    return (
      <div className="order-status_main d-flex flex-column">
        <div className="order-status_top d-flex justify-content-center align-items-center">
          <p className="gotham-md-darkfont-15">Estadísticas:</p>
        </div>
        <div 
          className="d-flex flex-column justify-content-around align-items-center"
          style={{height: '60%'}}
        >
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="gotham-bold-darkgray-25 mt-1">{token_stadistics.complete}%</p>
            <p className="gotham-md-darkfont-15 mt-1">Encuestas Completadas</p>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="gotham-bold-darkgray-25 mt-1">{token_stadistics.declined}%</p>
            <p className="gotham-md-darkfont-15 mt-1">Encuestas Declinadas</p>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="gotham-bold-darkgray-25 mt-1">{token_stadistics.pending}%</p>
            <p className="gotham-md-darkfont-15 mt-1">Encuestas Pendientes</p>
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="gotham-bold-darkgray-25 mt-1">{token_stadistics.created}%</p>
            <p className="gotham-md-darkfont-15 mt-1">Encuestas Creadas</p>
          </div>
        </div>
        
      </div>
    );
  }
}

export default SurveyStadistics;