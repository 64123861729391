import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getFilteredOrdersWithCustomerId, getCustomerDetail, getCustomerAddresses, getNextPageOrders } from '../../services';
import UserAddressCard from '../userAddressCard/UserAddressCard';
import SearcResultRow from '../searchOrders/SearchResultRow';
import Loading from '../loading/Loading';
import Modal from '../modal/Modal';
import AlertView from '../alertView/AlertView';
import './UserDetail.scss';

import back_blue from '../../images/back-blue.png';
import favorite_location from '../../images/favorite-location.png';
import job from '../../images/job.png';
import profile from '../../images/profile.png';
import location from '../../images/location.png';
import logo from '../../images/pixyalbum-logo.png';

class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerDetail: null,
      username: '',
      orders: [],
      data: null,
      customerAddresses: [],
      isLoading: true,
      showError: false,
      errorMessage: ''
    }
  }

  async componentDidMount() {
    if (!this.props.location.id) {
      this.props.history.push('/search')
    } else {
      const { id } = this.props.location;
      await this.getCustomerDetail(id);
      await this.getOrderData(id);
      await this.getCustomerAddresses(id);
      this.setState({isLoading: false})
    }
  }

  getOrderData = async(username) => {
    const { access, refresh } = this.props.userToken;
    try {
      const response = await getFilteredOrdersWithCustomerId(username, access, "");
        if (response.status === 200) {
          this.setState({
            orders: response.data.results,
            data: response.data,
            username: '',
          });
        }
    } catch(e) {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.'
      })
    }
  }

  getCustomerDetail = async(id) => {
    const { userToken } = this.props;
    try {
      const response = await getCustomerDetail(id, userToken.access);
      if (response.status === 200) {
        this.setState({
          customerDetail: response.data
        });
      }
    } catch (e) {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.'
      })
    }
  }

  getCustomerAddresses = async(id) => {
    const { userToken } = this.props;
    try {
      const response = await getCustomerAddresses(id, userToken.access);
      if (response.status === 200) {
        this.setState({
          customerAddresses: response.data.results
        });
      }
    } catch(e) {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.'
      })
    }
  }

  handleSelectOrder = (id) => {
    this.props.history.push(`/order/detail/${id}`)
  }

  handleLoadNextOrders = async() => {
    const { data, orders } = this.state;
    const { access } = this.props.userToken;
    this.setState({isLoading: true})
    const nextPageUrl = data.next;
    try {
      const response = await getNextPageOrders(nextPageUrl, access);
      if (response.status === 200) {
        this.setState({
          orders: orders.concat(response.data.results),
          data: response.data,
          isLoading: false
        });
      }
    } catch(e) {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde',
        isLoading: false
      });
    }
  }
  
  handleSelectUser = () => {
    
  }

  handleOkError = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  render() {
    const { customerDetail, orders, data, customerAddresses, isLoading, showError, errorMessage } = this.state;
    return (
      isLoading ? <Loading/> :
        <React.Fragment>
          {showError && 
            <Modal>
              <AlertView
                handleOkButton={this.handleOkError}
                message={errorMessage}
              />
            </Modal>
          }
          <div className="user-detail_main">
              <div className="d-flex justify-content-start align-items-center px-5 pt-5">
                <img className="cursor-pointer" src={back_blue} alt="" height="31" onClick={() => this.props.history.goBack()}/>
              </div>

              <div >
                <div className="d-flex flex-column">
                  <div className="d-flex order-detail_container justify-content-center mb-5">
                    <div className="d-flex flex-column user-detail_container-inside align-items-start">

                      <div className="d-flex justify-content-start align-items-center" >
                        <img className="mb-2" src={profile} alt="" height="28" />
                        <p className="pt-1 pl-3 mb-2 gotham_bold-24" height="32">Cliente</p>
                      </div>

                      <div className="d-flex user-detail_container justify-content-center align-items-center pt-5 pb-5">
                        <img className="avatar mr-3" src={customerDetail.customer_profile_image} alt="profile"/>
                        <div className="d-flex flex-column align-items-start ms-3">
                          <div className="d-flex justify-content-center">
                          <p className="gotham-bold-darkfont-16">Nombre:</p>
                          <p className="gotham-book-darkfont-16 ms-2">{customerDetail.name}</p>
                        </div>
                        <div className="d-flex justify-content-center">
                          <p className="gotham-bold-darkfont-16">Email:</p>
                          <p className="gotham-book-darkfont-16 ms-2">{customerDetail.email}</p>
                        </div>
                        <div className="d-flex justify-content-center">
                          <p className="gotham-bold-darkfont-16">Teléfono:</p>
                          <p className="gotham-book-darkfont-16 ms-2">{customerDetail.phone}</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                {orders.length > 0 &&
                  <div className="pb-5 d-flex flex-column align-items-center">
                    <table className="search-orders_table">
                      <thead className="search-orders_table-head">
                        <tr>
                          <th className="search-orders_table-head-item">ID orden</th>
                          <th className="search-orders_table-head-item">Nombre del cliente</th>
                          <th className="search-orders_table-head-item">Fecha de pago</th>
                          <th className="search-orders_table-head-item">Estatus</th>
                          <th className="search-orders_table-head-item">Productos</th>
                          <th className="search-orders_table-head-item">Con error</th>
                          <th className="search-orders_table-head-item">ID cobro</th>
                          <th className="search-orders_table-head-item">Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map((item, i) => (
                          <SearcResultRow
                            key={i}
                            item={item}
                            handleSelectOrder={this.handleSelectOrder}
                            handleSelectUser={this.handleSelectUser}
                          />
                        ))}
                      </tbody>
                    </table>
                    {(data && data.count > orders.length) && 
                      <button className="search-orders_see-more my-5" onClick={this.handleLoadNextOrders}>
                        Ver más +
                      </button>
                    }
                  </div>
                }

                <div className="user-detail_container-inside_border d-flex mt-2 mb-5">
                  <div className="user-address_title-gray d-flex justify-content-center align-items-center" >
                    <p className="ps-4 pe-4 pt-4 pb-4 gotham-bold-darkfont-20">Dirección de envío:</p>
                  </div>
                  {customerAddresses.length > 0 ?
                    <div className="collections_container">
                      <div className="collections_customer-address d-flex justify-content-center">
                        <div className="collections_customer-products-inside d-flex flex-wrap">
                          {
                          customerAddresses.map((address, i) => (
                            <UserAddressCard
                              key={i}
                              address={address}
                            />
                          ))
                          }
                        </div>
                      </div>
                    </div> : null}
                  </div>
                </div>

              </div>
          </div>
        </React.Fragment>  
    );
  }
}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

export default connect(mapStateToProps)(UserDetail);