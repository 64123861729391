import React from 'react';
import reprint_blue from '../../images/reprint-blue.png';
import reprint_white from '../../images/reprint-white.png';
import './OrderStatusItem.scss';

export default function OrderStatusItem(props) {
  const { item, handleUpdateStatus } = props;
  const backgorundStyles = {
    backgroundColor: item.isActive ? '#51B7F3' : '#F3F8FF'
  }
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <div className="status-item_img-container d-flex justify-content-center align-items-center cursor-pointer" style={backgorundStyles} onClick={() => handleUpdateStatus(item.id)}>
        {item.id === 3 && <img className="status-item_reprint-sm" src={item.isActive ? reprint_white : reprint_blue} alt=""/>}
        <img className="status-item_img" src={item.isActive ? item.activeIcon : item.inactiveIcon} alt=""/>
      </div>
      <p className="gotham-md-darkfont-15 mt-1">{item.title}</p>
    </div>
  );
}