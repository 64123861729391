import React from 'react';
import { parseStatus, parseProducts, parseDate } from '../../utils/parsers';
import './SearchOrders.scss';

export default function SearchResultRow(props) {
  const { 
    id,
    customer_name,
    created_at,
    status,
    total_albums,
    get_total_pictures,
    get_total_magnets,
    get_total_ornaments,
    gift_cards_count,
    get_total_items,
    is_priority,
    payment_reference,
    total_paid,
    customer_id,
    country_code
  } = props.item;
  const isPriority = is_priority ? 'Sí' : 'No';
  return (
    <tr>
      <td className="search-orders_table-item cursor-pointer" onClick={() => props.handleSelectOrder(id)}>{id}</td>
      <td className="search-orders_table-item cursor-pointer" onClick={() => props.handleSelectUser(customer_id)}>{customer_name}</td>
      <td className="search-orders_table-item">{parseDate(created_at)}</td>
      <td className="search-orders_table-item">{parseStatus(status)}</td>
      <td className="search-orders_table-item">{parseProducts(total_albums, get_total_pictures, get_total_magnets, get_total_ornaments, gift_cards_count, get_total_items)}</td>
      <td className="search-orders_table-item">{isPriority}</td>
      <td className="search-orders_table-item">{country_code}</td>
      <td className="search-orders_table-item">Conekta: {payment_reference}</td>
      <td className="search-orders_table-item">${total_paid}</td>
    </tr>
  )
}