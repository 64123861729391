import React from 'react';
import Moment from 'moment';
import 'moment/locale/es';
import comment_img from '../../images/comment-img.png';
import './CommentItem.scss';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

export default function Comment(props) {
  const { comment } = props;
  return (
    <div className="comment-item_main p-4 d-flex flex-column">
      <div className="d-flex">
        <img src={comment_img} height="20" alt=""/>
        <p className="gotham-bold-blue-18 ml-2">{comment.author_first_name} {comment.author_last_name}</p>
      </div>
      <p className="gotham-book-darkfont-15 pt-2">{Moment(comment.created_date).locale('es').format('lll')}</p>
      <p className="gotham-md-darkfont-15 comment-item_text">{ ReactHtmlParser(comment.text) }</p>
    </div>
  );
}