import React from 'react';
import { parseStatus, parseProducts, parseDate } from '../../utils/parsers';
import './SearchShippingGuide.scss';

export default function ShippingGuideSearchResultRow(props) {
  const {
    order,
    tracking_number,
    company,
    created_at
  } = props.item;
  return (
    <tr>
      <td className="search-orders_table-item cursor-pointer" onClick={() => props.handleSelectOrder(order)}>{order}</td>
      <td className="search-orders_table-item">{tracking_number}</td>
      <td className="search-orders_table-item">{company}</td>
      <td className="search-orders_table-item">{parseDate(created_at)}</td>
    </tr>
  )
}