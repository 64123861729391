import React, { Component } from 'react';

import close_img from '../../images/close_gray.png';
import './EditName.scss';

class EditName extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: this.props.name
    }
  }

  handleInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleChangeName = (newName) => {
    switch (this.props.type) {
      case 'coverCategory':
        this.props.handleChangeStatusCategory(this.props.id, {"name": newName})
        break;
      default:
        break;
    }

  }

  render() {
    const title = this.props.type === 'coverCategory' ? 
    'Edita el nombre de la categoría' :
    'Cambia el nombre de tu proyecto.' 
    return (
      <div className="edit-name_container d-flex justify-content-center align-items-center">
        <div className="edit-name_container-inside py-5 d-flex flex-column align-items-center justify-content-center">
          <img className="edit-name_close cursor-pointer" src={close_img} height="20" alt=""  onClick={this.props.cancelEditName}/>
          <p className="gotham_bold-18 mb-5">{title}</p>
          <div className="edit-name_input-container d-flex">
            <input className="edit-name_container" name="name" maxLength="180" value={this.state.name} onChange={this.handleInput} />
          </div>
          <div className="edit-name_count-characters mb-5 pt-1 d-flex justify-content-end">
            <p className="gotham_bold-14">{this.state.name.length} de 180 caracteres</p>
          </div>
          <div className="edit-name_buttons-container d-flex justify-content-between">
            <button className="edit-name_cancel-button" onClick={this.props.cancelEditName}>Cancelar</button>
            <button className="edit-name_ok-button" onClick={() => this.handleChangeName(this.state.name)}>Cambiar nombre</button>
          </div>
        </div>
      </div>
    );
  }
}


export default EditName;