import React from 'react';
import './SoftCoverAlbumTemplate.scss';

export default function SoftCoverAlbumTemplate({photos, albumData, order_number}) {
  const renderIndicators = (i) => {
    if (i+1 === 33) {
      return (
        <div className="softcover_indicators-container d-flex justify-content-start">
          <div className="softcover_indicator" style={{backgroundColor: 'yellow'}}></div>
        </div>
      )
    } else if (i+1 === 49) {
      return (
        <div className="softcover_indicators-container d-flex justify-content-start">
          <div className="softcover_indicator" style={{backgroundColor: 'red'}}></div>
        </div>
      )
    } else if (i+1 === 17) {
      return (
        <div className="softcover_indicators-container d-flex justify-content-end">
          <div className="softcover_indicator" style={{backgroundColor: 'green'}}></div>
        </div>
      )
    } else {
      return (<div className="softcover_indicators-container"></div>);
    }
  }
  return (
    <div className="album_main-container">
      <style dangerouslySetInnerHTML={{
        __html: `
            @media print {
              @page {
                size: 21.3cm 21.65cm;
                margin: 0;
                padding: 0;
                page-break-after: always;
              }
            }
          `
      }}/>
      <div className="softcover_main">
        {photos.map((photo, i) => (
          <div key={i} 
            className="softcover_page"
            style={{paddingLeft: (i+1) % 2 === 0 ? '3.6cm' : '4.1cm'}}
          >
            {renderIndicators(i)}
            {i === 0 && <span className="softcover_ordernum">{order_number}</span>}
            <div className="softcover_header">
              {(photo.editable && albumData.dates) &&
                <p 
                  className="softcover_date" 
                  style={{textAlign: (i+1) % 2 === 0 ? 'start' : 'end'}}
                >
                  {photo.date}
                </p>
              }
            </div>
            <img style={{width: '18.54cm', height:'18.54cm', objectFit: 'contain'}} src={photo.image_url} alt="" />
            <div className="softcover_footer">
              <p className="softcover_comment">{photo.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
} 
