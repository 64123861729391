import React from 'react';
import logo_gray from '../../images/logo-gray.png';
import './PushNotificationRecordView.scss';


function PushNotificationRecordView(props) {
  console.log(props)
  const {
    sender,
    send_date,
    device_quantity,
    success_count
  } = props.item;

  return (
    <div className="record-puh_main d-flex justify-content-center align-items-center">
      <div className="record-puh_inside d-flex flex-column justify-content-between align-items-center">
        <img className="mt-5" src={logo_gray} alt="" />
        <p className="gotham-md-16">Fecha de envio: {send_date}</p>
        <p className="gotham-md-16">Total de dispositivos: {device_quantity}</p>
        <p className="gotham-md-16">Mensajes enviados exitosamente: {success_count}</p>
        <p className="gotham-md-16">Enviado por: {sender}</p>
        <button className="generic-button mb-5" onClick={props.handleOkButton}>Ok</button>
      </div>
    </div>
  );  
}


export default PushNotificationRecordView;