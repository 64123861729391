import Moment from 'moment';


function parseStatus(status) {
    switch (status) {
        case "created":
            return "Creada";
        case "paid":
            return "Pagada";
        case "cancelled":
            return "Cancelada";
        case "expired":
            return "Cancelada";
        case "shipped":
            return "Enviada";
        case "delivered":
            return "Entregada";
        case "declined":
            return "Declinada";
        case "pending_payment":
            return "Pendiente de pago";
        case "printed":
            return "En imprenta";
        case "reprinted":
            return "Reimpresa";
        case "requeued":
            return "Reencolada";
        default:
            return;
    }
}

function parseProducts(total_albums, get_total_pictures, get_total_magnets, get_total_ornaments, gift_cards_count, get_total_items) {
  return (
    <div style={{width: '100%', height: '100%'}} className="d-flex flex-column justify-content-center">
        <p>{total_albums > 0 && (total_albums > 1 ? total_albums + ' álbumes' : total_albums + ' album')} </p>
        <p>{get_total_pictures > 0 && (get_total_pictures > 1 ? get_total_pictures + ' cuadros' : get_total_pictures +' cuadro')}</p>
        <p>{get_total_magnets > 0 && (get_total_magnets > 1 ? get_total_magnets + ' imanes' : get_total_magnets + ' imán')}</p>
        <p>{get_total_ornaments > 0 && (get_total_ornaments > 1 ? get_total_ornaments + ' esferas' : get_total_ornaments + ' esfera')}</p>
        <p>{get_total_items > 0 && (get_total_items > 1 ? get_total_items + ' acrílicos' : get_total_items + ' acrílico')}</p>
        <p>{gift_cards_count > 0 && (gift_cards_count > 1 ? gift_cards_count + ' tarjetas de regalo' : gift_cards_count + ' tarjeta de regalo')}</p>
    </div>
  );
}

function parseDate(date) {
    return Moment(date).format('DD/MM/YYYY');
}

function parseOrderDate(date) {
    return Moment(date).format('DD/MM/YYYY, h:mm a')
}

export {
    parseStatus,
    parseProducts,
    parseDate,
    parseOrderDate,
}