
const initialState = {
    userToken: JSON.parse(localStorage.getItem('USER_TOKEN')),
    userName: JSON.parse(localStorage.getItem('USER_NAME'))
}

function mainReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPLOAD_USER_TOKEN':
            return {
                ...state,
                userToken: action.payload
            }
        case 'SET_USER_NAME':
            return {
                ...state,
                userName: action.payload
            }
        case 'REMOVE_USER_TOKEN':
            return {
                ...state,
                userToken: action.payload
            }
        case 'REMOVE_USER_NAME':
            return {
                ...state,
                userName: action.payload
            }
        default:
            return state
    }
}


export default mainReducer;