import axios from 'axios';
import store from '../redux/store';
import { updateUserTokenAction, removeUserDataAction } from '../redux/actions';

// const API_URL = "http://localhost:8000/api";
// const API_URL = window._env_.API_URL;
// const API_URL = "https://stageorders.pixyalbum.com/api";
const API_URL = "https://backoffice.pixyalbum.com/api";
// console.log(window._env_API_URL)


const axiosInstance = axios.create();


axios.interceptors.response.use(response => {
        return response
    }, function(error) {
        const originalRequest = error.config;
        const token = store.getState().userToken.access
        if (error.response.status === 401) {
            const refreshToken = store.getState().userToken.refresh;
            return axiosInstance.post(`${API_URL}/token/refresh/`, {"refresh": refreshToken})
                .then(response => {
                    if (response.status === 200) {
                        store.dispatch(updateUserTokenAction(response.data))
                        const accessToken = store.getState().userToken.access
                        originalRequest.headers = {...originalRequest.headers, Authorization: `Bearer ${accessToken}`}
                        return axios(originalRequest);
                    }
                }).catch(e => {
                    if (e.response.status === 401) {
                        store.dispatch(removeUserDataAction())
                        window.location.replace('/')
                    }
                    return Promise.reject(e)
                })
        } else {
            return Promise.reject(error);
        }
    });



const loginUser = (data) => axios.post(`${API_URL}/token/`, data);

const refreshUserToken = (data) => axios.post(`${API_URL}/token/refresh/`, data);

const getFilteredOrders = (searchText, token, searchType) => axios.get(
    `${API_URL}/orders/?${searchType}=${searchText}`, 
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getFilteredOrdersByStatus = (status, token, searchPriorityOrders, searchInternationalOrders) => axios.get(
    searchPriorityOrders ? `${API_URL}/orders/?is_priority=true` : searchInternationalOrders ? `${API_URL}/orders/?shipping_method=international` : `${API_URL}/orders/?status=${status}`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getNextPageOrders = (url, token) => axios.get(
    url,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getOrderDetail = (id, token) => axios.get(
    `${API_URL}/orders/${id}`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getCommentsByOrderId = (token, id) => axios.get(
    `${API_URL}/orders/comments/${id}`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getMagnetDetail = (id, token) => axios.get(
    `${API_URL}/magnets/${id}`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getPictureDetail = (id, token) => axios.get(
    `${API_URL}/pictures/${id}/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getMagnetPreview = (id, token) => axios.get(
    `${API_URL}/magnets/${id}/preview/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getOrnamentPreview = (id, token) => axios.get(
    `${API_URL}/ornaments/${id}/preview/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getAlbumDetail = (id, token) => axios.get(
    `${API_URL}/albums/${id}/preview/album`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getCoverDetail = (id, token) => axios.get(
    `${API_URL}/albums/${id}/preview/cover`,
    {headers: {"Authorization": `Bearer ${token}`}}
)

const patchOrderDetailStatus = (id, token, data) => axios.patch(
    `${API_URL}/orders/${id}/`,
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const postComment = (token, data) => axios.post(
    `${API_URL}/orders/comments/`,
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const updateShippingAddress = (id, token, data) => axios.patch(
    `${API_URL}/shipping-address/${id}/`, 
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const postOrderError = (id, token, data) => axios.post(
    `${API_URL}/orders/error/${id}/`,
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
)

const getFilteredOrdersWithCustomerId = (searchText, token) => axios.get(
    `${API_URL}/orders/?customer__id=${searchText}`, 
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getCustomerDetail = (id, token) => axios.get(
    `${API_URL}/customers/${id}`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getCustomerAddresses = (id, token) => axios.get(
    `${API_URL}/customers/${id}/address/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const reprintSingleProduct = (id, productType, token) => axios.post(
    `${API_URL}/orders/reprint/${productType}/${id}/`,
    {},
    {headers: {"Authorization": `Bearer ${token}`}}
);

//Shipping Guides

const createShippingGuide = (id, token, data) => axios.post(
    `${API_URL}/waybills/${id}/`,
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const deleteShippingGuide = (id, token) => axios.delete(
    `${API_URL}/waybills/${id}/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getFilteredShippingGuides = (searchText, token, searchType) => axios.get(
    `${API_URL}/waybills/?${searchType}=${searchText}`, 
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getNextPageShippingGuide = (url, token) => axios.get(
    url,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const giftcardResend = (id, token) => axios.post(
    `${API_URL}/giftcard/resend/${id}/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getCouponDetail = (id, token) => axios.get(
    `${API_URL}/vouchers/${id}/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const createCoupon = (token, data) => axios.post(
    `${API_URL}/vouchers/`,
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const updateCoupon = (id, token, data) => axios.patch(
    `${API_URL}/vouchers/${id}/`,
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getFilteredCoupons = (searchText, token) => axios.get(
    `${API_URL}/vouchers/?search=${searchText}`, 
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getCoupons = (token) => axios.get(
    `${API_URL}/vouchers`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getNextPageCoupons = (url, token) => axios.get(
    url,
    {headers: {"Authorization": `Bearer ${token}`}}
);

//Predesign Cover

const getPredesignCategories = (token) => axios.get(
    `${API_URL}/predesign/categories/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const createCoverCategory = (token, data) => axios.post(
    `${API_URL}/predesign/categories/`,
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const updateCoverCategory = (id, token, data) => axios.patch(
    `${API_URL}/predesign/categories/${id}/`, 
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getPredesignCoversByCategory = (id, token) => axios.get(
    `${API_URL}/predesign/category/${id}/covers/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const createPredesignCoverByCategory = (categoryId, token, data) => axios.post(
    `${API_URL}/predesign/category/${categoryId}/covers/`,
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const updatePredesignCoverByCategory = (id, categoryId, token, data) => axios.patch(
    `${API_URL}/predesign/category/${categoryId}/covers/${id}/`, 
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

//Mask Cover

const getMaskCategories = (token) => axios.get(
    `${API_URL}/mask/categories/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const createMaskCoverCategory = (token, data) => axios.post(
    `${API_URL}/mask/categories/`,
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const updateMaskCoverCategory = (id, token, data) => axios.patch(
    `${API_URL}/mask/categories/${id}/`, 
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getMaskCoversByCategory = (id, token) => axios.get(
    `${API_URL}/mask/category/${id}/covers/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const createMaskCoverByCategory = (id, token, data) => axios.post(
    `${API_URL}/mask/category/${id}/covers/`,
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const updateMaskCoverByCategory = (id, categoryId, token, data) => axios.patch(
    `${API_URL}/mask/category/${categoryId}/covers/${id}/`, 
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

//Quotes

const getQuoteCategories = (token) => axios.get(
    `${API_URL}/quote/categories/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const createQuoteCategory = (token, data) => axios.post(
    `${API_URL}/quote/categories/`,
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const updateQuoteCategory = (id, token, data) => axios.patch(
    `${API_URL}/quote/categories/${id}/`, 
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getQuoteByCategory = (id, token) => axios.get(
    `${API_URL}/quote/category/${id}/quotes/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const createQuoteByCategory = (id, token, data) => axios.post(
    `${API_URL}/quote/category/${id}/quotes/`,
    data,
    {headers: {"Authorization": `Bearer ${token}`, 'Content-Type': 'multipart/form-data'}}
);

const updateQuoteByCategory = (id, categoryId, token, data) => axios.patch(
    `${API_URL}/quote/category/${categoryId}/quotes/${id}/`, 
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

// PushNotifications

const getPushNotifications = (token) => axios.get(
    `${API_URL}/notifications/push/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getNextPagePushNotifications = (url, token) => axios.get(
    url,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getPushNotificationDetail = (id, token) => axios.get(
    `${API_URL}/notifications/push/${id}/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const createPushNotification = (token, data) => axios.post(
    `${API_URL}/notifications/push/`,
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const updatePushNotification = (id, token, data) => axios.patch(
    `${API_URL}/notifications/push/${id}/`, 
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getPushNotificationRecord = (id, token, data) => axios.get(
    `${API_URL}/notifications/push/${id}/log/`, 
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

//Banners

const getAppBannerList = (token) => axios.get(
    `${API_URL}/configuration/banners/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getLandingBannerList = (token) => axios.get(
    `${API_URL}/configuration/banners/landing/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const createAppBanner = (token, data) => axios.post(
    `${API_URL}/configuration/banners/`,
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const updateAppBanner = (id, token, data) => axios.patch(
    `${API_URL}/configuration/banners/${id}/`, 
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const updateLandingBanner = (id, token, data) => axios.patch(
    `${API_URL}/configuration/banners/landing/${id}/`, 
    data,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getAppBannerDetail = (id, token) => axios.get(
    `${API_URL}/configuration/banners/${id}`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const deleteAppBanner = (id, token) => axios.post(
    `${API_URL}/configuration/banners/${id}`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

//General

const getCountries = (token) => axios.get(
    `${API_URL}/countries/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

// Surveys

const getSurveys = (token) => axios.get(
    `${API_URL}/surveys/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getNextPageSurveys = (url, token) => axios.get(
    url,
    {headers: {"Authorization": `Bearer ${token}`}}
);

const getSurveyDetail = (id, token) => axios.get(
    `${API_URL}/survey/${id}/`,
    {headers: {"Authorization": `Bearer ${token}`}}
);

export {
    loginUser,
    refreshUserToken,
    getFilteredOrders,
    getFilteredOrdersByStatus,
    getNextPageOrders,
    getOrderDetail,
    getCommentsByOrderId,
    getMagnetDetail,
    getPictureDetail,
    getMagnetPreview,
    getOrnamentPreview,
    getAlbumDetail,
    getCoverDetail,
    patchOrderDetailStatus,
    postComment,
    updateShippingAddress,
    postOrderError,
    getFilteredOrdersWithCustomerId,
    getCustomerDetail,
    getCustomerAddresses,
    reprintSingleProduct,
    createShippingGuide,
    deleteShippingGuide,
    getFilteredShippingGuides,
    getNextPageShippingGuide,
    giftcardResend,
    getCouponDetail,
    createCoupon,
    updateCoupon,
    getFilteredCoupons,
    getCoupons,
    getNextPageCoupons,
    getPredesignCategories,
    createCoverCategory,
    updateCoverCategory,
    getPredesignCoversByCategory,
    createPredesignCoverByCategory,
    updatePredesignCoverByCategory,
    getMaskCategories,
    createMaskCoverCategory,
    updateMaskCoverCategory,
    getMaskCoversByCategory,
    createMaskCoverByCategory,
    updateMaskCoverByCategory,
    getQuoteCategories,
    createQuoteCategory,
    updateQuoteCategory,
    getQuoteByCategory,
    createQuoteByCategory,
    updateQuoteByCategory,
    getPushNotifications,
    getNextPagePushNotifications,
    createPushNotification,
    getPushNotificationDetail,
    updatePushNotification,
    getPushNotificationRecord,
    getAppBannerList,
    getLandingBannerList,
    createAppBanner,
    updateAppBanner,
    updateLandingBanner,
    getAppBannerDetail,
    deleteAppBanner,
    getCountries,
    getSurveys,
    getNextPageSurveys,
    getSurveyDetail
}