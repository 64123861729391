import React, { Component } from 'react';

import close_img from '../../images/close_gray.png';
import file from '../../images/navigation/covers.png';
import './ImageWithName.scss';

class ImageWithName extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      fileImage: null,
      product: props.product
    }
  }

  handleInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  onFileAdded = async(e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
        let fileArr = this.fileListToArray(e.target.files)
        this.setState({fileImage: fileArr});
    }
  }

  fileListToArray = list => {
    const result = [];
    for (let i = 0; i < list.length; i++) {
      result.push(list.item(i));
    }
    return result;
  };

  fileInputHandler = () => {
    if (this.state.fileImage === null) {
      this.fileInput.click();
    }
  }

  handleSaveCategory = (name, file) => {
      if (name != '' && file != null) {
        this.props.handleSaveCategory(name, file)
      }
  }

  handleSaveBanner = (file) => {
    if (file != null) {
      this.props.handleSaveBanner(file)
    }
}

  handleSaveQuote = (files) => {
    if (files != null) {
      this.props.handleSaveQuote(files)
    }
}

  handleRemovePhoto = () => {
    this.setState({fileImage: null})
  }

  handleRemoveIndividualPhoto = (index) => {
    let fileImage = this.state.fileImage;
    fileImage.splice(index, 1);
    this.setState({fileImage: fileImage})
  }

  render() {
    const { product } = this.state;
    console.log(product);
    switch (product) {
      case 'quote':
        return (
          <div className="file-container d-flex justify-content-center align-items-center">
            <div className="file-name_container-inside py-5 d-flex flex-column align-items-center justify-content-center">
              <img className="file-name_close cursor-pointer" src={close_img} height="20" alt=""  onClick={this.props.cancelAddCategory}/>
              <p className="gotham_bold-18 mb-3 mt-4">Agrega una nueva frase</p>
              <p className="gotham-book-12 mb-1 d-flex">Puedes seleccionar más de un archivo</p>
              <div className="image-container pt-4 pb-4 d-flex justify-content-center align-items-center">
                <input 
                  style={{display: 'none'}}
                  ref={(fileInput) => this.fileInput = fileInput}
                  type="file" 
                  multiple
                  onChange={(event) => this.onFileAdded(event)} 
                  accept="image/jpg, image/jpeg"
                />
                <button className="file-name_cancel-button" onClick={() => this.fileInputHandler()}>Selecciona el archivo</button>
              </div>
              <div className="file_list-container mt-2 justify-content-center align-items-center" >
                { this.state.fileImage != null && this.state.fileImage.map((item, i) => (
                  <div className="file-item_main px-1 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center ">
                      <img className="file-item_show-img" src={file} alt="" />
                      <p className="gotham_bold-12 ml-2">{item.name}</p>
                    </div>
                    <div className="cursor-pointer" onClick={() => this.handleRemoveIndividualPhoto(i)}>
                        <img src={close_img} width="12" height="12" alt="" />
                    </div>
                  </div>
                ))}
              </div>
              <div className="file-name_buttons-container mt-2 mb-4 d-flex justify-content-between">
                <button className="file-name_cancel-button" onClick={this.props.cancelAddCategory}>Cancelar</button>
                <button className="file-name_ok-button" onClick={() => this.handleSaveQuote(this.state.fileImage)}>Guardar</button>
              </div>
            </div>
          </div>
        );
      case 'landing':
        return (
          <div className="file-name_container d-flex justify-content-center align-items-center">
            <div className="file-name_container-inside py-5 d-flex flex-column align-items-center justify-content-center">
              <img className="file-name_close cursor-pointer" src={close_img} height="20" alt=""  onClick={this.props.cancelAddCategory}/>
              <p className="gotham_bold-18 mb-5 mt-4">Agrega un nueva banner</p>
              <div className="image-container pt-4 pb-4 d-flex justify-content-center align-items-center">
                <input 
                  style={{display: 'none'}}
                  ref={(fileInput) => this.fileInput = fileInput}
                  type="file" 
                  onChange={(event) => this.onFileAdded(event)} 
                  accept="image/jpg, image/jpeg"
                />
                <button className="file-name_cancel-button" onClick={() => this.fileInputHandler()}>Selecciona el archivo</button>
              </div>
              { this.state.fileImage != null &&
                <div className="file-single-item_main py-2 px-1 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center ">
                      <img className="file-item_show-img" src={file} alt="" />
                      <p className="gotham_bold-12 ml-2">{this.state.fileImage[0].name}</p>
                    </div>
                    <div className="cursor-pointer" onClick={() => this.handleRemovePhoto()}>
                        <img src={close_img} width="12" height="12" alt="" />
                    </div>
                </div>
              }
              <div className="file-name_buttons-container mt-4 mb-4 d-flex justify-content-between">
                <button className="file-name_cancel-button" onClick={this.props.cancelAddCategory}>Cancelar</button>
                <button className="file-name_ok-button" onClick={() => this.handleSaveBanner(this.state.fileImage)}>Guardar</button>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="file-name_container d-flex justify-content-center align-items-center">
            <div className="file-name_container-inside py-5 d-flex flex-column align-items-center justify-content-center">
              <img className="file-name_close cursor-pointer" src={close_img} height="20" alt=""  onClick={this.props.cancelAddCategory}/>
              <p className="gotham_bold-18 mb-5 mt-4">Agrega una nueva categoría</p>
              <div className="file-name_input-container d-flex">
                <input className="file-name_container" name="name" maxLength="180" value={this.state.name} onChange={this.handleInput} />
              </div>
              <div className="file-name_count-characters mb-3 pt-1 d-flex justify-content-end">
                <p className="gotham_bold-14">{this.state.name.length} de 180 caracteres</p>
              </div>
              <div className="image-container pt-4 pb-4 d-flex justify-content-center align-items-center">
                <input 
                  style={{display: 'none'}}
                  ref={(fileInput) => this.fileInput = fileInput}
                  type="file" 
                  onChange={(event) => this.onFileAdded(event)} 
                  accept="image/jpg, image/jpeg"
                />
                <button className="file-name_cancel-button" onClick={() => this.fileInputHandler()}>Selecciona el archivo</button>
              </div>
              { this.state.fileImage != null &&
                <div className="file-single-item_main py-2 px-1 d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center ">
                      <img className="file-item_show-img" src={file} alt="" />
                      <p className="gotham_bold-12 ml-2">{this.state.fileImage[0].name}</p>
                    </div>
                    <div className="cursor-pointer" onClick={() => this.handleRemovePhoto()}>
                        <img src={close_img} width="12" height="12" alt="" />
                    </div>
                </div>
              }
              <div className="file-name_buttons-container mt-4 mb-4 d-flex justify-content-between">
                <button className="file-name_cancel-button" onClick={this.props.cancelAddCategory}>Cancelar</button>
                <button className="file-name_ok-button" onClick={() => this.handleSaveCategory(this.state.name, this.state.fileImage)}>Guardar</button>
              </div>
            </div>
          </div>
        );
    }
  }
}


export default ImageWithName;