import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../loading/Loading';
import { getSurveys, getNextPageSurveys } from '../../services';
import SurveysSearchResultRow from './SurveysSearchResultRow';
import './SearchSurveys.scss';

import warning_img from '../../images/warning-img.png';

class SearchSurveys extends Component {

  state = {
    surveys: [],
    data: null,
    showError: false,
    errorMessage: '',
    isLoading: false,
    showLocalLoading: false
  }

  componentDidMount() {
    this.getSurveys();
  }

  getSurveys = async() => {
    const { access } = this.props.userToken;
    this.setState({isLoading: true});

    await getSurveys(access)
    .then((response) => {
      this.setState({
        surveys: response.data.results,
        data: response.data,
        isLoading: false
      });
    })
    .catch((error) => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde',
        isLoading: false
      });
    });
  }

  handleLoadNextSurveys = async() => {
    const { data, surveys } = this.state;
    const { access } = this.props.userToken;
    this.setState({showLocalLoading: true});
    const nextPageUrl = data.next;

    await getNextPageSurveys(nextPageUrl, access)
    .then((response) => {
      this.setState({
        surveys: surveys.concat(response.data.results),
        data: response.data,
        showLocalLoading: false
      });
    })
    .catch((error) => {
      this.setState({
        showError: true,
        errorMessage: 'Ocurrió un error, intenta más tarde',
        showLocalLoading: false
      });
    });
  }

  handleSelectedSurvey= (item) => {
    window.open(`/surveys/detail/${item.id}`, '_blank')
  }

  render() {
    const { surveys, data, isLoading, showLocalLoading, showError, errorMessage } = this.state;

    return (
      isLoading ? <Loading/> :
      <div className="search-push-notifications_main">
        <div className="search-push-notifications_title d-flex flex-column justify-content-center align-items-center">
          <div className="search-push-notifications_box d-flex justify-content-between align-items-center">
            {showError &&
              <div className="search-push-notifications_input-container-error">
                <div className="search-push-notifications_error mt-1 d-flex align-items-center justify-content-center">
                  <img src={warning_img} height="18" alt=""/>
                  <p className="gotham-md-red-12 ml-3">{errorMessage}</p>
                </div>
              </div>            
            }   
          </div>
        </div>
        {surveys.length > 0 &&
          <div className="pb-5 d-flex flex-column align-items-center">
            <div className="search-push-notifications_count-results pl-2">
              <p className="gotham-md-darkfont-15">Mostrando {surveys.length} de {data.count} resultados</p>
            </div>
            <table className="search-push-notifications_table">
              <thead className="search-orders_table-head">
                <tr>
                  <th className="search-orders_table-head-item">Titulo</th>
                  <th className="search-orders_table-head-item">Cupón</th>
                  <th className="search-orders_table-head-item">Tipo</th>
                  <th className="search-orders_table-head-item">Estado</th>
                </tr>
              </thead>
              <tbody>
                {surveys.map((item, i) => (
                  <SurveysSearchResultRow
                    key={i}
                    item={item}
                    handleSelectedSurvey={this.handleSelectedSurvey}
                  />
                ))}
              </tbody>
            </table>
            {(data && data.count > surveys.length) && (
              showLocalLoading ? <Loading isLocalLoading /> :
              <button className="search-push-notifications_see-more my-5" onClick={this.handleLoadNextSurveys}>
                Ver más +
              </button>)
            }
          </div>
        }
        
      </div>
    );
  }

}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

export default connect(mapStateToProps)(SearchSurveys);      