import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  getPredesignCoversByCategory, 
  createCoverByCategory, 
  updatePredesignCoverByCategory,
  getMaskCoversByCategory,
  createMaskCoverByCategory,
  updateMaskCoverByCategory,
  getQuoteByCategory,
  createQuoteByCategory,
  updateQuoteByCategory,
  postOrderError
} from '../../services';
import { updateUserTokenAction } from '../../redux/actions';
import CategoryProductCard from '../categoryProductCard';
import Modal from '../modal/Modal';
import Loading from '../loading/Loading';
import AlertView from '../alertView/AlertView';
import './Covers.scss';

import Scrollable from '../../components/Scrollable';
import EditName from '../../components/editName';
import ImageWithName from '../../components/imageWithName';

import back from '../../images/back-blue.png';
import addFile from '../../images/add_file.png';

class Covers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      albumType: '',
      albumList: null,
      isLoading: true,
      showLoacalLoading: false,
      showAlertAddAddress: false,
      showAddQuote: false,
      showError: false,
      errorMessage: '',
      category: null,
      categoryId: '',
      category_name: '',
    }
  }

  componentDidMount() {
    const { id, type } = this.props.match.params;
    this.setState({
        albumType: type,
        categoryId: id
    });
    this.getPredesignCoversByCategory(id, type);
  }

  getPredesignCoversByCategory = async(id, type) => {
    const { userToken } = this.props;
    let response = null;
    try {
      switch (type) {
        case 'predesign':
          response = await getPredesignCoversByCategory(id, userToken.access);
          break;
        case 'mask':
          response = await getMaskCoversByCategory(id, userToken.access);
          break;
        case 'quote':
          response = await getQuoteByCategory(id, userToken.access);
          break;
      }
      if (response.status === 200) {
        this.setState({
          albumList: response.data.results,
          category_name: response.data.category_name,
          isLoading: false
        });
      }
    } catch(e) {
      this.setState({
        showError: true,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.'
      })
    }

  }

  handleChangeStatusCategory = async(id, data) => {
    const { userToken } = this.props;
    let response = null;
    try {
      switch (this.state.albumType) {
        case 'predesign':
          response = await updatePredesignCoverByCategory(id, this.state.categoryId, userToken.access, data);
          break;
        case 'mask':
          response = await updateMaskCoverByCategory(id, this.state.categoryId, userToken.access, data);
          break;
        case 'quote':
          response = await updateQuoteByCategory(id, this.state.categoryId, userToken.access, data);
          break;
      }
      if (response.status === 200) {
        this.getPredesignCoversByCategory(this.state.categoryId, this.state.albumType);
      }
    } catch(e) {
      this.setState({
        showError: true,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.'
      })
    }
  }

  handleGoToCreateCover = () => {
    const { albumType, categoryId } = this.state
    switch (albumType) {
      case 'predesign':
        window.location.replace('/predesign/cover/categories/' + categoryId + '/createcover');
        break;
      case 'mask':
        window.location.replace('/mask/cover/categories/' + categoryId + '/createcover');
        break;
      case 'quote':
        window.location.replace('/quote/categories/' + categoryId + '/createquote');
        break;
    }
  }

  handleOrderError = async(orderId, token) => {
    try {
      const response = await postOrderError(orderId, token, {});
      if (response.status === 200) {
        this.setState({isLoading: false})
      }
    } catch(e) {
      this.setState({
        showError: true,
        isLoading: false,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.'
      })
    }
  }

  handleSaveQuote = async(files) => {
    this.setState({isLoading: true})
    const { userToken } = this.props;
    const { categoryId } = this.state
    const data = new FormData();
    Array.from(files).forEach(file => {
      data.append('images', file);
    });
    try {
      let response = await createQuoteByCategory(categoryId, userToken.access, data);
      console.log(response);
      if (response.status === 200 || response.status === 201) {
        this.getPredesignCoversByCategory(categoryId, this.state.albumType);
        this.cancelAddQuote();
      }
    } catch(e) {
      this.setState({
        showError: true,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.',
        isLoading: false
      })
    }
  }

  handleShowAddQuote = () => {
    this.setState({ showAddQuote: true });
  }

  cancelAddQuote = () => {
    this.setState({ showAddQuote: false });
  }

  handleOkError = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  render() {
    const { albumList, albumType, category_name, isLoading, showError, errorMessage, showAddQuote } = this.state;
    let title = "";
    switch (albumType) {
      case 'predesign':
        title = 'Portadas prediseñadas > ' + category_name;
        break;
      case 'mask':
        title = 'Portadas con marco > ' + category_name;
        break;
      case 'quote':
        title = 'Frases > ' + category_name;
        break;
    }
    return (
      isLoading ? <Loading/> :
      <React.Fragment>
        {showError && 
          <Modal>
            <AlertView
              handleOkButton={this.handleOkError}
              message={errorMessage}
            />
          </Modal>
        }{
          showAddQuote &&
          <Modal>
            <ImageWithName
              handleSaveQuote={this.handleSaveQuote}
              cancelAddCategory={this.cancelAddQuote}
              product={albumType}
            />
          </Modal>
        }
        <div className="cover-categories_main">
          <div className="border" />
          <div className="cover-categories_header d-flex align-items-center">
            <div className="cover-categories_back-btn d-flex justify-content-start">
              <img className="cursor-pointer" src={back} alt="" height="31" onClick={() => this.props.history.goBack()} />
            </div>
            <div className="collections_customer-products d-flex flex-column justify-content-center align-items-end">
              <div className="d-flex cursor-pointer align-items-center mr-5" onClick={() => albumType === 'quote' ? this.handleShowAddQuote() : this.handleGoToCreateCover()}>
                <img className="" src={addFile} alt="" height="22" />
                <p className="gotham_bold-black-14 ml-2">Agregar nueva { albumType === 'quote' ? 'frase' : 'portada' }</p>
              </div>
            </div>
          </div>
          <div className="border" />
          <Scrollable scrollableContent={
                <div className="collections_container">
                  <div className="ml-4 d-flex align-items-start">
                    <p className="ml-5 mb-5 gotham_bold-18">
                      { title }
                    </p>
                  </div>
                  <div className="collections_customer-products d-flex justify-content-center">
                    <div className="collections_customer-products-inside d-flex flex-wrap">
                      {albumList.length > 0 && albumList.map((cover, i) => (
                        <CategoryProductCard
                          key={i}
                          cover={cover}
                          coverType={ albumType === 'quote' ? 'quote' : 'cover' }
                          selectCollectionCallBack={this.selectCollectionCallBack}
                          goToTemplates={this.goToTemplates}
                          handleChangeStatusCategory={this.handleChangeStatusCategory}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              }></Scrollable>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

const mapDispatchToProps = {
  updateUserTokenAction: updateUserTokenAction
}


export default connect(mapStateToProps, mapDispatchToProps)(Covers);