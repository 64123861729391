import React, { Component } from 'react';
import { createPredesignCoverByCategory, updatePredesignCoverByCategory, createMaskCoverByCategory, updateMaskCoverByCategory } from '../../services';
import { connect } from 'react-redux';
import './CreateCover.scss';
import Loading from '../loading/Loading';
import Modal from '../modal/Modal';
import AlertView from '../alertView/AlertView';

class CreateCover extends Component {

  state = {
    selected_file_D16_30: null,
    selected_file_D16_40: null,
    selected_file_D16_50: null,
    selected_file_D21_30: null,
    selected_file_D21_40: null,
    selected_file_D21_50: null,
    selected_file_S16_30: null,
    selected_file_S16_40: null,
    selected_file_S16_50: null,
    selected_file_S21_30: null,
    selected_file_S21_40: null,
    selected_file_S21_50: null,
    cover_type: '',
    category_id: 0,
    mask_cover_type: 'header',
    isLoading: false,
    showError: false,
    errorMessage: false
  }

  componentDidMount() {
    const { id, type } = this.props.match.params;
    this.setState({
        cover_type: type,
        category_id: id
    });
  }

  handleChange = e => {
    this.setState({
        [e.target.name] : e.target.files[0]
    });
  }

  handleSelectorChange = e => {
    this.setState({
        [e.target.name] : e.target.value
    });
  }

  handleSubmit = async(e) => {
    e.preventDefault();
    this.setState({isLoading: true})
    const { userToken } = this.props;
    const { selected_file_D16_30, selected_file_D16_40, selected_file_D16_50, selected_file_D21_30, selected_file_D21_40, selected_file_D21_50, selected_file_S16_30, selected_file_S16_40, selected_file_S16_50, selected_file_S21_30, selected_file_S21_40, selected_file_S21_50, mask_cover_type } = this.state;

    const data_30 = new FormData();
    data_30.append('D16_30', selected_file_D16_30);
    data_30.append('D21_30', selected_file_D21_30);
    data_30.append('S16_30', selected_file_S16_30);
    data_30.append('S21_30', selected_file_S21_30);
    data_30.append('is_active', true);

    const data_40 = new FormData();
    data_40.append('D16_40', selected_file_D16_40);
    data_40.append('D21_40', selected_file_D21_40);
    data_40.append('S16_40', selected_file_S16_40);
    data_40.append('S21_40', selected_file_S21_40);
    data_40.append('is_active', true);

    const data_50 = new FormData();
    data_50.append('D16_50', selected_file_D16_50);
    data_50.append('D21_50', selected_file_D21_50);
    data_50.append('S16_50', selected_file_S16_50);
    data_50.append('S21_50', selected_file_S21_50);
    data_50.append('is_active', true);

    const { category_id, cover_type } = this.state;

    switch (cover_type) {
        case 'predesign':
            await createPredesignCoverByCategory(category_id, userToken.access, data_30)
            .then((response) => {
                const cover_id = response.data.id;
                updatePredesignCoverByCategory(cover_id, category_id, userToken.access, data_40)
                .then((response) => {
                    updatePredesignCoverByCategory(cover_id, category_id, userToken.access, data_50)
                    .then((response) => {
                        window.location.replace('/predesign/cover/categories/' + category_id + '/');
                    })
                    .catch((error) => {
                        this.setState({
                            isLoading: false,
                            showError: true,
                            errorMessage: error.response.data.message
                        });
                    });
                })
                .catch((error) => {
                    this.setState({
                        isLoading: false,
                        showError: true,
                        errorMessage: error.response.data.message
                    });
                });
            })
            .catch((error) => {
                this.setState({
                    isLoading: false,
                    showError: true,
                    errorMessage: error.response.data.message
                });
            });
            break;
        case 'mask':
          data_30.append('template', mask_cover_type);
          console.log(mask_cover_type)
          await createMaskCoverByCategory(category_id, userToken.access, data_30)
          .then((response) => {
              const cover_id = response.data.id;
              updateMaskCoverByCategory(cover_id, category_id, userToken.access, data_40)
              .then((response) => {
                  updateMaskCoverByCategory(cover_id, category_id, userToken.access, data_50)
                  .then((response) => {
                      window.location.replace('/mask/cover/categories/' + category_id + '/');
                  })
                  .catch((error) => {
                      this.setState({
                          isLoading: false,
                          showError: true,
                          errorMessage: error.response.data.message
                      });
                  });
              })
              .catch((error) => {
                  this.setState({
                      isLoading: false,
                      showError: true,
                      errorMessage: error.response.data.message
                  });
              });
          })
          .catch((error) => {
              this.setState({
                  isLoading: false,
                  showError: true,
                  errorMessage: error.response.data.message
              });
          });
          break;
      }
  }

  handleOkError = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  render() {
    const { selected_file_D16_30, selected_file_D16_40, selected_file_D16_50, selected_file_D21_30, selected_file_D21_40, selected_file_D21_50, selected_file_S16_30, selected_file_S16_40, selected_file_S16_50, selected_file_S21_30, selected_file_S21_40, selected_file_S21_50, cover_type, isLoading, showError, errorMessage } = this.state;

    return (
      isLoading ? <Loading/> :
      <div className="create-cover_main mt-4 ml-5 mr-5 mb-5">
        {showError && 
          <Modal>
            <AlertView
              handleOkButton={this.handleOkError}
              message={errorMessage}
            />
          </Modal>
        }
        <div className="create-cover_inside">
          <div class="d-flex justify-content-center mb-3">
            <h1>Crear portada { cover_type === 'predesign' ? 'prediseñada' : 'con marco' }</h1>
          </div>
          <form className="create-cover-form" onSubmit={this.handleSubmit}>
            <div className="create-cover-input-container">
              <label className='create-cover-input-label mr-3'>Pasta suave 60 páginas 16x16: </label>
              <input 
                type="file"
                name="selected_file_S16_30"
                required
                onChange={this.handleChange}
                accept="image/png, image/jpeg, image/jpeg"
              />
            </div>
            { selected_file_S16_30 &&
                <img src={URL.createObjectURL(selected_file_S16_30)} width="320px" height="160px" alt="Cover Imge"/>
            }
            <hr className="cart_info-inline"></hr>
            <div className="create-cover-input-container">
              <label className='create-cover-input-label mr-3'>Pasta suave 80 páginas 16x16: </label>
              <input 
                type="file"
                name="selected_file_S16_40"
                required
                onChange={this.handleChange}
                accept="image/png, image/jpeg, image/jpeg"
              />
            </div>
            { selected_file_S16_40 &&
                <img src={URL.createObjectURL(selected_file_S16_40)} width="320px" height="160px" alt="Cover Imge"/>
            }
            <hr className="cart_info-inline"></hr>
            <div className="create-cover-input-container">
              <label className='create-cover-input-label mr-3'>Pasta suave 100 páginas 16x16: </label>
              <input 
                type="file"
                name="selected_file_S16_50"
                required
                onChange={this.handleChange}
                accept="image/png, image/jpeg, image/jpeg"
              />
            </div>
            { selected_file_S16_50 &&
                <img src={URL.createObjectURL(selected_file_S16_50)} width="320px" height="160px" alt="Cover Imge"/>
            }
            <hr className="cart_info-inline"></hr>
            <div className="create-cover-input-container">
              <label className='create-cover-input-label mr-3'>Pasta dura 60 páginas 16x16: </label>
              <input 
                type="file"
                name="selected_file_D16_30"
                required
                onChange={this.handleChange}
                accept="image/png, image/jpeg, image/jpeg"
              />
            </div>
            { selected_file_D16_30 &&
                <img src={URL.createObjectURL(selected_file_D16_30)} width="320px" height="160px" alt="Cover Imge"/>
            }
            <hr className="cart_info-inline"></hr>
            <div className="create-cover-input-container">
              <label className='create-cover-input-label mr-3'>Pasta dura 80 páginas 16x16: </label>
              <input 
                type="file"
                name="selected_file_D16_40"
                required
                onChange={this.handleChange}
                accept="image/png, image/jpeg, image/jpeg"
              />
            </div>
            { selected_file_D16_40 &&
                <img src={URL.createObjectURL(selected_file_D16_40)} width="320px" height="160px" alt="Cover Imge"/>
            }
            <hr className="cart_info-inline"></hr>
            <div className="create-cover-input-container">
              <label className='create-cover-input-label mr-3'>Pasta dura 100 páginas 16x16: </label>
              <input 
                type="file"
                name="selected_file_D16_50"
                required
                onChange={this.handleChange}
                accept="image/png, image/jpeg, image/jpeg"
              />
            </div>
            { selected_file_D16_50 &&
                <img src={URL.createObjectURL(selected_file_D16_50)} width="320px" height="160px" alt="Cover Imge"/>
            }
            <hr className="cart_info-inline"></hr>
            <div className="create-cover-input-container">
              <label className='create-cover-input-label mr-3'>Pasta suave 60 páginas 21x21: </label>
              <input 
                type="file"
                name="selected_file_S21_30"
                required
                onChange={this.handleChange}
                accept="image/png, image/jpeg, image/jpeg"
              />
            </div>
            { selected_file_S21_30 &&
                <img src={URL.createObjectURL(selected_file_S21_30)} width="320px" height="160px" alt="Cover Imge"/>
            }
            <hr className="cart_info-inline"></hr>
            <div className="create-cover-input-container">
              <label className='create-cover-input-label mr-3'>Pasta suave 80 páginas 21x21: </label>
              <input 
                type="file"
                name="selected_file_S21_40"
                required
                onChange={this.handleChange}
                accept="image/png, image/jpeg, image/jpeg"
              />
            </div>
            { selected_file_S21_40 &&
                <img src={URL.createObjectURL(selected_file_S21_40)} width="320px" height="160px" alt="Cover Imge"/>
            }
            <hr className="cart_info-inline"></hr>
            <div className="create-cover-input-container">
              <label className='create-cover-input-label mr-3'>Pasta suave 100 páginas 21x21: </label>
              <input 
                type="file"
                name="selected_file_S21_50"
                required
                onChange={this.handleChange}
                accept="image/png, image/jpeg, image/jpeg"
              />
            </div>
            { selected_file_S21_50 &&
                <img src={URL.createObjectURL(selected_file_S21_50)} width="320px" height="160px" alt="Cover Imge"/>
            }
            <hr className="cart_info-inline"></hr>
            <div className="create-cover-input-container">
              <label className='create-cover-input-label mr-3'>Pasta dura 60 páginas 21x21: </label>
              <input 
                type="file"
                name="selected_file_D21_30"
                required
                onChange={this.handleChange}
                accept="image/png, image/jpeg, image/jpeg"
              />
            </div>
            { selected_file_D21_30 &&
                <img src={URL.createObjectURL(selected_file_D21_30)} width="320px" height="160px" alt="Cover Imge"/>
            }
            <hr className="cart_info-inline"></hr>
            <div className="create-cover-input-container">
              <label className='create-cover-input-label mr-3'>Pasta dura 80 páginas 21x21: </label>
              <input 
                type="file"
                name="selected_file_D21_40"
                required
                onChange={this.handleChange}
                accept="image/png, image/jpeg, image/jpeg"
              />
            </div>
            { selected_file_D21_40 &&
                <img src={URL.createObjectURL(selected_file_D21_40)} width="320px" height="160px" alt="Cover Imge"/>
            }
            <hr className="cart_info-inline"></hr>
            <div className="create-cover-input-container">
              <label className='create-cover-input-label mr-3'>Pasta dura 100 páginas 21x21: </label>
              <input 
                type="file"
                name="selected_file_D21_50"
                required
                onChange={this.handleChange}
                accept="image/png, image/jpeg, image/jpeg"
              />
            </div>
            { selected_file_D21_50 &&
                <img src={URL.createObjectURL(selected_file_D21_50)} width="320px" height="160px" alt="Cover Imge"/>
            }
            <hr className="cart_info-inline"></hr>
            { cover_type === 'mask' && 
            <div>
              <p>Selecciona la forma de la portada:</p>
              <select name='mask_cover_type' onChange={this.handleSelectorChange}>
                <option value="header" defaultValue>Rectangular</option>
                <option value="border">Cuadrada</option>
              </select>
              <hr className="cart_info-inline"></hr>
            </div>
            }
            <div className="d-flex justify-content-center align-items-center">
                <button id="create_cover" type="submit" className="create_cover_button my-3">Guardar Portada</button>
              </div>
          </form>
        </div>
      </div>
    );
  }

}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

export default connect(mapStateToProps)(CreateCover);