import React, { Component } from 'react';
import { getAlbumDetail } from '../../../services';
import { connect } from 'react-redux';
import Loading from '../../loading/Loading';
import Modal from '../../modal/Modal';
import AlertView from '../../alertView/AlertView';
import SoftCoverAlbumTemplate16 from './160/SoftCoverAlbumTemplate16';
import HardCoverAlbumTemplate16 from './160/HardCoverAlbumTemplate16';
import SoftCoverAlbumTemplate from './210/SoftCoverAlbumTemplate';
import HardCoverAlbumTemplate from './210/HardCoverAlbumTemplate';
import './Album.scss';

class Album extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [],
      configuration: '',
      order_number: '',
      barcode_base64: '',
      isLoading: true,
      showError: false,
      errorMessage: '',
      albumData: null
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const albumData = JSON.parse(localStorage.getItem('album_data'))
    this.setState({albumData})
    this.getAlbumData(id);
  }

  getAlbumData = async(id) => {
    const { userToken } = this.props;
    try {
      const response = await getAlbumDetail(id, userToken.access);
      if (response.status === 200) {
        let photos = this.getAllPhotos(response.data.pages)
        this.setState({
          photos: photos,
          order_number: response.data.order_number,
          isLoading: false
        })
      }
    } catch(err) {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ha ocurrido un error, por favor intenta más tarde.'
      })
    }
  }

  getAllPhotos = (pages) => {
    let sortedPages = pages.sort((a, b) => a.index - b.index);
    let finalPhotosArray = [];
    for (let page of sortedPages) {
      let photo = page.photos[0];
      delete photo['index'];
      photo.index = page.index;
      finalPhotosArray.push(photo);
    }
    console.log(finalPhotosArray);
    return finalPhotosArray;
  }

  handleOkError = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  render() {
    const { photos, isLoading, albumData, order_number, showError, errorMessage } = this.state;
    return (
      isLoading ? <Loading/> :
      <div>
        {showError && 
          <Modal>
            <AlertView
              handleOkButton={this.handleOkError}
              message={errorMessage}
            />
          </Modal>
        }
        {albumData.size === '16x16' ?
          (albumData.hard_cover ? 
            <HardCoverAlbumTemplate16
              photos={photos}
              albumData={albumData}
              order_number={order_number}
            /> :
            <SoftCoverAlbumTemplate16
              photos={photos}
              albumData={albumData}
              order_number={order_number}
            />    
          ) : (
            albumData.hard_cover ? 
              <HardCoverAlbumTemplate 
                photos={photos}
                albumData={albumData}
                order_number={order_number}
              /> :
              <SoftCoverAlbumTemplate
                photos={photos}
                albumData={albumData}
                order_number={order_number}
              />
            )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

export default connect(mapStateToProps)(Album);