import React, { Component } from 'react';
import { getPictureDetail } from '../../../services';
import { connect } from 'react-redux';
import NoFrameTemplate from './NoFrameTemplate';
import FrameTemplate from './FrameTemplate';
import Loading from '../../loading/Loading';
import Modal from '../../modal/Modal';
import AlertView from '../../alertView/AlertView';
import './Picture.scss';


class Picture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [],
      configuration: '',
      order_number: '',
      barcode_base64: '',
      isLoading: true,
      showError: false,
      errorMessage: ''
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getPictureData(id);
  }

  getPictureData = async(id) => {
    const { userToken } = this.props;
    try {
      const { config } = this.props.match.params;
      const response = await getPictureDetail(id, userToken.access);
      if (response.status === 200) {
        this.setState({
          photos: response.data.photos,
          order_number: response.data.order_number,
          barcode_base64: response.data.barcode_base64,
          configuration: config,
          isLoading: false,
        });
      }
    } catch(e) {
      this.setState({
        isLoading: false,
        showError: true,
        errorMessage: 'Ha ocurrido un error, por favor intenta más tarde.'
      })
    }
  }

  handleOkError = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  render() {
    const { configuration, isLoading, photos, order_number, barcode_base64, showError, errorMessage } = this.state;
    return (
      isLoading ? <Loading/> :
      <div>
        {showError && 
          <Modal>
            <AlertView
              handleOkButton={this.handleOkError}
              message={errorMessage}
            />
          </Modal>
        }
        {configuration === 'Marco' ? 
          <NoFrameTemplate 
            photos={photos}
            order_number={order_number}
            barcode_base64={barcode_base64}
          /> : 
          <FrameTemplate
            photos={photos}
            order_number={order_number}
            barcode_base64={barcode_base64}
            configuration={configuration}
          />
        }
      </div>
    );
  }
};

const mapStateToProps = state => ({
  userToken: state.userToken,
});

export default connect(mapStateToProps)(Picture);