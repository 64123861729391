import React from 'react';
import './Loading.scss';

export default function Loading({ isLocalLoading }) {
  return (
    isLocalLoading ? 
    <div className="loading_local d-flex justify-content-center align-items-center">
      <div className="spinner-border text-primary"></div>
    </div> :
    <div className="loading_main d-flex justify-content-center align-items-center">
      <div className="spinner-border text-primary"></div>
    </div>
  );
}