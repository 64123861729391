import React from 'react';
import './UserAddressCard.scss';

const UserAddressCard = (props) => {
  const { address_name, address_icon } = props.address;
  const { address } = props;
  return (
    <div className="order_container d-flex flex-column align-items-center">
      <div className="cursor-pointer">
        <div className="d-flex order-detail_container-inside justify-content-start align-items-center ms-3">
          <p className="gotham-book-darkfont-16">{address_name} <img src={address_icon} alt="" height="16" /></p>
        </div>
      </div>
    </div>
  );
}

export default UserAddressCard;