import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  getPredesignCategories, 
  createCoverCategory, 
  updateCoverCategory,
  getMaskCategories,
  createMaskCoverCategory,
  updateMaskCoverCategory,
  getQuoteCategories,
  createQuoteCategory,
  updateQuoteCategory,
  getAppBannerList,
  getLandingBannerList,
  updateLandingBanner,
  postOrderError
} from '../../services';
import { updateUserTokenAction } from '../../redux/actions';
import CategoryProductCard from '../categoryProductCard';
import Modal from '../modal/Modal';
import Loading from '../loading/Loading';
import AlertView from '../alertView/AlertView';
import './CoverCategories.scss';

import Scrollable from '../../components/Scrollable';
import EditName from '../../components/editName';
import ImageWithName from '../../components/imageWithName';

import back from '../../images/back-blue.png';
import addFile from '../../images/add_file.png';

class CoverCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      albumType: '',
      categoryList: null,
      isLoading: true,
      showLoacalLoading: false,
      showAlertAddAddress: false,
      showAddCategory: false,
      bannerId: '',
      showError: false,
      errorMessage: '',
      showEditName: false,
      categorySelected: null
    }
  }

  componentDidMount() {
    const { type } = this.props.match.params;
    this.setState({
      albumType: type
    });
    console.log("Type: " + type);
    this.getCoverCategories(type);
  }

  getCoverCategories = async(type) => {
    const { userToken } = this.props;
    let response = null;
    try {
      switch (type) {
        case 'predesign':
            response = await getPredesignCategories(userToken.access);
          break;
        case 'mask':
            response = await getMaskCategories(userToken.access);
          break;
        case 'quote':
          response = await getQuoteCategories(userToken.access);
          break;
        case 'app':
          response = await getAppBannerList(userToken.access);
          break;
        case 'landing':
          response = await getLandingBannerList(userToken.access);
          break;
      }
      if (response.status === 200) {
        this.setState({
          categoryList: response.data.results,
          isLoading: false
        });
      }
    } catch(e) {
      this.setState({
        showError: true,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.'
      })
    }

  }

  handleChangeStatusCategory = async(categoryId, data) => {
    this.setState({isLoading: true})
    const { userToken } = this.props;
    let response = null;
    try {
      switch (this.state.albumType) {
        case 'predesign':
          response = await updateCoverCategory(categoryId, userToken.access, data);
          break;
        case 'mask':
          response = await updateMaskCoverCategory(categoryId, userToken.access, data);
          break;
        case 'quote':
          response = await updateQuoteCategory(categoryId, userToken.access, data);
          break;
      }
      if (response.status === 200) {
        this.getCoverCategories(this.state.albumType);
        this.cancelEditName();
      }
    } catch(e) {
      this.setState({
        showError: true,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.',
        isLoading: false
      })
    }
  }

  handleSaveCategory = async(name, image) => {
    this.setState({isLoading: true})
    const { userToken } = this.props;
    const data = new FormData();
    data.append('name', name);
    data.append('image', image[0]);
    let response = null;
    try {
      switch (this.state.albumType) {
        case 'predesign':
          response = await createCoverCategory(userToken.access, data);
          break;
        case 'mask':
          response = await createMaskCoverCategory(userToken.access, data);
          break;
        case 'quote':
          response = await createQuoteCategory(userToken.access, data);
          break;
      }
      if (response.status === 200 || response.status === 201) {
        this.getCoverCategories(this.state.albumType);
        this.cancelAddCategory();
      }
    } catch(e) {
      this.setState({
        showError: true,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.',
        isLoading: false
      })
    }
  }

  handleSaveBanner = async(image) => {
    this.setState({isLoading: true})
    const { userToken } = this.props;
    const data = new FormData();
    data.append('image', image[0]);
    if (this.state.bannerId) {
      await updateLandingBanner(this.state.bannerId, userToken.access, data)
      .then((response) => {
        this.getCoverCategories(this.state.albumType);
        this.cancelAddCategory();
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          showError: true,
          errorMessage: error.response.data.message
        });
      });
    }
  }

  onFileAdded = async(categoryId, e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
        let file = e.target.files[0];
        const data = new FormData();
        data.append('image', file);
        this.handleChangeStatusCategory(categoryId, data);
    }
  }

  handleOrderError = async(orderId, token) => {
    try {
      const response = await postOrderError(orderId, token, {});
      if (response.status === 200) {
        this.setState({isLoading: false})
      }
    } catch(e) {
      this.setState({
        showError: true,
        isLoading: false,
        errorMessage: 'Ha ocurrido un error, intenta más tarde.'
      })
    }
  }

  handleOkError = () => {
    this.setState({
      showError: false,
      errorMessage: ''
    });
  }

  handleShowEditName = (category) => {
    this.setState({ 
        showEditName: true, 
        categorySelected: category
    });
  }

  cancelEditName = () => {
    this.setState({ showEditName: false });
  }

  handleShowAddCategory = () => {
    switch (this.state.albumType) {
      case 'app':
        this.props.history.push({
          pathname: `/banners/app/create`
      });
        break;
      default:
        this.setState({ showAddCategory: true });
        break;
    }
  }

  cancelAddCategory = () => {
    this.setState({ showAddCategory: false });
  }

  handlerFileInput = (category) => {
    this.setState({ categorySelected: category });
    this.fileInput.click();
  }

  handleSelectCategory = (category) => {
    let pathname = '';
    switch (this.state.albumType) {
      case 'predesign':
        pathname = `/predesign/cover/categories/${category.id}`;
        break;
      case 'mask':
        pathname = `/mask/cover/categories/${category.id}`;
        break;
      case 'quote':
        pathname = `/quote/categories/${category.id}`;
        break;
    }
    this.props.history.push({
        pathname: pathname,
        params: { category: category }
    });
  }

  handlerEditBanner = (id) => {
    switch (this.state.albumType) {
      case 'app':
        let pathname = `/banners/app/${id}`;
        this.props.history.push({
            pathname: pathname
        });
        break;
      case 'landing':
        this.setState({ 
          showAddCategory: true,
          bannerId: id
        });
        break;
    }
  }

  render() {
    const { categoryList, isLoading, showError, errorMessage, showEditName, showAddCategory, categorySelected, albumType } = this.state;
    let title = "";
    let type = "";
    let text = "";
    switch (albumType) {
      case 'predesign':
        title = "Portadas prediseñadas";
        type = "category";
        text = "album de portadas";
        break;
      case 'mask':
        title = "Portadas con marco";
        type = "category";
        text = "album de portadas";
        break;
      case 'quote':
        title = "Frases";
        type = "category";
        text = "album de frases";
        break;
      case 'app':
        title = "App Banners";
        type = "app_banner";
        text = "banner";
        break;
      case 'landing':
        title = "Landing Banners";
        type = "landing_banner";
        text = "banner";
        break;
    }
    return (
      isLoading ? <Loading/> :
      <React.Fragment>
        {showError && 
          <Modal>
            <AlertView
              handleOkButton={this.handleOkError}
              message={errorMessage}
            />
          </Modal>
        }
        {
          showEditName &&
          <Modal>
            <EditName
              type={'coverCategory'}
              name={categorySelected.name}
              id={categorySelected.id}
              handleChangeStatusCategory={this.handleChangeStatusCategory}
              cancelEditName={this.cancelEditName}
            />
          </Modal>
        }
        {
          showAddCategory &&
          <Modal>
            <ImageWithName
              handleSaveCategory={this.handleSaveCategory}
              handleSaveBanner={this.handleSaveBanner}
              cancelAddCategory={this.cancelAddCategory}
              product={albumType}
            />
          </Modal>
        }
        <div className="cover-categories_main">
          <div className="border" />
          <div className="cover-categories_header d-flex align-items-center">
            <div className="cover-categories_back-btn d-flex justify-content-start">
              <img className="cursor-pointer" src={back} alt="" height="31" onClick={() => this.props.history.goBack()} />
            </div>
            <div className="collections_customer-products d-flex flex-column justify-content-center align-items-end">
              { albumType !== 'landing' &&
                <div className="cursor-pointer d-flex align-items-center mr-5" onClick={() => this.handleShowAddCategory()}>
                  <img className="" src={addFile} alt="" height="22" />
                  <p className="gotham_bold-black-14 ml-2">Agregar nuevo {text}</p>
                </div>
              }  
            </div>
          </div>
          <div className="border" />
          <Scrollable scrollableContent={
                <div className="collections_container">
                  <div className="ml-3 d-flex align-items-start">
                    <p className="ml-5 mb-5 gotham_bold-18">{ title }</p>
                  </div>
                  <input 
                    style={{display: 'none'}}
                    ref={(fileInput) => this.fileInput = fileInput}
                    type="file" 
                    onChange={(event) => this.onFileAdded(this.state.categorySelected.id, event)} 
                    accept="image/png, image/jpeg, image/jpeg"
                  />
                  <div className="collections_customer-products d-flex justify-content-center">
                    <div className="collections_customer-products-inside d-flex flex-wrap">
                      {categoryList.length > 0 && categoryList.map((category, i) => (
                        <CategoryProductCard
                          key={i}
                          category={category}
                          coverType={type}
                          product={ albumType === 'quote' ? 'frases' : 'portadas' }
                          selectCollectionCallBack={this.selectCollectionCallBack}
                          handlerFileInput={this.handlerFileInput}
                          handleChangeStatusCategory={this.handleChangeStatusCategory}
                          handleShowEditName={this.handleShowEditName}
                          handleSelectCategory={this.handleSelectCategory}
                          handlerEditBanner={this.handlerEditBanner}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              }></Scrollable>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userToken: state.userToken,
});

const mapDispatchToProps = {
  updateUserTokenAction: updateUserTokenAction
}


export default connect(mapStateToProps, mapDispatchToProps)(CoverCategories);