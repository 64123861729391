import React, { Component } from 'react';
import { updateShippingAddress } from '../../services';

import close_gray from '../../images/close_gray.png';

import './EditAddress.scss';

class EditAddress extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      street: '',
      number: '',
      number_int: '',
      neighborhood: '',
      city: '',
      country_code: '',
      state: '',
      zip_code: '',
      references: '',
      receiver_name: '',
      receiver_phone: ''
    }
  }

  componentDidMount() {
    const { name, street, number, number_int, neighborhood, city, country, state, zip_code, references, receiver_name, receiver_phone } = this.props.shippingAddress
    this.setState({
      name: name,
      street: street,
      number: number,
      number_int: number_int,
      neighborhood: neighborhood,
      city: city,
      country_code: country.code,
      state: state,
      zip_code: zip_code,
      references: references,
      receiver_name: receiver_name,
      receiver_phone: receiver_phone
    })
  }

  handleChange = e => {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  handleCancel = e => {
    e.preventDefault();
    this.props.dismissHandler();
  }

  handleSubmit = async(e) => {
    e.preventDefault();
    const { shippingAddress, userToken } = this.props;
    const { street, number, number_int, neighborhood, city, country_code, state, zip_code, references, receiver_phone, receiver_name } = this.state;
    const data = {};
    if (shippingAddress.receiver_name !== receiver_name) {
      data.receiver_name = receiver_name;
    }
    if (shippingAddress.receiver_phone !== receiver_phone) {
      data.receiver_phone = receiver_phone;
    }
    if (shippingAddress.street !== street) {
      data.street = street;
    }
    if (shippingAddress.number !== number) {
      data.number = number;
    }
    if (shippingAddress.number_int !== number_int) {
      data.number_int = number_int
    }
    if (shippingAddress.neighborhood !== neighborhood) {
      data.neighborhood = neighborhood;
    }
    if (shippingAddress.city !== city) {
      data.city = city;
    }
    if (shippingAddress.country.code !== country_code) {
      data.country = {};
      data.country.code = country_code;
    }
    if (shippingAddress.state !== state) {
      data.state = state;
    }
    if (shippingAddress.zip_code !== zip_code) {
      data.zip_code = zip_code;
    }
    if (shippingAddress.references !== references) {
      data.references = references;
    }
    try {
      const response = await updateShippingAddress(shippingAddress.order, userToken.access, data);
      if (response.status === 200) {
        this.props.handleShowCreateAddress(true);
      }
    } catch(err) {
      // console.log('error', err)
    }
  }

  render() {
    const { creatingAddressOnServer } = this.props;
    const { receiver_name, receiver_phone, street, number, number_int, neighborhood, city, country_code, state, zip_code, references } = this.state;
    return (
      <div className="create-address_main d-flex justify-content-center align-items-center">
        <div className="create-address_inside d-flex flex-column justify-content-center align-items-center">
          <img className="create-address_close cursor-pointer mt-3 me-4" src={close_gray} height="20" alt="" onClick={this.props.handleShowCreateAddress} />
          <p className="gotham-bold-darkfont-15 mb-4">Modificar dirección</p>
          <form className="create-address_form" onSubmit={this.handleSubmit}>
            <div className="crate-address_input-container w-100">
              {receiver_name && <label className="crate-address_input-label">Nombre de quien recibe</label>}
              <input 
                type="text" 
                placeholder="Nombre de quien recibe" 
                name="receiver_name"
                value={receiver_name} 
                onChange={this.handleChange} 
                disabled={creatingAddressOnServer}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="crate-address_input-container w-100">
              {receiver_phone && <label className="crate-address_input-label">Teléfono</label>}
              <input 
                type="text" 
                placeholder="Teléfono (opcional)" 
                name="receiver_phone" 
                minLength="10" 
                maxLength="10" 
                value={receiver_phone} 
                onChange={this.handleChange} 
                disabled={creatingAddressOnServer}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="crate-address_input-container w-100">
              {street && <label className="crate-address_input-label">Calle</label>}
              <input 
                type="text" 
                required
                placeholder="Calle" 
                name="street" 
                value={street} 
                onChange={this.handleChange} 
                disabled={creatingAddressOnServer}
              />
            </div>
            <hr className="cart_info-inline"></hr>

            <div className="w-100 d-flex">
              <div className="crate-address_input-container w-50">
                {number && <label className="crate-address_input-label">No. Ext.</label>}
                <input 
                  type="text" 
                  required
                  placeholder="No. Ext." 
                  name="number" 
                  value={number} 
                  onChange={this.handleChange} 
                  disabled={creatingAddressOnServer}
                />
              </div>
              <div className="crate-address_input-container-right w-50">
                {number_int && <label className="crate-address_input-label pl-1">No. Int.</label>}
                <input 
                  type="text" 
                  placeholder="No. Int." 
                  name="number_int" 
                  value={number_int} 
                  onChange={this.handleChange} 
                  disabled={creatingAddressOnServer}
                />
              </div>
            </div>
            <hr className="cart_info-inline"></hr>

            <div className="crate-address_input-container w-100">
              {neighborhood && <label className="crate-address_input-label">Colonia</label>}
              <input 
                type="text" 
                required
                placeholder="Colonia" 
                name="neighborhood" 
                value={neighborhood} 
                onChange={this.handleChange} 
                disabled={creatingAddressOnServer}
              />
            </div>
            <hr className="cart_info-inline"></hr>

            <div className="w-100 d-flex">
              <div className="crate-address_input-container w-50">
                {city && <label className="crate-address_input-label">Ciudad/Delegación</label>}
                <input 
                  type="text" 
                  required
                  placeholder="Ciudad/Delegación" 
                  name="city" 
                  value={city} 
                  onChange={this.handleChange} 
                  disabled={creatingAddressOnServer}
                />
              </div>
              <div className="crate-address_input-container w-50">
                {country_code && <label className="crate-address_input-label">País</label>}
                <select disabled={creatingAddressOnServer} style={{width: '100%', height: '100%'}} name="country_code" required onChange={this.handleChange}>
                  <option value="MEX" selected={country_code==='MEX'}>México</option>
                  <option value="USA" selected={country_code==="USA"}>Estados Unidos</option>
                </select>
              </div>
            </div>
            <hr className="cart_info-inline"></hr>

            <div className="w-100 d-flex">
              <div className="crate-address_input-container w-50">
                {state && <label className="crate-address_input-label">Estado</label>}
                <input 
                  type="text" 
                  required
                  placeholder="Estado" 
                  name="state" 
                  value={state} 
                  onChange={this.handleChange} 
                  disabled={creatingAddressOnServer}
                />
              </div>
              <div className="crate-address_input-container-right w-50">
                {zip_code && <label className="crate-address_input-label pl-1">Código Postal</label>}
                <input 
                  type="text" 
                  required
                  placeholder="Código Postal" 
                  name="zip_code" 
                  value={zip_code} 
                  onChange={this.handleChange} 
                  disabled={creatingAddressOnServer}
                />
              </div>
            </div>
            <hr className="cart_info-inline"></hr>

            <div className="crate-address_input-container w-100">
              {references && <label className="crate-address_input-label">Referencias</label>}
              <input 
                type="text" 
                // required
                placeholder="Referencias" 
                name="references" 
                value={references} 
                onChange={this.handleChange} 
                disabled={creatingAddressOnServer}
              />
            </div>
            <hr className="cart_info-inline"></hr>
            <div className="w-100 d-flex justify-content-center align-items-center">
              <button id="create_address" type="submit" className="create-address_button my-3" disabled={creatingAddressOnServer}>Guardar</button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default EditAddress;